import React, { useState } from 'react'
import { Button, CheckBox, RadioButton, Select, TextArea, TextField } from '../../ui/core'
import VeditorSimple from "../../veditor-simple/veditor";
import { v4 as uuidv4 } from 'uuid';
export default function Question({ question, onChange }) {
    const [contentRef] = useState(React.createRef());

    //options: [{ _id: uuidv4(), sort: 1, text: '', active: true }],

    const handleChange = (attribute, value, save) => {
        let tmpItem = Object.assign({}, question);
        tmpItem[attribute] = value;
        onChange(tmpItem, save)
    }

    const handleChangeOption = (optionID, attribute, value, save) => {
        let tmpItem = Object.assign({}, question);
        if (!tmpItem.options) tmpItem.options = [];
        if(question.type===0){
            tmpItem.options = tmpItem.options.map((opt)=>{opt.correct = false;return opt;})
        }
        let found = tmpItem.options.find(o => { return o._id === optionID });
        if (found) {
            found[attribute] = value;
        }
        found = tmpItem.options.find(o => { return o.text.length === 0 });
        if (!found) {
            tmpItem.options.push({ _id: uuidv4(), sort: 1, text: '', active: true })
        }
        onChange(tmpItem, save)
    }

    return (
        <>
            <div style={{ display: 'flex', width: '100%' }}>
                <div className="label" style={{ paddingTop: '20px', paddingRight: '20px' }}>{question.sort}</div>
                <div><VeditorSimple id={question._id} value={question.question || 'Voer hier uw vraag in'} contentRef={contentRef} onChange={() => handleChange('question', contentRef.current.innerHTML)} onBlur={() => { onChange(null, true) }} htmlTAG={'h1'} /></div>

            </div>
            <div style={{ float: 'right' }}>
                <Select id="type" value={question.type} onChange={(ev) => { handleChange('type', parseInt(ev.target.value), true) }}>
                    <option value={0}>1 antwoord mogelijk</option>
                    <option value={1}>meerkeuze</option>
                </Select></div>
            <table style={{ width: '100%' }} cellPadding="20px" cellSpacing="20px"><tbody><tr><th className="label">Correct</th><th className="label">Keuze tekst</th></tr>
                {
                    question.options && question.options.map(option => {
                        return <tr key={option._id}>
                            <td style={{ textAlign: 'right', width: '100px',verticalAlign:'top' }}>
                                {question.type === 0 && <RadioButton id={'cb' + option._id} checked={option.correct || false} onChange={(ev) => { handleChangeOption(option._id, 'correct', ev.target.checked, true) }} label=""></RadioButton>}
                                {question.type === 1 && <CheckBox id={'cb' + option._id} checked={option.correct || false} onChange={(ev) => { handleChangeOption(option._id, 'correct', ev.target.checked, true) }} label=""></CheckBox>}
                            </td>
                            <td><TextField id={'text' + option._id} placeholder="Keuze tekst.." fullWidth={true} value={option.text || ''} onChange={(ev) => { handleChangeOption(option._id, 'text', ev.target.value, false) }} onBlur={() => { onChange(null, true) }}></TextField>
                            </td>
                        </tr>
                    })
                }
            </tbody></table>

            <div className="label">Feedback bij correct antwoord</div>
            <TextArea id="feedbackOnCorrect" value={question.feedbackOnCorrect} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} onBlur={() => { onChange(null, true) }}></TextArea>

            <div className="label">Feedback bij <u>in</u>correct antwoord</div>
            <TextArea id="feedbackOnIncorrect" value={question.feedbackOnIncorrect} onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }} onBlur={() => { onChange(null, true) }}></TextArea>

        </>
    )
}
