import React,{useEffect,useContext} from 'react'
import {Context} from '../../AppContext';

export default function PageWrapper(props) {
    const context = useContext(Context);

    useEffect(() => {
        context.setHistory(props.history)
    }, [])
    
    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}
