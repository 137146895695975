import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "evaluationmonitor";

const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForOrganisation:(organisationID)=>{
        return new Promise((resolve, reject) => {
            API.get('organisations/'+organisationID+'/evaluationmonitor').then(
                result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getScore:(id)=>{
        return new Promise((resolve, reject) => {
            API.get('evaluationmonitor/'+id+'/scores').then(
                result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;