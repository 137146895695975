import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "documents";

const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getForModule: (module, moduleID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/all/modules/' + module + '/' + moduleID).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    requestDownloadKey: (documentID, moduleID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/' + documentID + '/modules/' + moduleID + '/requestlink').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    arrayBufferToBase64 :(buffer)=> {
        return new Promise(function (resolve, reject) {
            var blob = new Blob([buffer], { type: 'application/octet-binary' });
            var reader = new FileReader();
            reader.onload = function (evt) {
                var dataurl = evt.target.result;
                resolve(dataurl.substr(dataurl.indexOf(',') + 1));
            };
            reader.onerror = function (err) {
                reject(err);
            }
            reader.readAsDataURL(blob);
        })
    },
    downLoadFile : (url) =>{
        return new Promise(function (resolve, reject) {
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState === 4 && this.status == 200) {
                    Service.arrayBufferToBase64(xhttp.response)
                        .then(function (base64) {
                            resolve(base64);
                        }, function (err) {
                            reject(err)
                        })
                } else if (this.readyState === 4) {
                    reject(this.status)
                }
            };
            xhttp.open("GET", url, true);
            xhttp.responseType = "arraybuffer";
            xhttp.setRequestHeader('responseType', 'arraybuffer')
            xhttp.send();
        })
    }
}
export default Service;