/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Settings from "./AppSettings";
import ServiceOrganisation from './services/organisation';
import ServiceSettings from './services/settings';
import ServiceFAQ from './services/faq';
const locationAPI = Settings.getLocationAPI();
export const Context = React.createContext();


export default function AppContext(props) {
  const [history, setHistory] = useState()
  const [user, setUser] = useState();
  const [module, setModule] = useState();
  const [organisation, setOrganisation] = useState();
  const [settings,setSettings] = useState()
  const [showFAQModule,setShowFAQModule] = useState();
  const [FAQs,setFAQs] = useState([])
  const logout = () => {
    setUser();
    setModule()
    window.localStorage.clear();
    window.location.href = "/#";
    window.location.reload()
  };

  const updateOrganisation = (organisation) => {
    if (organisation) {
      window.localStorage.setItem('selectedorganisation', organisation._id);
      setOrganisation(organisation)
    } else {
      window.localStorage.removeItem('selectedorganisation');
      setOrganisation()
    }
  }

  const hasRights = (user, module, option) => {
    if (!user || !user.rights) return false;
    let foundModule = user.rights.find(right => { return right.module === module });
    if (!foundModule) return false;
    return foundModule.options.indexOf(option) >= 0;
  }
 

  const context = {
    history: history,
    setHistory: setHistory,
    user: user,
    hasRights: hasRights,
    setUser: setUser,
    module: module,
    setModule: setModule,
    organisation: organisation,
    setOrganisation: updateOrganisation,
    logout: logout,
    FAQs:FAQs,
    showFAQ:showFAQModule,
    setShowFAQ:setShowFAQModule,
    settings:settings
  }

  useEffect(() => {
   
    if (window.location.href.indexOf('beheer') >= 0) {
      setModule('beheer')
    } else if (window.location.href.indexOf('evaluatie') >= 0) {
      setModule('evaluatie')
    } else if (window.location.href.indexOf('checklist') >= 0) {
      setModule('checklist')
    } else if (window.location.href.indexOf('e-learning') >= 0) {
      setModule('e-learning')
    }  else if (window.location.href.indexOf('organisatie') >= 0) {
      setModule('organisation')
    }else {
      setModule();
    }
    
    if (window.localStorage.getItem('selectedorganisation')) {
      ServiceOrganisation.get(window.localStorage.getItem('selectedorganisation'))
        .then(organisation => { setOrganisation(organisation) })
    }

    ServiceSettings.get()
    .then(settings => { setSettings(settings) })

    ServiceFAQ.get()
    .then(faqs => { setFAQs(faqs) })

  }, [])

  return (
    <Context.Provider value={context}>
    
      {settings && props.children}
    </Context.Provider>
  )
};