import React, { useState, useEffect, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField } from '../ui/core'
import ServiceDocument from '../../services/document';
export default function Uploaddocument({ onSave, onClose }) {
    const [editItem, setEditItem] = useState({});
    const [url, setUrl] = useState();

    const ref = useRef()
    const handleChange = (attribute, value) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);
    }

    const fileToBase64 = async (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (e) => reject(e)
        })

    const handleFile = () => {
        if (ref && ref.current && ref.current.files && ref.current.files.length > 0) {

            fileToBase64(ref.current.files[0])
                .then(result => {

                    let tmpItem = Object.assign({}, editItem);
                    tmpItem.size = ref.current.files[0].size;
                    tmpItem.type = ref.current.files[0].type;
                    tmpItem.name = ref.current.files[0].name;
                    tmpItem.permanentlink = true;
                    if(!tmpItem.title) tmpItem.title = ref.current.files[0].name;
                    tmpItem.lastmodified = ref.current.files[0].lastModifiedDate;
                    tmpItem.base64 = result;
                    tmpItem.module = 'blockbuilder';
                    tmpItem.moduleid = '-';
                    ServiceDocument.insert(tmpItem)
                        .then(result => { 
                           ServiceDocument.requestDownloadKey(result._id, result.moduleid)
                           .then(resultLink => {
                               if (resultLink && resultLink.link) {
                                    setUrl(resultLink.link)
                               }
                           })
                           .catch(ex => { console.log(ex) })
                        })
                        .catch(ex => { })
                  
                    setEditItem(tmpItem);
                })
                .catch(ex => { console.log(ex) })
        }
    }

    return (
        <Dialog>
            <DialogTitle><Typography component={'h1'}>Toevoegen document</Typography></DialogTitle>
            <DialogContent>
                <TextField id="title" label="Titel" value={editItem.title || ''} onChange={(ev) => handleChange(ev.target.id, ev.target.value)} ></TextField>
                <br />   <br />
                <Button   onClick={()=>{ref.current && ref.current.click()}} fullWidth>Selecteer document</Button>
                <input ref={ref} onChange={handleFile} id="uploadFile" type="file" style={{display:'none'}}/>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Annuleren</Button>
                <Button onClick={() => { onSave(url) }} disabled={!url}>Toevoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
