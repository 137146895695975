import React, { useState, useEffect } from 'react'
import { Button } from '../ui/button'
import Edit from './faq-edit';
import ServiceFAQ from '../../services/faq';
import { List, ListItem, ListItemText, Tabs } from '../ui/core';

export default function FAQ() {
    const [FAQS, setFAQs] = useState([])
    const [selectedFAQ, setSelectedFAQ] = useState()
    const [selectedModule, setSelectedModule] = useState(0)
    const get = () => {
        ServiceFAQ.get()
            .then(result => setFAQs(result));

    }
    useEffect(() => {
        get()
    }, [])

    const handleNew = () => {
        setSelectedFAQ({ question: "Hoe kan ik...", module: selectedModule })
    }
    const handleSave = (faq) => {
        if (faq._id) {
            ServiceFAQ.update(faq)
                .then(() => {
                    setSelectedFAQ();
                    get();
                })
        } else {
            ServiceFAQ.insert(faq)
                .then(() => {
                    setSelectedFAQ();
                    get();
                })
        }
    } 
    return (
        <div>
            {selectedFAQ && <Edit faq={selectedFAQ} onSave={handleSave} onClose={() => setSelectedFAQ()} />}
            <Tabs value={selectedModule} onChange={setSelectedModule}>
                <div className="ui-tab">EVALUATIE</div>
                <div className="ui-tab">E-LEARNING</div>
                <div className="ui-tab">BEVOEGDHEDEN</div>
            </Tabs>
            
            <List>
                {FAQS.filter(FAQ => { return FAQ.module === selectedModule }).map(FAQ => {
                    return <ListItem key={FAQ._id} onClick={()=>{setSelectedFAQ(FAQ)}}><ListItemText primary={FAQ.question}></ListItemText></ListItem>
                })}
            </List>
            <br style={{ clear: 'both' }} />
            <div  className="padding">
                 <Button onClick={handleNew}>Vraag Toevoegen</Button>
            </div>
         
        </div>
    )
}
