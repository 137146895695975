import React, { useEffect, useState, useContext } from 'react'
import Service from '../../../services/respondents';
import ServiceUsers from '../../../services/user';
import ServiceAnswers from '../../../services/answers';
import { Button, Paper, Table } from '../../ui/core';
import QuickSearch from '../../users/quicksearch';
import { Context } from '../../../AppContext';
import AddRespondent from './add';
import EditRespondent from './edit';
import { nanoid } from 'nanoid'
export default function Index({ survey }) {
    const [respondents, setRespondents] = useState([]);
    const [editRespondent, setEditRespondent] = useState();
    const [showAddRespondent, setShowAddRespondent] = useState(false);
    const [users, setUsers] = useState([]);
    const context = useContext(Context);
    const headers = [
        { label: 'Naam', attribute: 'name', default: true },
        { label: 'Email', attribute: 'email', default: true },
        { label: 'Voortgang', attribute: 'progress', default: true }
    ]
    useEffect(() => {
        getRespondents();
        getUsers();
    }, [context.organisation])

    const getRespondents = () => {
        Service.getForSurvey(survey._id)
            .then(result => {
                result = result.map(res => { if (res.countquestions) res.progress = (res.countanswers || 0) + '/' + (res.countquestions || 0); return res; })
                setRespondents(result)
            })
            .catch(err => { console.log(err) })
    }

    const getUsers = () => {
        if (context && context.organisation) {
            ServiceUsers.getForOrganisation(context.organisation._id)
                .then(result => { setUsers(result) })
                .catch(err => { console.log(err) })
        }

    }

    const createCode = () =>{
       return nanoid(20).replace(/-/g,'4')
    }

    const addRespondent = (user) => {
        if (user) {
            let found = respondents.find(res => { return res.email.trim().toLowerCase() === user.email.trim().toLowerCase() });
            if (!found) {
                Service.insert({ user: user._id, name: ServiceUsers.getFullName(user), email: user.email, survey: survey._id,code:createCode() })
                    .then(result => {
                        getRespondents();
                    })
                    .catch(ex => { console.log(ex) })
            }
        }
    }

    const addExternalRespondent = (name, email) => {
        let found = respondents.find(res => { return res.email.trim().toLowerCase() === email.trim().toLowerCase() });
        if (!found) {
            Service.insert({ name: name, email: email, survey: survey._id,code:createCode() })
                .then(result => {
                    getRespondents();
                })
                .catch(ex => { console.log(ex) })
        }
    }

    const handleDelete = (item) => {
        Service.remove(item).then(result => { getRespondents() }).catch(ex => { console.log(ex) })
    }

    const handleUpdateRespondent = (respondent)=>{
        Service.update(respondent).then(result => { getRespondents();setEditRespondent() }).catch(ex => { console.log(ex) })
    }

    const handleEditRespondent = (respondent)=>{
        setEditRespondent(respondent);
    }
    return (
        <div >
            {showAddRespondent && <AddRespondent users={users} onAddUser={(user) => { addRespondent(user); setShowAddRespondent(false) }} onAddExternal={(name, email) => { addExternalRespondent(name, email); setShowAddRespondent(false) }} onClose={() => { setShowAddRespondent(false) }} />}
            {editRespondent && <EditRespondent respondent={editRespondent} onClose={()=>{setEditRespondent()}} onUpdate={handleUpdateRespondent}></EditRespondent>}
            <Paper>
                <Table items={respondents} headers={headers} onDelete={handleDelete} onClick={handleEditRespondent}/>
            </Paper>
            <br />
            <Button fullWidth variant="outlined" onClick={() => { setShowAddRespondent(true) }} >Waarnemer toevoegen</Button>
        </div>
    )
}
