import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextField,  Paper, Button,IconButton } from '../ui/core';
import {Delete} from '../ui/coreIcons';
export default function Parameters({ item, onSave }) {
    const [editItem, setEditItem] = useState();

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem.params = editItem.params;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    useEffect(() => {
        setEditItem(item);
    }, [item])

    const handleAddParam = () =>{
        let tmpItem = Object.assign({}, editItem);
        if(!tmpItem.params) tmpItem.params = [];
        tmpItem.params.push({_id:uuidv4(),name:'Parameter ..',description:'',values:[],active:true});
        setEditItem(tmpItem);
    }

    const handleAddValue = (param) =>{
        let tmpItem = Object.assign({}, editItem);
        if(!tmpItem.params) tmpItem.params = [];
        let found = tmpItem.params.find(p=>{return p._id===param._id});
        if(found){
           found.values.push({_id:uuidv4(),name:'Waarde ..',active:true});
        }
      
        setEditItem(tmpItem);
    }


    const handleChangeParam = (param,value) => {
        let tmpItem = Object.assign({}, editItem);
        let found = tmpItem.params.find(p=>{return p._id===param._id});
        if(found){
           found.name = value;
        }
        setEditItem(tmpItem);
    }

    const handleChangeVal = (param,val,value) => {
        let tmpItem = Object.assign({}, editItem);
        let found = tmpItem.params.find(p=>{return p._id===param._id});
        if(found){
            let foundVal = found.values.find(v=>{return v._id===val._id});
          if(foundVal) foundVal.name = value;
        }
        setEditItem(tmpItem);
    }

    const handleDeleteVal = (param,val) => {
        let tmpItem = Object.assign({}, editItem);
        let found = tmpItem.params.find(p=>{return p._id===param._id});
        if(found){
            let foundVal = found.values.find(v=>{return v._id===val._id});
          if(foundVal) foundVal.active = false;
        }
        setEditItem(tmpItem);
    }

    if(!item) return null;
    return (
        <div className="padding">
               <Paper  className="padding">
                
            {editItem && editItem.params && editItem.params.filter(param=>{return param.active===true}).map(param=>{
                return <div key={param._id}>
                    <TextField id={param._id} label="Parameter" value={param.name} onChange={(ev)=>{handleChangeParam(param,ev.target.value)}} onBlur={handleSave}></TextField>
                   {param.values.length > 0 &&  <div   style={{marginLeft:'100px',paddingTop:'6px'}} className="label" >Waardes</div>}
                   <table style={{width:'100%'}}><tbody>
                    {param.values.filter(v=>{return v.active===true}).map(val=>{
                        return <tr key={val._id} >
                            <td style={{width:'100px'}}></td>
                            <td>  <TextField id={val._id}   value={val.name} onChange={(ev)=>{handleChangeVal(param,val,ev.target.value)}} onBlur={handleSave}></TextField></td>
                            <td><IconButton onClick={()=>{handleDeleteVal(param,val)}}><Delete/></IconButton></td>
                        </tr>
                    })}
                    <tr><td></td><td>  <Button onClick={()=>{handleAddValue(param)}} fullWidth variant="outlined">Toevoegen waarde</Button></td><td></td></tr>
                    </tbody></table>
                  
                </div>
            })}
            {editItem && <>                
                 </>}
                 <Button onClick={handleAddParam}  fullWidth variant="outlined">Toevoegen parameter</Button>
                 </Paper>  
        </div>
    )
}
