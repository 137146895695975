import React, { useState, useEffect } from 'react'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, Select, CheckBox, Typography } from '../ui/core';

export default function ImageUpload(props) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [useBox, setUseBox] = useState(false);
    //   const [link, setLink] = useState('');
    //  const [base64Image, setBase64Image] = useState('')
    const [contentRef] = useState(React.createRef());
    const [contentRefCanvas] = useState(React.createRef());
    const [uploadedImage, setUploadedImage] = useState();
    const [width, setWidth] = useState('400px');
    const [resizeWidth, setResizeWidth] = useState(1000);
    const [align, setAlign] = useState('right');
    const insertItem = () => {

        let alignText = align === '-' ? '' : `align="${align}"`;
        let widthText = width !== '0' ? `width="${width}"` : ''
        let insertImage = `<img ${widthText} ${alignText}  src="${contentRefCanvas.current.toDataURL()}" alt="${title}"  class="paragraphimage"/>`
        if (useBox) {
            insertImage = `<div class="imagebox" style="width:${width};float:${align}"}><img  src="${contentRefCanvas.current.toDataURL()}" alt="${title}"  class="paragraphimage"/><br/><div class="imageboxtext">${description}</div></div>`
        }
        props.onSave(insertImage);

        props.onClose();
    }

    useEffect(() => {
        if (uploadedImage && contentRefCanvas.current) {
            let setWidth = uploadedImage.width;
            let setHeight = uploadedImage.height;
            if (resizeWidth > 0) {
                setWidth = resizeWidth;
                setHeight = Math.round((resizeWidth / uploadedImage.width) * uploadedImage.height);
            }
            contentRefCanvas.current.width = setWidth;
            contentRefCanvas.current.height = setHeight;
            var ctx = contentRefCanvas.current.getContext('2d');
            ctx.drawImage(uploadedImage, 0, 0, setWidth, setHeight);
        }
    }, [uploadedImage, resizeWidth])

    const uploadFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            let base64 = reader.result;
            if (base64.indexOf('data:image') === 0) {
                //  setBase64Image(base64);
                let image = new Image();
                image.src = base64;

                image.onload = function (ev) {
                    setUploadedImage(image);
                }
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        } 
    }
    const processFile = (ev) => {
        for (let i = 0; i < ev.target.files.length; i++) {
            uploadFile(ev.target.files[i]);
        }
    }

    return (
        <Dialog onClose={() => props.onClose()} open={true} >
            <DialogTitle id="simple-dialog-title"><Typography component="h1">Invoegen afbeelding</Typography></DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="Naam voor de afbeelding"
                    type="text"
                    value={title || ""}
                    onChange={(ev) => { setTitle(ev.target.value) }}
                    fullWidth
                />

                <Select label="Formaat afbeelding aanpassen naar" fullWidth value={resizeWidth} labelId="resize" onChange={(ev) => { setResizeWidth(ev.target.value) }}>
                    <option value={0}>Origineel formaat</option>
                    <option value={300}>300px</option>
                    <option value={600}>600px</option>
                    <option value={1000}>1000px</option>
                </Select>


                <Select label="Breedte in de weergave" fullWidth value={width} labelId="width" onChange={(ev) => { setWidth(ev.target.value) }}>
                    <option value={'0'}>Origineel formaat</option>
                    <option value={'100%'}>100%</option>
                    <option value={'100px'}>100px</option>
                    <option value={'200px'}>200px</option>
                    <option value={'400px'}>400px</option>
                    <option value={'600px'}>600px</option>
                    <option value={'800px'}>800px</option>
                    <option value={'1000px'}>1000px</option>
                </Select>


                <Select label="Uitlijning" fullWidth value={align} labelId="width" onChange={(ev) => { setAlign(ev.target.value) }}>
                    <option value={'-'}>Geen</option>
                    <option value={'left'}>Links</option>
                    <option value={'right'}>Rechts</option>
                    <option value={'middle'}>Midden</option>
                    <option value={'top'}>Boven</option>
                    <option value={'bottom'}>Onder</option>
                </Select>


                <CheckBox
                    id="kader"
                    label="Kader afbeelding"
                    checked={useBox}
                    onChange={(ev) => { setUseBox(ev.target.checked) }}
                    name="useBox"
                    color="primary"
                />

                {useBox && <TextField
                    margin="dense"
                    id="description"
                    label="Beschrijving"
                    type="text"
                    value={description || ""}
                    onChange={(ev) => { setDescription(ev.target.value) }}
                    fullWidth
                />}
                <br />
                <div className="label">Selecteer document</div>
                <input ref={contentRef} type="file" onChange={processFile} /><br />
                <canvas ref={contentRefCanvas} id="canvas"></canvas>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={() => { props.onClose() }}>Annuleren</Button><Button color="primary" variant="contained" onClick={() => { insertItem() }} disabled={!contentRefCanvas.current}>Invoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
