import React, { useContext } from 'react'
import { Header } from './header'
import {Footer} from './footer'
import { ContextUI } from '../../uiContext';
const Page = function Page(props) {
    const contextUI = useContext(ContextUI);
   
    return (
        < >
            {contextUI.showHeader && <Header  />}
            {contextUI.showFooter && <Footer  />}
            <div className="padding-ios">
            <div style={{ paddingTop: contextUI.showHeader ? '64px' : '0px', paddingBottom: contextUI.showFooter ? '64px' : '0px',width: '100%' }}>
                {props.children}
            </div>
            </div>
        </>
    )
}

export { Page };
