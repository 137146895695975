import React from 'react'
import { SearchBar, Table } from '../ui/core';

export default function list({items,onClick}) {
    const headers = [
        { label: 'Titel', attribute: 'title', default: true },
        { label: 'Beschrijving', attribute: 'description', default: true }
    ] 
    //  <SearchBar />
    return (
        <>
          
            <div className="padding"><Table items={items} headers={headers} onClick={onClick}/></div>
        </>
    )
}
