import React, { useState, useEffect, useContext } from 'react'
import { Typography } from '../../ui/core';
import Service from '../../../services/survey';
import ServiceRespondents from '../../../services/respondents';
import ServiceQuestions from '../../../services/question';
import ServiceGeneral from '../../../services/general';
import RadarChart from '../../charting/radar/index.js';
import { RadialChart } from 'react-vis';
import Grid from '../../charting/radar/grid.js';
import Radar from '../../charting/radar/radar.js';
import Label from '../../charting/radar/labels.js';
import ServiceColor from '../../../services/color';

import { Context } from '../../../AppContext';
export default function Processes({ survey }) {
    const context = useContext(Context);
    const [respondent, setRespondent] = useState();
    const [showForm, setShowForm] = useState(false);
    const [allScores, setAllScores] = useState({});
    const [scores, setScores] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [scoreDetails, setScoreDetails] = useState([]);
    const [subScores, setSubScores] = useState([]);
    const [subTitle, setSubTitle] = useState([]);
    const [selectedSubProcess, setSelectedSubProcess] = useState();

    useEffect(() => {
        //if (context.user && survey) {
        if (survey) {

            /*
        ServiceRespondents.getForSurvey(survey._id)
            .then(result => {
                let found = result.find(res => { return res.user === context.user._id });
                setRespondent(found);
            })
            .catch(err => { console.log(err) })
*/
            Service.getScores(survey._id)
                .then(scores => {
                    setAllScores(scores);
                    calCulateScores(survey, scores)
                })
                .catch(err => { console.log(err) })

            Service.getScoresDetail(survey._id)
                .then(scores => {
                    setScoreDetails(scores)

                })
                .catch(err => { console.log(err) })

            ServiceQuestions.getForSurvey(survey._id)
                .then(questions => {
                    questions.forEach(q => {
                        if(q.options){
                            q.options = q.options.filter(o => { return o.active === true });
                            q.options = ServiceGeneral.sortJSON(q.options, 'score', 123);
                        }                     
                    });
                    setQuestions(questions)

                })
                .catch(err => { console.log(err) })

        }
    }, [survey, showForm])

    const calCulateScores = (survey, scores) => {
        let tmpScores = [];
        if (survey.mainProcesses) {
            for (let t = 0; t < survey.mainProcesses.length; t++) {
                if (survey.mainProcesses[t].active === true) {
                    let tmpScore = {};
                    tmpScore._id = survey.mainProcesses[t]._id;
                    tmpScore.title = survey.mainProcesses[t].title;

                    tmpScore.score = 0;
                    let score = scores.mainProcessScore.find(i => { return i._id === survey.mainProcesses[t]._id });
                    if (score) {
                        tmpScore.score = score.perc;
                        tmpScore.color = Service.getColorFromRange(survey.colorRange, score.perc);
                    }

                    tmpScore.max = 100;
                    tmpScores.push(tmpScore)
                }
            }
        }
        setScores(tmpScores)
    }

    const calculateScoresSubProcess = (item) => {
        let tmpScores = [];
        if (survey.mainProcesses) {
            let found = survey.mainProcesses.find(pro => { return pro._id === item._id })

            if (found && found.subProcesses) {
                setSubTitle(found.title)
                for (let t = 0; t < found.subProcesses.length; t++) {
                    if (found.subProcesses[t].active === true) {

                        let tmpScore = {};
                        tmpScore._id = found.subProcesses[t]._id;
                        tmpScore.title = found.subProcesses[t].title;


                        tmpScore.score = 0;
                        let score = allScores.subProcessScore.find(i => { return i._id === found.subProcesses[t]._id });
                        if (score) {
                            tmpScore.score = score.perc;
                            tmpScore.color = Service.getColorFromRange(survey.colorRange, score.perc);
                        }
                        tmpScore.max = 100;
                        tmpScores.push(tmpScore)
                    }
                }

            }
        }

        setSubScores(tmpScores)
    }


    const getAvgTotalScore = (scores) => {
        let tmpScores = Object.assign([],scores);
        tmpScores = tmpScores.filter(s=>{return s.score !== 0});
        if(!tmpScores || tmpScores.length==0 ){
return 0;
        }else{
            return tmpScores.reduce((a, b) => { return { score: (a.score + b.score) } }).score / tmpScores.length;
        }
      
    }

    const getScorePie = (question, scores) => {
        scores = scores.filter(s => { return s.value >= 0 });
        if (scores.length === 0) {
            return null;
        }
        let data = [];
        let bri = 1;
        let briSteps = 1 / question.options.filter(s => { return s.score >= 0 }).length;
        if (question.options) {
            for (let i = 0; i < question.options.length; i++) {
                if (question.options[i].score >= 0) {
                    ///console.log( ServiceColor.changeHue("#00698f",hue))
                    let foundScores = scores.filter(s => { return s.value === question.options[i].score })
                    data.push({ id: question.options[i]._id, angle: foundScores.length, style: { fill: `rgba(0,105,143,${bri})`, stroke: `rgba(0,105,143,${bri})` }, colorType: "literal", label: (Math.round(((100 / scores.length) * foundScores.length) * 10) / 10) + '%', text: question.options[i].text })
                    bri = bri - briSteps;//0.2
                }
            }
        }

        return <div key={question._id} style={{ marginLeft: '18px' }}>
            <Typography component="h3">{question.question}</Typography>
            <RadialChart
                showLabels={true}
                data={data}
                labelsRadiusMultiplier={0.9}
                labelsStyle={{ fontSize: "11px", fill: "white", backgroundColor: "black" }}
                width={200}
                height={200} />
            <table>
                <tbody>
                    {data.map(d => {
                        return <tr key={d._id}><td style={{ backgroundColor: d.style.fill }}>&nbsp;</td><td className='label'>{d.text}</td><td className='label' style={{ textAlign: 'right' }}>{d.label}</td></tr>
                    })}
                </tbody>
            </table>
        </div>

    }

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div >
                    {scores && scores.length > 0 &&
                        <>
                            <Typography component="h3">Hoofdprocessen</Typography>
                            <RadarChart width={400} height={400} data={scores} settings={{ maxValueX: 100, title: '' }}>
                                <Grid gridDistanceX={10} />
                                <Radar dataKey={'score'} stroke="none" fill={Service.getColorFromRange(survey.colorRange, getAvgTotalScore(scores))} fillOpacity={1} />
                                <Label dataKey={'title'} onClick={(item) => { setSelectedSubProcess(); calculateScoresSubProcess(item) }} />
                            </RadarChart>
                        </>
                    }
                </div>
                <div >
                    {subScores && subScores.length > 0 ?
                        <>
                            <Typography component="h3">{subTitle}</Typography>
                            <RadarChart width={400} height={400} data={subScores} settings={{ maxValueX: 100, title: '' }}>
                                <Grid gridDistanceX={10} />
                                <Radar dataKey={'score'} stroke="#none" fill={Service.getColorFromRange(survey.colorRange, getAvgTotalScore(subScores))} fillOpacity={1} />
                                <Label dataKey={'title'} onClick={(item) => { setSelectedSubProcess(item._id); }} />
                            </RadarChart>
                        </>
                        :
                        <>
                            {scores && scores.length > 0 && <>
                                <Typography component="h3">Subprocessen</Typography>
                                <div style={{ width: '100%', textAlign: 'center', color: 'silver' }}>
                                    <br /><br />
                                    <i>Selecteer een hoofdprocess om de <br />onderliggende subprocessen in te zien</i>
                                    <br /><br />
                                </div>
                            </>}
                        </>
                    }

                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                    {selectedSubProcess && <>
                        {questions.filter(q => { return q.subProcess === selectedSubProcess }).map(question => {
                            //console.log(question)
                            let retValue = [];

                            let scores = scoreDetails.closedValues.filter(sd => { return sd.question == question._id });
                            if (scores) {
                                retValue.push(getScorePie(question, scores))
                            }
                            return retValue;
                        })}
                    </>}


                </div>
            </div>

        </div>
    )
}
