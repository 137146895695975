import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "users";

const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getForOrganisation: (organisationID) => {
        return new Promise((resolve, reject) => {
            API.get("organisations/" + organisationID + "/" + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getToken: () => {
        if (localStorage.getItem('accesskey') !== null) {
            return localStorage.getItem('accesskey');
        } else {
            return '0';
        }
    },
    setToken: (token) => {
        localStorage.setItem('accesskey', token);
    },
    requestNewPasswordToken: (username) => {
        return new Promise((resolve, reject) => {
            API.post('requesttoken', [], { u: username }).then(
                token => {
                    resolve(token)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    changePassword: (username, password, token) => {
        return new Promise((resolve, reject) => {
            API.post('resetpassword', [], { u: username, p: password, token: token }).then(
                token => {
                    resolve(token);
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    currentuser: () => {
        return new Promise((resolve, reject) => {
            API.get('currentuser', []).then(
                response => {
                    resolve(response)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    currentuserOrganisations: () => {
        return new Promise((resolve, reject) => {
            API.get('currentuser/organisations', []).then(
                response => {
                    resolve(response)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    authenticate: (username, password) => {
        return new Promise((resolve, reject) => {
            API.post('authenticate', [], { username: username, password: password }).then(
                response => {
                    resolve(response.result)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    signup: (signupform) => {
        return new Promise((resolve, reject) => {
            API.post('signup', [], signupform).then(
                response => {
                    resolve(response.result)
                },
                error => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    },
    getFullName: (user) => {
        if (!user) return '';
        let retValue = '';
        retValue = user.firstname || '';
        if (retValue.length > 0) { retValue = retValue + ' ' }
        retValue = retValue + (user.lastname || '')
        return retValue;
    },
    canWrite: (rights, module, itemID) => {  //itemID = organisation or department id
        if (rights) {
            let foundModule = rights.find(r => { return r.module === module })
            if (foundModule) {
                if (itemID) { 
                    return foundModule.options.indexOf('w-' + itemID) >= 0;
                } else { 
                    return foundModule.options.indexOf('w') >= 0;
                }
            }
        }
        return false;
    }
}
export default Service;