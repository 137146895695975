import React, { useState, useEffect } from 'react'
import { Overlay } from './overlay';
import Portal from './portal';
import './dialog.css';
const Dialog = function Dialog(props) {
    const [height, setHeight] = useState(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)

    const setWindowSize = () => {
        document.body.style.overflow = 'hidden';//added
        setHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', setWindowSize);
        return () => {
            document.body.style.overflow = 'auto';
            window.removeEventListener('resize', setWindowSize);
        }
    }, [])

    let modalStyle = {};
    let modalContentStyle = {};
    let className = 'ui-dialog-body'
    if (props.fullScreen === true) {
        modalStyle = { paddingTop: '0px' }
        modalContentStyle = { width: '100%', height: height + 'px' }
        className = 'ui-dialog-body-fullscreen padding-ios'
    }

    const childrenWithProps = React.Children.map(props.children, element =>
        React.cloneElement(element, { fullScreen: props.fullScreen })
    );

    return (
        <Portal>
            <Overlay />
            <div className="ui-dialog" style={modalStyle}>
                <div className={className} style={modalContentStyle}>
                    {childrenWithProps}
                </div>
            </div>
        </Portal>
    )
}
export { Dialog };
