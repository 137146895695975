import React, { useEffect, useState } from 'react'
import Service from '../../../services/checklistitems';
import GeneralService from '../../../services/general'
import { Button, ExpansionPanel, Menu, MenuItem,  Paper, Typography } from '../../ui/core';
import ServiceSurveys from '../../../services/survey';
import ChecklistItem from './item';
export default function Index({ checklist }) {
    const [checklistItems, setChecklistItems] = useState([]);
    const [surveys,setSurveys] = useState([]);
    const [selectedStep,setSelectedStep] = useState(0);
    const [steps,setSteps] = useState([])
   
    useEffect(() => {
        let max = 1;
        for(let i=0;i<checklistItems.length;i++){
            if(checklistItems[i].step && parseInt(checklistItems[i].step) > max){
                max = checklistItems[i].step;
            }
        }
        let steps = [];
        for(let i=1;i<=max;i++){
            steps.push(i);
        }
        setSteps(steps)
    }, [checklistItems])
    

    useEffect(()=>{
        ServiceSurveys.getTemplates()
        .then(result=>{
            setSurveys(result)
        })
        .catch(ex=>{})
    },[])


    useEffect(() => {
        getChecklistItems();
    }, [])

    const getChecklistItems = () => {
        Service.getForChecklist(checklist._id)
            .then(result => { setChecklistItems(GeneralService.sortJSON(result, 'sort', '123')) })
            .catch(err => { console.log(err) })
    }

    const addChecklist = (type) => {
        let maxNumber = 1;
        let searchList = Object.assign([],checklistItems);
        let setStep = selectedStep;
        if(selectedStep===0){
            setSelectedStep(steps.length+1);
            setStep = steps.length+1;
        }
        searchList = searchList.filter(item=>{return item.step === setStep})
        for (let i = 0; i < searchList.length; i++) {
            if (searchList[i].sort && searchList[i].sort >= maxNumber) {
                maxNumber = parseInt(searchList[i].sort) + 1
            }
        }
        
        Service.insert({ checklist: checklist._id,step:setStep, type: type, sort: parseInt(maxNumber) })
            .then(result => {
                getChecklistItems();
            })
            .catch(ex => { console.log(ex) })

    }

    const handleSave = (item) => { Service.update(item).then(result => { getChecklistItems(); }).catch(ex => { console.log(ex) }) }
    const handleDelete = (item) => { Service.remove(item).then(result => { getChecklistItems() }).catch(ex => { console.log(ex) }) }
  
    return (
        <div >
            {steps.map(step=>{
                return  <ExpansionPanel key={step} primary={<Typography component="h3">{checklist['labelstep_'+step]===undefined?('Stap '+step):checklist['labelstep_'+step]}</Typography>} expanded={selectedStep===step} onClick={()=>{step===selectedStep ? setSelectedStep(0): setSelectedStep(step)}}>
                    
                    {checklistItems.filter(item=>{return item.step===step}).map(checklist => {
                return <Paper key={'panel' + checklist._id} className="padding" style={{ marginBottom: '8px' }}><ChecklistItem surveys={surveys} key={checklist._id} checklist={checklist} onSave={handleSave} onDelete={handleDelete}></ChecklistItem></Paper>
            })}
                    
                    </ExpansionPanel> 
            })}
             
             {checklistItems.filter(item=>{return !item.step}).map(checklist => {
                return <Paper key={'panel' + checklist._id} className="padding" style={{ marginBottom: '8px' }}><ChecklistItem surveys={surveys} key={checklist._id} checklist={checklist} onSave={handleSave} onDelete={handleDelete}></ChecklistItem></Paper>
            })}
            <br />
            <Menu id="questionselectmenu" control={<Button fullWidth variant="outlined" >Item toevoegen</Button>}>
                <MenuItem onClick={() => addChecklist(0)}>Controle lijst</MenuItem>
                <MenuItem onClick={() => addChecklist(1)}>Naslag</MenuItem>
                <MenuItem onClick={() => addChecklist(2)}>Notitie</MenuItem>
                <MenuItem onClick={() => addChecklist(3)}>Acties</MenuItem>
            </Menu>

        </div>
    )
}
