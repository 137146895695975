import React, { useState, useEffect } from 'react'
import { Typography, Paper } from '../../../ui/core';
import BlockBuilder from '../../../block-builder/index';
import UploadComponent from '../../../block-builder-upload/index';
export default function Index({ item, onChange }) {

    const handleChangeBlocks = (blocks, save) => {
        let tmpStep = JSON.parse(JSON.stringify(item));// Object.assign({},elearningStep);
        if (blocks) {
            tmpStep.blocks = Object.assign([], blocks);
            tmpStep.blocks = tmpStep.blocks.filter(block => { return block.active === true }).sort((a, b) => { return a.sort - b.sort })
        }
        onChange(tmpStep, save);
    }

    if (!item) return null;

    return (
        <div>
            <Paper className="padding">
                <Typography component="h1">{item.title}</Typography>

                <BlockBuilder blocks={item.blocks || []} onChange={handleChangeBlocks} UploadComponent={UploadComponent}/>
            </Paper>
        </div>
    )
}
