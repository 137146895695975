import React from 'react' 
import { Button, Dialog,DialogActions, DialogContent, DialogTitle, Typography } from '../ui/core';
import List from '../organisations/list';
export default function QuickSearch({title,items,onClick,onClose}) {

    return (
        <Dialog onClose={onClose} fullScreen={true}>
            <DialogTitle><Typography component="h2">{title || 'Zoeken organisatie'}</Typography></DialogTitle>
            <DialogContent>
                <List items={items} onClick={onClick}/>
            </DialogContent>
        </Dialog>
    )
}
