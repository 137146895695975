import React, { useEffect,useContext } from 'react'
import { ContextUI } from '../../uiContext';

export default function SideBar(props) {
    const contextUI = useContext(ContextUI);

    const checkClick = (e) => {
        if (!document.getElementById('sideBar').contains(e.target)) {
           contextUI.setShowSideBar(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', checkClick);
        return () => {
            window.removeEventListener('click', checkClick);
        }
    }, [checkClick])

    return (
        <div id="sideBar" className="sideBar padding-ios" >
            {props.children}
        </div>
    )
}
