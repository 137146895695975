import React from 'react'

import { ExpandLess, ExpandMore } from './coreIcons';
const ExpansionPanel = function ExpansionPanel({ children, expanded, primary, secondary, onClick }) {
    return (
        <div className="expansion" >
            <div className="expansionBar">
                <div className="expansionHeader" onClick={onClick}>
                    <div className="expansionPrimary">{primary} </div>
                    <div className="expansionSecondary">{secondary}</div>
                    <div className="expansionIcon">{expanded ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
            </div>
            {expanded && <div className="expansionContent">
                {children}
            </div>}
        </div>
    )
}

export { ExpansionPanel };
