import React, { useState } from 'react';
import { Page, Overlay } from './components/ui/core';

import SideBar from './components/ui/sideBar';
export const ContextUI = React.createContext();

export default function UI(props) {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('')
    const [message, setMessage] = useState();
    const [popup, setPopup] = useState();
   
   
    //header related
    const [showHeader,setShowHeader] = useState(false);
    const [headerMenu,setHeaderMenu] = useState();
    const [headerActions,setHeaderActions] = useState();
    const [showBackButton,setShowBackButton] = useState(false);

    const [showSideBar, setShowSideBar] = useState(false);
    const [sideMenu, setSideMenu] = useState();

    //footer related
    const [showFooter,setShowFooter] = useState(false);
    const [footerImage,setFooterImage] = useState();
    const [footerMenuItems,setFooterMenuItems] = useState();
    const [footerLine1,setFooterLine1] = useState();
    const [footerLine2,setFooterLine2] = useState();

    const [primary, setPrimary] = useState({ color: '#224094', contrast: '#fff' });
    const [secondary, setSecondary] = useState({ color: '#4163c1', contrast: '#fff' });
    const [error, setError] = useState({ color: '#d47052', contrast: '#fff' });

    const showMessage = (message) => {
        setMessage(message)
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
    }

    const context = {
        loading,
        setLoading,
        title,
        setTitle,
        subTitle,
        setSubTitle,
        message,
        setMessage: showMessage,
        popup,
        setPopup,

        showHeader,
        setShowHeader,
        showBackButton,
        setShowBackButton,
        showSideBar,
        setShowSideBar,

        showFooter,
        setShowFooter,
        footerImage,
        setFooterImage,
        footerMenuItems,
        setFooterMenuItems,
        footerLine1,
        setFooterLine1,
        footerLine2,
        setFooterLine2,

        primary,
        setPrimary,
        secondary,
        setSecondary,
        error,
        setError,
        sideMenu,
        setSideMenu,
        headerMenu,
        setHeaderMenu,
        headerActions,
        setHeaderActions,
        
    }

    return (
        <ContextUI.Provider value={context}>
            {/*Show overlay*/}
            {(showSideBar || popup) && <Overlay />}
            {/*Show snackbar*/}
            <div id="snackbar">{message}</div>
            {/*Show sidebar*/}
            {showSideBar && <SideBar >{sideMenu}</SideBar>}
            {/*Show popup*/}
            {popup}
            {/*Show page*/}
            <Page >{props.children}</Page>
        </ContextUI.Provider>
    )
}