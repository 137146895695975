import React, { useState ,useEffect} from 'react'
import { CheckBox } from '../../ui/core';
import ServiceRespondent from '../../../services/respondents';
export default function InputParamsControl({ respondent, params }) {
    const [currentRespondent, setCurrentRespondent] = useState();

    useEffect(() => {
        ServiceRespondent.get(respondent._id)
        .then(result=>{setCurrentRespondent(result)})
    }, [respondent])

    const handleChangeCheck = (val, checked) => {
        let tmpRespondent = Object.assign({}, currentRespondent);
        if (!tmpRespondent.paramValues) tmpRespondent.paramValues = [];
        if (checked) {
            if (tmpRespondent.paramValues.indexOf(val._id) >= 0) {
                tmpRespondent.paramValues.splice(tmpRespondent.paramValues.indexOf(val._id), 1);
            } else {
                tmpRespondent.paramValues.push(val._id)
            }
        } else {
            if (tmpRespondent.paramValues.indexOf(val._id) >= 0) {
                tmpRespondent.paramValues.splice(tmpRespondent.paramValues.indexOf(val._id), 1);
            }
        }
        let updateRespondent = {_id:tmpRespondent._id,paramValues:tmpRespondent.paramValues}
        ServiceRespondent.update(updateRespondent)
        .then((result)=>{})
        setCurrentRespondent(tmpRespondent)
    } 
    return (
        <div>

            {currentRespondent && params.filter(p => { return p.active === true }).map(param => {
                return <div key={param._id}>
                    <div className="inputform-title">{param.name}</div>
                    {param.values.filter(val => { return val.active === true }).map((val) => {
                        return <div key={'val_' + val._id}>
                            <CheckBox key={'cb_val_' + val._id} id={'val_' + val._id} label={val.name || ''} checked={currentRespondent.paramValues && currentRespondent.paramValues.indexOf(val._id) >= 0} onChange={(ev) => handleChangeCheck(val, ev.target.checked)} />

                        </div>
                    })}
                </div>
            })}
        </div>
    )
}
