import React, { useEffect, useState } from 'react'
import { Button, Paper, Typography } from '../ui/core';
import Question from './viewer/question';

export default function Viewer({ quiz, answers, onChange }) {
    const [currentQuestion, setCurrentQuestion] = useState();
    const [finished, setFinished] = useState(false)

    useEffect(() => {
        if (quiz && quiz.questions && quiz.questions.length > 0) {
            //find question not completed
            let tmpQuestions = Object.assign([], quiz.questions);
            let nextQuestion;
            if (answers && answers.length > 0) {
                for (let i = 0; i < tmpQuestions.length; i++) {
                    let found = answers.find(ans => { return ans.question === tmpQuestions[i]._id && ans.complete === true });

                    if (!found && !nextQuestion) {
                        nextQuestion = tmpQuestions[i];
                    }
                }
            } else {
                nextQuestion = tmpQuestions[0];
            }
            if (nextQuestion) {
                setCurrentQuestion(nextQuestion);
                setFinished(false)
            } else {
                setFinished(true)
            }
        } else {
            setCurrentQuestion()
        }

    }, [quiz, answers])

    const handleChangeQuestion = (answer) => {
        let tmpAnswers = Object.assign([], answers);
        tmpAnswers = tmpAnswers.filter(ans => { return ans.question !== answer.question });
        tmpAnswers.push(answer);

        let progress = (100 / quiz.questions.length) * tmpAnswers.length;
        tmpAnswers.progress = progress;
        tmpAnswers.failed = false;
        if (tmpAnswers.progress === 100) {
            //todo bij 80% goed is het behaald
            if (quiz.questions.length !== tmpAnswers.filter(ans => { return ans.passed === true }).length) {
                tmpAnswers.failed = true
            }
        }
        onChange(tmpAnswers);
    }


    const reset = () => {
        onChange([])
    }

    if (!quiz) return null;
    return (
        <div className="padding" >
            <div className="square">
                {finished && <Paper className="padding w3-container w3-center w3-animate-bottom">
                    <Typography component="h1"> Resultaten </Typography>
                    <div style={{ width: '100%', textAlign: 'center' }}  >
                        <br />
                        <h1>{answers && answers.filter(ans => { return ans.passed === true }).length}/{quiz.questions.length}</h1> vragen goed. <br /><br />
                        <br />
                        <Button variant="outlined" color="primary" onClick={reset}>Probeer opnieuw</Button>
                        <br />  <br />
                    </div>
                </Paper>}
                {!finished && <Paper className="padding w3-container w3-center w3-animate-bottom">
                    <div style={{ width: '100%', textAlign: 'center' }} className="label">{currentQuestion && currentQuestion.sort}/{quiz.questions && quiz.questions.length}</div>
                    {currentQuestion && <Question question={currentQuestion} answer={answers && answers.find(ans => { return ans.question === currentQuestion._id })} onChange={handleChangeQuestion} />}
                    <br />  <br />
                </Paper>}
            </div>
        </div>
    )

}
