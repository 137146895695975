import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "respondents";


const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForSurvey: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get('surveys/' + surveyID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForElearning: (surveyID, elearningID) => {
        return new Promise((resolve, reject) => {
            API.get('surveys/' + surveyID + '/elearningsteps/' + elearningID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForChecklist: (surveyID, checklistID) => {
        return new Promise((resolve, reject) => {
            API.get('surveys/' + surveyID + '/checklistcaseitems/' + checklistID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getAllForRespondent: (surveyID, respondentID,code) => {
        return new Promise((resolve, reject) => {
            API.get('surveys/' + surveyID + '/respondents/' + respondentID + '/code/' + code).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
}
export default Service;