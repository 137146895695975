import React, { useState, useEffect,useContext } from 'react'
import ServiceCase from '../../services/checklistcase';
import {Settings} from '../ui/coreIcons';
import ChecklistCaseItem from './item-case';

import ItemSteps from './item-case-steps';
import ItemHeader from './item-case-header';
import ItemContent from './item-case-content';

import Container from '../sidemenu/container';
import Menu from '../sidemenu/menu';
import Content from '../sidemenu/content';

import { Context } from '../../AppContext';
export default function Item({ item, items, onRefresh }) {
    const context = useContext(Context);
    const [currentStep, setCurrentStep] = useState();
    const [steps, setSteps] = useState([])
    const [complete, setComplete] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentView, setCurrentView] = useState('settings');
    const [canWrite, setCanWrite] = useState(false);

    useEffect(() => {

        let max = 1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].step && parseInt(items[i].step) > max) {
                max = items[i].step;
            }
        }
        let steps = [];
        for (let i = 1; i <= max; i++) {
            steps.push(i);
        }
        setSteps(steps);

        calculateOverallComplete(items)
    }, [items])

    useEffect(() => {
        setCanWrite(ServiceCase.hasRight(item, context.user, 100));
    }, [item, context.user])

const handleSaveCaseItem = (item)=>{
    ServiceCase.update(item)
    .then(result=>{
        onRefresh()
    })
}



    const calculateOverallComplete = (items) => {
        let complete = 0;
        let foundItems = items.filter(item => { return item.active === true && item.type !== 2  })
        if (foundItems.length > 0) {
            let foundItemsComplete = foundItems.filter(item => { return item.complete === true })
            setComplete(Math.round(((100 / parseFloat(foundItems.length)) * foundItemsComplete.length)));
        } else {
            setComplete(0)
        }
    }

    /*
      <Paper>
              {steps.map(step => {
                  return <ExpansionPanel key={step} primary={<Typography component="h3">Stap {step}</Typography>} secondary={getPercentageComplete(step)} expanded={selectedStep === step} onClick={() => { step === selectedStep ? setSelectedStep(0) : setSelectedStep(step) }}>
                      {items.sort((a,b)=>{return a.sort-b.sort}).filter(item => { return item.step === step  && item.active===true}).map(checklist => {
                          let retItem;
                          switch (checklist.type) {
                              case 0:
                                  retItem = <ItemSurvey key={checklist._id} item={checklist} onRefresh={onRefresh}></ItemSurvey>
                                  break;
                              case 1:
                                  retItem = <ItemWiki key={checklist._id} item={checklist} onRefresh={onRefresh}></ItemWiki>
                                  break;
                              case 2:
                                  retItem = <ItemNote key={checklist._id} item={checklist} onRefresh={onRefresh}></ItemNote>
                                  break;
                              case 3:
                                  retItem = <ItemActions key={checklist._id} item={checklist} onRefresh={onRefresh}></ItemActions>
                                  break;
                          }
                          return retItem;
                      })}
                  </ExpansionPanel> 
              })}
          </Paper> */
          const styleStep = { padding: '12px', cursor: 'pointer', display: 'flex', color: '#333333' }


    return (
        <Container>
            <Menu>
                <ItemHeader item={item} complete={complete} items={items} />
                <div style={styleStep} onClick={() => { setCurrentView('settings')}} >
                    <div style={{ paddingRight: '10px' }}>
                        <Settings color="333333" />
                    </div>
                    <div style={{ width: '90%' }}>Instellingen </div>
                    <div></div>
                </div>
                <ItemSteps steps={steps} items={items} item={item}onClick={(step) => { setCurrentView('step'); setCurrentStep(step); setRefresh(new Date().toString()) }} />
            </Menu>
            <Content scrollTop={refresh} >
                {currentView==='settings' && <ChecklistCaseItem item={item} onSave={handleSaveCaseItem}/>}
                {currentView==='step' && <ItemContent item={item} items={items.sort((a, b) => { return a.sort - b.sort }).filter(item => { return item.step === currentStep && item.active === true })} onRefresh={onRefresh} readOnly={!canWrite} />}
            </Content>
        </Container>
    )
}
