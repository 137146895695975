import React, { useState, useContext, useEffect } from 'react';

import Service from '../../../services/checklist';
import GeneralService from '../../../services/general';

import List from '../../../components/module-checklist/list';

import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { IconButton ,SearchBar,Tabs,Paper} from '../../../components/ui/core';
import {Add} from '../../../components/ui/coreIcons';

export default function Index(props) {
    const contextUI = useContext(ContextUI);  
    const context = useContext(Context);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0)
    const [search,setSearch] = useState('');

   
    //INITIAL FUNCTIONS
    useEffect(() => {
        getItems();
        contextUI.setSubTitle('Bevoegdheden applicatie');
        contextUI.setShowBackButton(false);
        contextUI.setHeaderActions(<IconButton onClick={addItem}><Add color="white" /></IconButton>)
        return () => { }
    }, [])

    useEffect(() => {
        setItemsFiltered(items.filter(i=>{return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase())>=0}));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])

    const getItems = () => { Service.get().then(items => setItems(GeneralService.sortJSON(items, 'title', '123'))).catch(err => { console.log(err) }) }

    const addItem = () => { Service.insert({template:true}).then(item => { viewItem(item._id); }).catch(ex => { console.log(ex) }) }

    const viewItem = (id) => {  props.history.push('/beheer/checklijsten/' + id)  }

    if (!context.hasRights(context.user, 'fullcontrol-checklists', 'w')) return null;

    return (
        <div>
 <Paper>
<Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab">ACTUEEL</div>
                <div className="ui-tab">ARCHIEF</div>
            </Tabs>
            </Paper>
            <SearchBar value={search} onKeyUp={setSearch} onReset={()=>{setSearch('')}}></SearchBar>
            <List items={itemsFiltered.filter(item => { if (selectedTab === 1 && item.archived === true) { return true }; if (selectedTab === 0 && !item.archived) { return true }; return false; })} onClick={(item) => viewItem(item._id)} />
        </div>
    )
}
