import React from 'react'

const Paper = function Paper(props) {
    return (
        <div  className={props.className ? 'paper ' + props.className : 'paper'} style={props.style || {}} onClick={(ev)=>{if(props.onClick){props.onClick(ev)}}}>
            {props.children}
        </div>
    )
}
export { Paper };
