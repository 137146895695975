import React, { useState, useEffect } from 'react'
import { TextArea, TextField } from '../../ui/core';
import VeditorSimple from "../../veditor-simple/veditor";
export default function Paragraph({ block, onChange, onSave }) {
    const [contentRefPar] = useState(React.createRef());
    const [contentRefTitle] = useState(React.createRef());
    const [contentRefSubTitle] = useState(React.createRef());
    /* block.textStyle
       <option value={'par'}>Paragraaf</option>
       <option value={'partit'}>Paragraaf met titel</option>
       <option value={'parsub'}>Paragraaf met subtitel</option>
       <option value={'tit'}>Titel</option>
       <option value={'sub'}>Subtitel</option>
       <option value={'twocol'}>Twee kolommen</option>
       <option value={'say'}>Uitspaak</option>
       <option value={'info'}>Info</option>
    */

    const handleBlur = () => {
        let tmpBlock = Object.assign({}, block);
        onChange(tmpBlock, true);
    };

    const handleChange = (attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value;
        onChange(tmpBlock);
    };

    if (!block) return null;
    return (
        <div className="block-content">
            {block.textStyle && block.textStyle.indexOf('tit') >= 0 && <VeditorSimple id={block._id} value={block.title !== undefined ? block.title : 'Titel'} contentRef={contentRefTitle} onChange={() => handleChange('title', contentRefTitle.current.innerHTML)} onBlur={handleBlur} htmlTAG={'h1'} />}

            {block.textStyle && block.textStyle.indexOf('sub') >= 0 && <VeditorSimple id={block._id} value={block.title !== undefined ? block.title : 'Subtitel'} contentRef={contentRefSubTitle} onChange={() => handleChange('title', contentRefSubTitle.current.innerHTML)} onBlur={handleBlur} htmlTAG={'h2'} />}

            {block.textStyle && block.textStyle.indexOf('par') >= 0 && <VeditorSimple id={block._id} value={block.paragraph !== undefined ? block.paragraph : 'Vul hier uw paragraaf tekst in'} contentRef={contentRefPar} onChange={() => handleChange('paragraph', contentRefPar.current.innerHTML)} onBlur={handleBlur} />}
    
        </div>
    )

}
