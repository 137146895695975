import React,{useState,useEffect} from 'react'
import { Button } from '../ui/button'
import { TextField, Typography } from '../ui/core'
import { Dialog } from '../ui/dialog'
import { DialogActions } from '../ui/dialogActions'
import { DialogContent } from '../ui/dialogContent'
import { DialogTitle } from '../ui/dialogTitle'
import VEditor from "../veditor/veditor"

export default function FAQEdit({faq,onClose,onSave}) {
    const [currentFAQ,setCurrentFAQ] = useState();
    const [contentRef] = useState(React.createRef());

useEffect(() => {
  setCurrentFAQ(faq);
}, [faq])

const handleChange = (attribute,value)=>{
    let tmpItem = Object.assign({},currentFAQ);
    tmpItem[attribute] = value;
    setCurrentFAQ(tmpItem);
}

const handleSave = () =>{
    let tmpItem = Object.assign({},currentFAQ);
    tmpItem.answer = contentRef.current.innerHTML;
    setCurrentFAQ(tmpItem);
    onSave(tmpItem)
}
const handleDelete = () =>{
    if(window.confirm("Weet u zeker dat u deze vraag wilt verwijderen?")){
        let tmpItem = Object.assign({},currentFAQ);
        tmpItem.active =false;
        setCurrentFAQ(tmpItem);
        onSave(tmpItem)
    }
  
}
if(!currentFAQ) return null;
    return (
        <Dialog>
            <DialogTitle>
        <Typography component={"h1"}>FAQ</Typography>
            </DialogTitle>
            <DialogContent>
            <TextField id="question" label="vraag" value={currentFAQ.question} onChange={(ev)=>{handleChange('question',ev.target.value)}}></TextField>
            <br/><br/><div className="label">antwoord</div>
            <VEditor id="html-edit" height="300px" value={currentFAQ.answer} contentRef={contentRef} files={[]} pages={[]} />
            </DialogContent>
            <DialogActions>
            <Button variant="text" onClick={onClose} style={{color:'red'}} onClick={handleDelete}>Verwijderen</Button>
                <Button variant="outlined" onClick={onClose}>Sluiten</Button>
                <Button variant="contained" onClick={handleSave}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
