import React, { useState, useEffect } from 'react'
import RadarChart from '../charting/radar/index.js';
import Grid from '../charting/radar/grid.js';
import Radar from '../charting/radar/radar.js';
import Label from '../charting/radar/labels.js';
import ServiceSurvey from '../../services/survey';
export default function MonitorDashRadar({ chartData, monitor, onClick }) {
    const [width, setWidth] = useState(300);
    const [domains, setDomains] = useState([])

    const getAvgTotalScore = (scores) => {
        if (scores.length > 0) { 
            let tmpScores = Object.assign([],scores);
            tmpScores = tmpScores.filter(s=>{return s.score !== 0})
            if(tmpScores.length>0){
                return tmpScores.reduce((a, b) => { return { score: ((a.score || 0) + (b.score || 0)) } }).score / tmpScores.length;
            }else{
                return 0;
            }
         
        } else {
            return 0;
        }
    }

    if (!chartData) return null;
    return (
        <>
            <RadarChart width={400} height={400} data={chartData} settings={{ maxValueX: 100, title: '' }}>
                <Grid gridDistanceX={10} />
                <Radar dataKey={'score'} stroke="none" fill={ServiceSurvey.getColorFromRange(monitor.colorRange, getAvgTotalScore(chartData))} fillOpacity={1} />

                <Label dataKey={'title'} onClick={onClick} />
            </RadarChart>
        </>
    )
}
