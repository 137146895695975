import React, { useState, useEffect } from 'react'
import { Button, TextArea, TextField } from '../../ui/core';
import VeditorSimple from "../../veditor-simple/veditor";
import useWindowSize from '../hooks/windowsize';
export default function Paragraph({ block, onChange, onSave, UploadComponent }) {
    const windowsize = useWindowSize();
    const [contentRefPar] = useState(React.createRef());
    const [contentRefTitle] = useState(React.createRef());
    const [contentRefSubTitle] = useState(React.createRef());
    const [showUpload, setShowUpload] = useState(false);

    /* block.imageStyle
       <option value={'full'}>Volle breedte</option>
       <option value={'textleft'}>Tekst links naast de afbeelding</option>
       <option value={'textonimage'}>Tekst op de afbeelding</option>
    */

    const handleBlur = () => {
        let tmpBlock = Object.assign({}, block);
        onChange(tmpBlock, true);
    };

    const handleChange = (attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value;
        onChange(tmpBlock);
    };

    const handleSaveImage = (url) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.image = url;
        onChange(tmpBlock);
        setShowUpload(false);
    }

    if (!block) return null;
    return (
        <div>
            {showUpload && <UploadComponent onSave={handleSaveImage} onClose={() => { setShowUpload(false) }} />}
           
            {block.image && <div style={{backgroundImage:`url(${block.image})`,backgroundRepeat:'no-repeat', backgroundSize:'cover', height: windowsize.innerHeight - 64}}>
            {block.imageStyle && block.imageStyle.indexOf('textonimage') >= 0 && <div className="block-text-on-image"><VeditorSimple id={block._id} value={block.paragraph !== undefined ? block.paragraph : 'Vul hier uw tekst in'} contentRef={contentRefPar} onChange={() => handleChange('paragraph', contentRefPar.current.innerHTML)} onBlur={handleBlur} /></div>}
                </div>}
            {!block.image && <div  className="w3-center"><Button onClick={() => { setShowUpload(true) }}>Upload afbeelding</Button></div>}
        </div>
    )

}
