import React, { useState } from 'react'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, Typography } from '../ui/core';

export default function Table(props) {
    const [rows, setRows] = useState(2);
    const [columns, setColumns] = useState(2);

    const insertItem = () => {
        let table = '<table width="100%"><tbody>';
        for(let r=0;r<rows;r++){
            table += '<tr>'
            for(let c=0;c<columns;c++){
                table += '<td></td>'
            }
            table += '</tr>'
        }
        table += '</tbody></table>';
       
        props.onSave(table);
        props.onClose();
    }

    return (
        <Dialog onClose={() => props.onClose()} open={true} >
            <DialogTitle id="simple-dialog-title"><Typography component="h1">Invoegen tabel</Typography></DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="rows"
                    label="Aantal rijen"
                    type="number"
                    value={rows || ""}
                    onChange={(ev) => { setRows(parseInt(ev.target.value)) }}
                    fullWidth
                    required
                />
                  <TextField
                    margin="dense"
                    id="columns"
                    label="Aantal kolommen"
                    type="number"
                    value={columns || ""}
                    onChange={(ev) => { setColumns(parseInt(ev.target.value)) }}
                    fullWidth
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={() => { props.onClose() }}>Annuleren</Button><Button color="primary" variant="contained" onClick={() => { insertItem() }}>Invoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
