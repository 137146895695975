import React, { useEffect, useState, useRef } from 'react';

import './circulairProgress.css';

const CirculairProgress = ({ progress, color = 'rgb(255, 128, 54)' }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    let calculateProgress = progress || 0;
    calculateProgress = 100 - calculateProgress;
    let calculateOffset = Math.round(629 / 100 * calculateProgress);
    if (calculateOffset < offset) {
      setTimeout(() => {
        setOffset(offset - 1)
      }, 1);
    } else if (calculateOffset > offset) {
      setTimeout(() => {
        setOffset(offset + 1)
      }, 1);
    }

  }, [progress, offset])

  return (
    <div  >
      {progress === 100 && <svg height="20" width="20" viewBox="-10 -10 220 220">
        <path id="back" d="M0,100 a100,100 0 1 0 200,0 a100,100 0 1 0 -200,0" fill={color} stroke={color} strokeWidth="25" strokeLinecap="round" />
        <line id="line"  x1="40" y1="90" x2="80" y2="160" style={{stroke:'white',strokeWidth:20}}></line>
        <line id="line"  x1="80" y1="160" x2="160" y2="40" style={{stroke:'white',strokeWidth:20}}></line>
      </svg>}
      {progress < 100 &&
        <svg height="20" width="20" viewBox="-10 -10 220 220">
          <path id="back" d="M0,100 a100,100 0 1 0 200,0 a100,100 0 1 0 -200,0" fill="#FFFFFF" stroke="silver" strokeWidth="25" strokeLinecap="round" />
          <path id="ring" d="M100,0 a100,100 0 0 1 0,200 a100,100 0 0 1 0,-200,0" fill="none" stroke={color} strokeWidth="25" strokeDasharray="629" strokeLinecap="round" strokeDashoffset={offset} />
        </svg>
      }
    </div>

  )
}


export { CirculairProgress };