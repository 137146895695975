import React, { useState, useEffect, useContext } from 'react'
import { ContextUI } from '../../uiContext';
import { Delete } from './coreIcons';
import { IconButton } from './iconButton';
import './table.css';

const Table = function Table({ items, headers, onClick, onDelete }) {
    const contextUI = useContext(ContextUI);

    let [tableRows, setTableRows] = useState([]);
    let [tableHeaders, setTableHeaders] = useState([]);

    const buildTable = () => {
        //HEADER
        let tmpHeaders = headers.map(header => {
            return <th key={header.attribute} style={{ backgroundColor: contextUI.primary.color, color: contextUI.primary.contrast }} >{header.label}</th>
        })
        setTableHeaders(<tr>{tmpHeaders}{onDelete && <th style={{ backgroundColor: contextUI.primary.color, color: contextUI.primary.contrast }} ></th>}</tr>)
        //ROWS
        let tmpRows = items.map(item => {
            let tmpCells = headers.map(header => {
                return <td key={item._id + '_' + header.attribute}>{item[header.attribute]}</td>
            })
            return <tr key={item._id + '_row'} onClick={() => { onClick && onClick(item) }}>{tmpCells}{onDelete && <td style={{float:'right'}}><IconButton onClick={(ev => { ev.preventDefault(); ev.stopPropagation();onDelete(item) })}><Delete /></IconButton></td>}</tr>
        })
        setTableRows(tmpRows);
    }

    useEffect(() => {
        buildTable();
        return () => { }
    }, [items, headers])


    return (
        <table className="ui-table">
            <tbody>
                {tableHeaders}
                {tableRows}
            </tbody>
        </table>
    )
}
export { Table }