import React,{useState,useContext} from 'react'
import { Paper, TextArea, Typography } from '../ui/core'
import {Edit} from '../ui/coreIcons';
import ServiceChecklistCaseItem from '../../services/checklistcaseitems';
import {Context} from '../../AppContext';

export default function ItemCaseitemNote({item,onRefresh,readOnly}) {
    const context = useContext(Context);
    const [note,setNote] = useState(item.note || '');

    const saveItem = () =>{
        let saveItem = {};
        saveItem._id = item._id;
        saveItem.note = note;
        ServiceChecklistCaseItem.update(saveItem)
        .then(result=>{
            onRefresh();
        })
        .catch(ex=>{
            context.setMessage('Het opslaan is mislukt');
        })
        
    }
    return (
        <div className="padding">
              <Typography component="h1" style={{borderBottom:'1px solid rgb(0, 105, 143)',paddingBottom:'6px'}}><Edit color={'rgb(0, 105, 143)'}/> {item.step}.{item.sort}  {item.title}</Typography>
            <TextArea id="note" fullWidth value={note} onChange={(ev)=>{setNote(ev.target.value)}} onBlur={saveItem} placeholder='Voer hier uw aantekeningen in' readOnly={readOnly}/>
        </div>
    )
}
