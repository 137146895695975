import React from 'react'
import RadarChart from '../../charting/radar/index.js';
import Grid from '../../charting/radar/grid.js';
import Radar from '../../charting/radar/radar.js';
import Label from '../../charting/radar/labels.js';

import Service from '../../../services/survey';
export default function CustomRadarChart({id, scores, colorRange, onClick }) {
    const getAvgTotalScore = (scores) => {
        let tmpScores = Object.assign([], scores);
        tmpScores = tmpScores.filter(s => { return s.score !== 0 });
        if (!tmpScores || tmpScores.length == 0) {
            return 0;
        } else {
            return tmpScores.reduce((a, b) => { return { score: (a.score + b.score) } }).score / tmpScores.length;
        }

    }

    return (
        <RadarChart id={id} width={400} height={400} data={scores} settings={{ maxValueX: 100, title: '' }} hideDownload={true}>
            <Grid gridDistanceX={10} />
            <Radar dataKey={'score'} stroke="#none" fill={Service.getColorFromRange(colorRange, getAvgTotalScore(scores))} fillOpacity={1} />
            <Label dataKey={'title'} onClick={onClick} />
        </RadarChart>
    )
}
