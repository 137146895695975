import React, { useState, useEffect } from 'react'

export default function Grid({ centerX, centerY, radius,data ,gridDistanceX }) {
    const [lines, setLines] = useState([]); 
    const totalAxis = data.length;
   
    const printLines = (r,printBaseLine)=>{
        let tmpLines = [];
        let outerLines = [];
if(totalAxis>0){
    for (let i = 0; i < totalAxis; i++) {
        const angle = (i) * (Math.PI * 2) / totalAxis;
        var x1 = centerX + Math.cos(angle) * (r);
        var y1 = centerY + Math.sin(angle) * (r);
        var x2 = centerX + Math.cos(angle)
        var y2 = centerY + Math.sin(angle)
        outerLines.push({ x: x1, y: y1 })
        if(printBaseLine)   tmpLines.push(<line key={i+x1+x2+y1+y2} x1={x1} y1={y1} x2={x2} y2={y2} style={{ stroke: '#dedede', strokeWidth: 1, fill: 'none' }} />)
    }
    let prevX = outerLines[outerLines.length - 1].x;
    let prevY = outerLines[outerLines.length - 1].y;
    for (let i = 0; i < outerLines.length; i++) {
        tmpLines.push(<line key={i+prevX+prevY+outerLines[i].x+outerLines[i].y} x1={prevX} y1={prevY} x2={outerLines[i].x} y2={outerLines[i].y} style={{ stroke: '#dedede', strokeWidth: 1, fill: 'none' }} />)
        prevX = outerLines[i].x;
        prevY = outerLines[i].y;
    }

}
       
       return tmpLines;
    }
     
    useEffect(() => {
        let tmpLines = []
        for(let i=0;i<=gridDistanceX;i++){
            let r = (radius/gridDistanceX) * i
            tmpLines.push(printLines(r,gridDistanceX===i))
        }
        setLines(tmpLines)
    }, [data])

    return (<>
        {lines}
    </>)
}
