import React from 'react' 
import './dialog.css';
const DialogContent = function DialogContent(props) {
    let className = props.fullScreen ? 'ui-dialog-content-fullscreen': 'ui-dialog-content';
    if(props.className){className = className +' ' + props.className }
     return (
        <div className={className}  style={props.style}>{props.children}<br style={{clear:'both'}}/></div>
    )
}
export { DialogContent };
