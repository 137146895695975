import React,{useState,useEffect} from 'react'
import { IconButton, Select, TextField } from '../../ui/core'
import {Delete} from '../../ui/coreIcons';
export default function Actions({ actions, onSave }) {
    const [items,setItems] = useState(actions);

    useEffect(() => {
        setItems(actions)
    }, [actions])

    const handleChangeAndSaveOption = (id, attribute, value) => {
        let tmpItems = Object.assign([], items);
        for (let i = 0; i < tmpItems.length; i++) {
            if (tmpItems[i]._id === id) {
                if (attribute === 'interval') {
                    tmpItems[i][attribute] = parseInt(value);
                } else {
                    tmpItems[i][attribute] = value;
                }
            }
        }
        setItems(tmpItems);
        onSave(tmpItems);
    }

    const handleChangeOption = (id, attribute, value) => {
        let tmpItems = Object.assign([], items);
        for (let i = 0; i < tmpItems.length; i++) {
            if (tmpItems[i]._id === id) {
                if (attribute === 'interval') {
                    tmpItems[i][attribute] = parseInt(value);
                } else {
                    tmpItems[i][attribute] = value;
                }
            }
        }
        setItems(tmpItems);
    }


    const deleteOption = (id) => {
        let tmpItems = Object.assign([], items);
        for (let i = 0; i < tmpItems.length; i++) {
            if (tmpItems[i]._id === id) {
                tmpItems[i].active = false;
            }
        }
        setItems(tmpItems);
        onSave(tmpItems);
    }


    return (
        <div><br/>
            <div className="label">Acties</div>
            <table style={{width:'100%'}}><tbody>
                <tr><td className="label">Beschrijving</td><td className="label">Uitvoeren na</td><td className="label">Periode</td><td></td></tr>
                {actions && actions.filter(action=>{return action.active==true}).map(action => {
                    return <tr key={action._id}>
                        <td style={{width:'70%'}}><TextField id="description" value={action.description} onChange={(ev)=>{handleChangeOption(action._id,'description',ev.target.value)}} onBlur={(ev)=>{handleChangeAndSaveOption(action._id,'description',ev.target.value)}} /></td>
                        <td><TextField  id="interval" type="number" value={action.interval}  onChange={(ev)=>{handleChangeOption(action._id,'interval',ev.target.value)}} onBlur={(ev)=>{handleChangeAndSaveOption(action._id,'interval',ev.target.value)}}/></td>
                        <td><Select id="timeperiod" value={action.timeperiod}  onChange={(ev)=>{handleChangeOption(action._id,'timeperiod',ev.target.value)}} onBlur={(ev)=>{handleChangeAndSaveOption(action._id,'timeperiod',ev.target.value)}}> 
                            <option value='hours'>uren</option>
                            <option value='days'>dagen</option>
                            <option value='months'>maanden</option>
                            </Select>
                        </td>
                        <td><IconButton onClick={()=>deleteOption(action._id)}><Delete/></IconButton></td>
                       </tr>
                })}</tbody></table>
        </div>
    )
}
