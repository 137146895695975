import React, { useState } from 'react'
import { Button ,Typography} from '../ui/core'
import './index.css'
import { v4 as uuidv4 } from 'uuid';
import Question from './edit/question';
import DOMPurify from 'dompurify';
export default function Index({ quiz, onChange }) {
    const [selectedQuestion, setSelectedQuestion] = useState();


    const handleAddQuestion = () => {
        let tmpItem = Object.assign({}, quiz);
        if (!tmpItem.questions) tmpItem.questions = [];
        let maxSort = 0;
        for (let i = 0; i < tmpItem.questions.length; i++) {
            if (tmpItem.questions[i].sort > maxSort) maxSort = tmpItem.questions[i].sort;
        }
        let newQuestion = {
            _id: uuidv4(),
            sanitizeQuestion:"Nieuwe vraag...",
            question:"Nieuwe vraag...",
            options: [{ _id: uuidv4(), sort: 1, text: '', active: true }],
            active: true,
            type:0,
            sort: maxSort + 1
        };
        tmpItem.questions.push(newQuestion);
        onChange(tmpItem, true);
    }

    const handleChangeQuestion = (question, save) => {
        if (question) {
            question.sanitizeQuestion = DOMPurify.sanitize(question.question?question.question.replace(/&nbsp;/g, " "):"Nieuwe vraag..", { ALLOWED_TAGS: [] })
            let tmpItem = Object.assign({}, quiz);
            if (!tmpItem.questions) tmpItem.questions = [];
            let found = false;
            for (let i = 0; i < tmpItem.questions.length; i++) {
                if (tmpItem.questions[i]._id === question._id) {
                    tmpItem.questions[i] = question;
                    found = true;
                }
            }
            if (!found) tmpItem.questions.push(question)
            onChange(tmpItem, save);
        } else {
            onChange(null, save);
        }
    }

    return (
        <div style={{ display: 'flex' ,width: '100%'}}>
            <div style={{ width: '300px' }}>
            <Typography component="h1">Vragen</Typography>
                {quiz.questions && quiz.questions.map(q => {
                    if(!q.sanitizeQuestion) q.sanitizeQuestion =''
                    return <div key={q._id} className="quiz-menu-question" onClick={() => setSelectedQuestion(q)}>{q.sanitizeQuestion && q.sanitizeQuestion.substr(0,24)}{ q.sanitizeQuestion.length>24 ? '...':''}</div>
                })}
                <br />
                <Button onClick={handleAddQuestion}>Vraag toevoegen</Button>
            </div>
            <div style={{ width: '100%' }}>
                {selectedQuestion && <Question question={quiz.questions.find(q => { return q._id === selectedQuestion._id })} onChange={handleChangeQuestion} />}
            </div>
        </div>
    )
}
