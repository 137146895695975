import React,{useContext} from 'react'

import {Context} from '../../AppContext';
import {ContextUI} from '../../uiContext';

import  Logo from '../../graphics/menu-logo.png'
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Badge,Divider } from '../../components/ui/core';
import { SwapHoriz,KeyBoard, Message, Search as SearchIcon, Settings as SettingsIcon, ViewList, Email, Info as InfoIcon, ExitToApp, BuildCircle ,Home} from '../../components/ui/coreIcons';


export default function Menu() {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
   
    return (
        <>
        <img src={Logo}/>
        <List style={{ width: '270px' }}>
            <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context && context.history) context.history.push("/organisatie") }}><ListItemIcon><ViewList /></ListItemIcon> <ListItemText primary="Organisatie"></ListItemText></ListItem>
          
            <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context && context.history) context.history.push("/") }}><ListItemIcon><Home /></ListItemIcon> <ListItemText primary="Homepage"></ListItemText></ListItem>
     
            <ListItem onClick={() => { contextUI.setShowSideBar(false); context.logout(context.history);  }}><ListItemIcon><ExitToApp /></ListItemIcon> <ListItemText primary="Uitloggen"></ListItemText></ListItem>
        </List>
        </>
    )
}
