import React, { useState, useEffect } from 'react'
import { Typography, Paper } from '../../../ui/core'; 
import QuizBuilder from '../../../quiz-builder/index';
export default function Index({ item, onChange }) {   
    const handleChangeQuiz = (quiz,save) => {  
        let tmpStep = JSON.parse(JSON.stringify(item)); 
        if(quiz){
            tmpStep.quiz = Object.assign({},quiz); 
            if(!tmpStep.quiz.questions){tmpStep.quiz.questions = []}
            tmpStep.quiz.questions =  tmpStep.quiz.questions.filter(q=>{return q.active===true}).sort((a,b)=>{return a.sort-b.sort})
        }  
       onChange(tmpStep,save); 
    }

    if (!item) return null;

    return (
        <div>
            <Paper className="padding">
                <Typography component="h1">{item.title}</Typography> 
                <QuizBuilder quiz={item.quiz || []} onChange={handleChangeQuiz} />
            </Paper>
        </div>
    )
}
