import React from 'react'

export default function Text({block}) {
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    return (
        <div  className="padding block-content w3-animate-bottom">
            {block.textStyle && block.textStyle.indexOf('tit') >= 0 &&<h1>{block.title}</h1>}
            {block.textStyle && block.textStyle.indexOf('sub') >= 0 &&<h2>{block.title}</h2>}
            {block.textStyle && block.textStyle.indexOf('par') >= 0 &&<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.paragraph) }} ></div>}
        </div>
    )
}
