import React from 'react'
import { Paper, SearchBar, Table } from '../ui/core';

export default function list({items,onClick}) {
    const headers = [
        { label: 'Achternaam', attribute: 'lastname', default: true },
        { label: 'Voornaam', attribute: 'firstname', default: true },
        { label: 'Email', attribute: 'email', default: true },
        { label: 'Functie', attribute: 'funct', default: true }
    ] 
    //    <SearchBar />
    return (
        <> 
            <div className="padding"><Table items={items} headers={headers} onClick={onClick}/></div>
        </>
    )
}
