import React from 'react'
import { XYPlot, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LabelSeries, RadarChart } from 'react-vis';
import './monitor-dash-bar.css'
export default function MonitorDashBar({ chartData, monitor, width,onClick }) {

    return (
        <XYPlot height={300} width={width} xType="ordinal"
            margin={{
                left: 60,
                top: 30,
                bottom: 80,
                right: 30
            }}
            yDomain={[0, 100]}
        >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis tickLabelAngle={-25} />
            <YAxis tickTotal={10} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} title="Procent" />

            <VerticalBarSeries colorType="literal" className="vertical-bar-series-example" data={chartData} style={{cursor:'pointer'}} onValueClick={onClick}/>
        </XYPlot>
    )
}
