import React, { useState, useEffect } from 'react'
import InsertLink from "./link";
import InsertTable from "./table";
import InsertImage from "./image";
import InsertVideoControl from "./video"
import { Tooltip } from '../ui/core';
import { Close,CleaningService, FormatBold, Title, FormatItalic, FormatUnderlined, FormatStrikethrough, FormatAlignLeft, FormatAlignCenter, FormatAlignRight, FormatAlignJustify, FormatListNumbered, FormatListBulleted, Link, InsertPhoto, TableChart, Extension, Build,Movie } from  '../ui/coreIcons';



export default function Menu(props) {
    const [insertElement, setInsertElement] = useState()
    const [showLink, setShowLink] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const getRange = () => {
        return window.tempValueRangeVEditor;
    }
    const restoreSelection = (item, savedRange) => {
        item.focus();
        if (savedRange != null) {
            if (window.getSelection)//non IE and there is already a selection
            {
                var s = window.getSelection();
                if (s.rangeCount > 0)
                    s.removeAllRanges();
                s.addRange(savedRange);
            }
            else if (document.createRange)//non IE and no selection
            {
                window.getSelection().addRange(savedRange);
            }
            else if (document.selection)//IE
            {
                savedRange.select();
            }
        }
    }

    const executeCommand = (cmd, args) => {
        restoreSelection(props.contentEditable.current, getRange());
        document.execCommand(cmd, false, (args || null));
        props.onChange()
        props.contentEditable.current.focus();
    }

    useEffect(() => {
        if (insertElement) {
            getRange().deleteContents();
            getRange().insertNode(getRange().createContextualFragment(insertElement));
            props.onChange();
            setInsertElement(null);
        }
    }, [insertElement])


    const openAction = (action) => {
        try {
            if (getRange().startContainer.parentNode.isContentEditable || getRange().startContainer.className.indexOf('veditor') >= 0) {
                switch (action) {
                    case 'link':
                        setShowLink(true);
                        break;
                    case 'table':
                        setShowTable(true);
                        break;
                    case 'image':
                        setShowImage(true);
                        break;
                    case 'video':
                        setShowVideo(true);
                        break;
                    default:
                        break;
                }
            }

        } catch (ex) { console.log(ex) }
    }

    const commandAvailable = (cmd) => {
        return document.queryCommandSupported(cmd);
    }

    const buttonStyleToggle = { color: '#656565', padding: '4px', cursor: 'pointer', display: 'inline-block', borderRight: '1px solid silver' }
    const buttonStyle = { color: '#656565', padding: '4px', cursor: 'pointer', display: 'inline-block' }
    return (
        <div style={{ borderBottom: '1px solid silver', }}>

            {showLink && <InsertLink onSave={setInsertElement} files={props.files} pages={props.pages} onClose={() => { setShowLink(false) }} range={getRange()} />}
            {showTable && <InsertTable onSave={setInsertElement} onClose={() => setShowTable(false)} />}
            {showImage && <InsertImage onSave={setInsertElement} onClose={() => setShowImage(false)} />}
            {showVideo && <InsertVideoControl onSave={setInsertElement} onClose={() => setShowVideo(false)} />}

            {(props.formModus === true) && <Tooltip title="Overschakelen naar editmodus" placement="top"><div style={buttonStyleToggle} className='hover' onClick={evt => { evt.preventDefault(); props.onChange(); props.onSetFormModus(false) }}> <Build /></div></Tooltip>}
            {(props.formModus === false) && <Tooltip title="Overschakelen naar formuliermodus" placement="top"><div style={buttonStyleToggle} className='hover' onClick={evt => { evt.preventDefault(); props.onChange(); props.onSetFormModus(true) }}> <Close /></div></Tooltip>}

            {props.formModus === false &&
                <React.Fragment>
                    {commandAvailable('formatBlock') && <Tooltip title="Titel" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('formatBlock', '<h1>'); }}><Title /></div></Tooltip>}
                    <Tooltip title="Vet gedrukt" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('bold', ''); }}><FormatBold /></div></Tooltip>
                    <Tooltip title="Schuin" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('italic', ''); }} ><FormatItalic /></div></Tooltip>
                    <Tooltip title="Onderstreept" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('underline', ''); }}><FormatUnderlined /></div></Tooltip>
                    <Tooltip title="Doorhalen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('strikethrough', ''); }} ><FormatStrikethrough /></div></Tooltip>
                    <Tooltip title="Links uitlijnen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('justifyLeft', ''); }} ><FormatAlignLeft /></div></Tooltip>
                    <Tooltip title="Centreren" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('justifyCenter', ''); }} ><FormatAlignCenter /></div></Tooltip>
                    <Tooltip title="Rechts uitlijnen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('justifyRight', ''); }}><FormatAlignRight /></div></Tooltip>
                    <Tooltip title="Uitvullen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('justifyFull', ''); }}><FormatAlignJustify /></div></Tooltip>

                    <Tooltip title="Opmaak verwijderen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('removeFormat', ''); }}><CleaningService /></div></Tooltip>

                    <Tooltip title="Nummerieke opsomming" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('insertorderedlist', ''); }} ><FormatListNumbered /></div></Tooltip>
                    <Tooltip title="Opsomming" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); executeCommand('insertunorderedlist', ''); }} ><FormatListBulleted /></div></Tooltip>


                    <Tooltip title="Link toevoegen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); openAction('link') }} ><Link /></div></Tooltip>
                    <Tooltip title="Tabel toevoegen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); openAction('table') }} ><TableChart /></div></Tooltip>
                    <Tooltip title="Afbeelding toevoegen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); openAction('image') }} ><InsertPhoto /></div></Tooltip>
                    <Tooltip title="Video toevoegen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); openAction('video') }} ><Movie /></div></Tooltip>
                    {'a' === 'b' && <Tooltip title="Formulier element toevoegen" placement="top"><div style={buttonStyle} className='hover' onClick={evt => { evt.preventDefault(); openAction('formelement') }}><Extension /></div></Tooltip>}

                </React.Fragment>
            }
        </div>
    )
}
