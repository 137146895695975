import React,{useState} from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography,Button, Tabs, TextField } from '../../ui/core'
import List from '../../users/list';
export default function AddRespondent({users,onAddUser,onAddExternal,onClose}) {
    
    const [selectedTab,setSelectedTab] = useState(0);
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    return (
        <Dialog fullScreen>
            <DialogTitle><Typography component="h1">Toevoegen waarnemer</Typography></DialogTitle>
            <DialogContent>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <div>Medewerkerslijst organisatie</div>
                    <div>Externe waarnemer</div>
                </Tabs>
                {selectedTab===0 && <List items={users} onClick={onAddUser}/>}
                {selectedTab===1 && <div className="padding">
                    <TextField id="name" value={name} onChange={(ev)=>{setName(ev.target.value)}} label="Naam"></TextField>
                    <TextField id="email" value={email} onChange={(ev)=>{setEmail(ev.target.value)}} label="E-mailadres"></TextField>
                    </div>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Annuleren</Button>
                {selectedTab===1 && <Button onClick={()=>{onAddExternal(name,email)}} disabled={name.length===0 || (email.indexOf('@')===-1 || email.indexOf('.')===-1)}>Opslaan</Button>}
            </DialogActions>
        </Dialog>
    )
}
 