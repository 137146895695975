import React from 'react' 
import { Button, Dialog,DialogActions, DialogContent, DialogTitle, Typography } from '../ui/core';
import List from './list';
export default function QuickSearch({title,items,onClick,onClose}) {

    return (
        <Dialog onClose={onClose} fullScreen={true}>
            <DialogTitle><Typography component="h2">{title || 'Zoeken vragenlijst'}</Typography></DialogTitle>
            <DialogContent>
                <List items={items} onClick={onClick}/>
            </DialogContent>
            <DialogActions>
                 <Button onClick={onClose}>SLUITEN</Button>
            </DialogActions>
        </Dialog>
    )
}
