import React, { useEffect, useState, useContext } from 'react'
import { Typography, Button, TextArea, TextField, CheckBox, IconButton, Fab, Select } from '../ui/core'
import { Add, Delete } from '../ui/coreIcons'
import { v4 as uuidv4 } from 'uuid';
import ServiceUsers from '../../services/user';
import Service from '../../services/evaluations';
import QuickSearch from '../users/quicksearch';
import { Context } from '../../AppContext';
export default function Index({ evaluation, onSave }) {
    const context = useContext(Context);
    const [item, setItem] = useState()
    const [showSearch, setShowSearch] = useState(false);
    const [users, setUsers] = useState([]);
    const [canWrite, setCanWrite] = useState(false)
    useEffect(() => {
        let tmpItem = Object.assign({}, evaluation);
        setItem(tmpItem);
        ServiceUsers.getForOrganisation(tmpItem.organisation)
            .then(users => {
                setUsers(users);
            })
    }, [evaluation])

    const handleChangeSelect = (id, value) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.rights) tmpItem.rights = [];
        let search = tmpItem.rights.find(right => { return right._id === id });
        if (search) {
            search.right = parseInt(value);
        }
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const handleAddUser = (user) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.rights) tmpItem.rights = [];
        let search = tmpItem.rights.find(right => { return right.user === user._id });
       
        if (!search) {
            tmpItem.rights.push({
                _id: uuidv4(),
                user: user._id,
                email: user.email,
                name: ServiceUsers.getFullName(user),
                right: 10
            })
        }

        setItem(tmpItem);
        onSave(tmpItem);
        setShowSearch(false);
    }

    const handleDelete = (id) => {
        let result = window.confirm('Weet u zeker dat u deze medewerker uit de lijst wilt verwijderen?');
        if (result) {
            let tmpItem = Object.assign({}, item);
            if (!tmpItem.rights) tmpItem.rights = [];
            for (let i = tmpItem.rights.length - 1; i >= 0; i--) {
                if (tmpItem.rights[i]._id === id) {
                    tmpItem.rights.splice(i, 1);
                }
            }
            setItem(tmpItem);
            onSave(tmpItem);
        }
    }

    useEffect(() => {
        setCanWrite(Service.hasRight(evaluation, context.user, 100));
    }, [evaluation, context.user])

    const headerStyle = { backgroundColor: 'rgb(0, 105, 143)', color: 'white' }
    if (!item) return null;
    return (
        < >
            {showSearch && <QuickSearch items={users} onClick={handleAddUser} onClose={() => { setShowSearch(false) }} />}
            <table className="ui-table" style={{ width: '100%' }}><tbody>
                {item.rights && item.rights.length > 0 && <tr><th style={headerStyle} >Medewerker</th><th style={headerStyle} >Email</th><th style={headerStyle} >Rechten</th><th style={headerStyle} ></th></tr>}
                {item.rights && item.rights.map(right => {
                    return <tr key={right._id}>
                        <td>{right.name}</td>
                        <td>{right.email}</td>
                        <td>{
                            canWrite ?
                                <Select value={right.right} onChange={(ev) => handleChangeSelect(right._id, ev.target.value)}>
                                    {Service.rights().map(r => {
                                        return <option key={r.val} value={r.val}>{r.text}</option>
                                    })}

                                </Select>
                                :
                                <>{Service.rights().filter(r=>{return r.val===right.right}).map(r=>{return r.text}).join('')}</>
                        }
                        </td>
                    <td>{canWrite && <IconButton onClick={() => { handleDelete(right._id) }} ><Delete /></IconButton>}</td></tr>
                })}
            </tbody></table>
           {canWrite && <Button variant="outlined" fullWidth onClick={() => { setShowSearch(true) }}>Medewerker toevoegen</Button>}

        </>
    )
}
