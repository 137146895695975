import React, { useEffect, useState } from 'react'

import { TextField, TextArea, CheckBox } from '../ui/core';
export default function Item({ item, onSave }) {
    const [editItem, setEditItem] = useState(item);

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        console.log(tmpItem)
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] =  ev.target.value || ev.target.checked;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    useEffect(() => {
        setEditItem(item);
    }, [item])

    /*
    <td valign="top">
                    <div className="label">Voor module</div>
                    <CheckBox id={'forevaluation'} checked={editItem.forevaluation || false} label={'Evaluatie'} onChange={handleSave}  ></CheckBox>
                    <CheckBox id={'forelearning'} checked={editItem.forelearning || false} label={'E-learning'} onChange={handleSave} ></CheckBox>
                </td>
     */
    return (
        <div className="padding">
            {editItem && <table style={{width:'100%'}}><tbody><tr>
                <td valign="top">
                <TextField id="title" label="Titel" value={editItem.title} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextArea id="description" label="Omschrijving" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea> 
                </td>
                
                </tr></tbody></table>}
        </div>
    )
}
