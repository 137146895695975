import React, { useEffect, useState } from 'react'

import { TextField, TextArea, CheckBox } from '../ui/core';

import Service from '../../services/checklistitems';

export default function Item({ item, onSave }) {
    const [editItem, setEditItem] = useState(item);
    const [steps, setSteps] = useState([])
    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    const handleChangeAndSave = (attribute, value) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }

    useEffect(() => {

        Service.getForChecklist(item._id)
            .then(checklistItems => {

                let max = 1;
                for (let i = 0; i < checklistItems.length; i++) {
                    if (checklistItems[i].step && parseInt(checklistItems[i].step) > max) {
                        max = checklistItems[i].step;
                    }
                }
                let steps = [];
                for (let i = 1; i <= max; i++) {
                    steps.push(i);
                }
                setSteps(steps)

            })
            .catch(err => { console.log(err) })



    }, [])


    useEffect(() => {
        setEditItem(item);
    }, [item])

    //todo
    const handleChangeStep = (step,value) =>{
        let tmpItem = Object.assign({}, editItem);
        tmpItem['labelstep_'+step] =value
        setEditItem(tmpItem);
    }

    const handleSaveStep = (step,value) =>{
        let tmpItem = {};
        tmpItem['labelstep_'+step] =value
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    return (
        <div className="padding">
            {editItem && <>
                <TextField id="title" label="Titel" value={editItem.title} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextArea id="description" label="Omschrijving" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea>
                <br/><br/>
                {steps.length > 0 && <div className="label">Titels voor de stappen:</div>}
                {steps.map(step => {
                    return <div key={step}> <TextField id={"step" + step} label={"Stap " + step} value={editItem['labelstep_'+step]===undefined?('Stap '+step):editItem['labelstep_'+step]} onChange={(ev)=>handleChangeStep(step,ev.target.value)} onBlur={(ev)=>handleSaveStep(step,ev.target.value)}></TextField></div>
                })}
                <br/>
                <div className="label">Publiceren</div>
                <CheckBox label="Controle lijst is beschikbaar" checked={editItem.published || false} onChange={(ev) => { handleChangeAndSave('published', ev.target.checked) }} />

                <div className="label">Archiveren</div>
                <CheckBox label="In archief plaatsen" checked={editItem.archived || false} onChange={(ev) => { handleChangeAndSave('archived', ev.target.checked) }} />
                <div className="label">Wanneer dit item is gearchiveerd kan men geen nieuwe case meer aanmaken met deze controle lijst. Bestaande cases blijven bestaan.</div>
            </>}
        </div>
    )
}
