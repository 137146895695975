import React,{useState,useEffect,useRef} from 'react'
import VEditor from "../../veditor/veditor"
import { Button, Dialog,DialogActions, DialogContent, TextArea ,Paper} from '../../ui/core'

export default function Wiki({item,onSave,onClose}) {
    const [contentRef] = useState(React.createRef());
   
    return (
        <Dialog open={true} fullScreen={true} onClose={onClose}>
            <DialogContent>
              <VEditor id="html-edit" value={item.wiki} contentRef={contentRef} files={[]} pages={[]} />
               </DialogContent>
            <DialogActions><Button onClick={()=>onSave( contentRef.current.innerHTML)} >Sluiten</Button></DialogActions>
        </Dialog>
    )
}
