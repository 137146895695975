
const XLSX = require('xlsx');
const Service = {
    downloadHTMLTable:(table)=>{
        var workbook = XLSX.utils.book_new(); 
        var ws1 = XLSX.utils.table_to_sheet(table);
        XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
        XLSX.writeFile(workbook, 'export.xlsx');
    }
}
export default Service;