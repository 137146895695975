import React, { useState, useEffect } from 'react'
import { CirculairProgress, TextArea } from '../ui/core';
import { Grading } from '../ui/coreIcons';
import ServiceChecklist from '../../services/checklist';
export default function ItemCaseSteps({ steps, items, item, onClick }) {
    const styleStep = { padding: '12px', cursor: 'pointer', display: 'flex', color: '#333333' }
    const [checklist, setChecklist] = useState({});
    const getPercentageComplete = (step) => {
        let complete = 0;
        let foundItems = items.filter(item => { return item.step === step && item.active === true && item.type !== 2 })
        if (foundItems.length > 0) {
            let foundItemsComplete = foundItems.filter(item => { return item.complete === true })
            return Math.round(((100 / parseFloat(foundItems.length)) * foundItemsComplete.length));
        }
        return 0
    }

    useEffect(() => {
        ServiceChecklist.get(item.checklist)
            .then(checklist => { setChecklist(checklist) })
    }, [item])

    return (
        <>
            {steps.map(step => {
                return <div style={styleStep} onClick={() => { onClick(step) }} key={step} >
                    <div style={{ paddingRight: '10px' }}>
                        <Grading color="333333" />
                    </div>
                    <div style={{ width: '90%' }}>{checklist['labelstep_' + step] === undefined ? ('Stap ' + step) : checklist['labelstep_' + step]}</div>
                    <div><CirculairProgress progress={getPercentageComplete(step)} /></div>
                </div>
            })}
        </>
    )
}
