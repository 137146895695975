import React from 'react'

import useWindowSize from '../hooks/windowsize';
export default function Text({ block }) {
    const windowsize = useWindowSize();
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);

    let styleVideo = {width: '100%'}
      
    if(windowsize.innerHeight < windowsize.innerWidth){
        styleVideo = {height: (windowsize.innerHeight - 64)+'px'};
    }

    return (
        <div className="padding block-content w3-center w3-animate-bottom">
             <video style={styleVideo} controls={true}>
                <source src={block.video} type="video/mp4"/> 
            </video>
            {block.videoStyle && block.videoStyle.indexOf('par') >= 0 && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.paragraph) }} ></div>}
        </div>
    )
}
