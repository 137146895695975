import React from 'react'
import { IconButton, Select, CheckBox } from '../ui/core'
import { Delete, FileCopy, KeyBoardArrowUp, KeyBoardArrowDown, Settings } from '../ui/coreIcons'

import { v4 as uuidv4 } from 'uuid';
export default function Blockoptions({ block, onChange, onSave, selected }) {
    const styleButton = { float: 'left' }

    const handleDelete = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm('Weet u zeker dat u dit blok wilt verwijderen?')) {
            let tmpBlock = Object.assign({}, block);
            tmpBlock.active = false;
            onChange(tmpBlock, true);
        }
    }

    const handleChangeAttribute = (attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value;
        onChange(tmpBlock, true);
    }
    const handleMoveUp = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        let tmpBlock = Object.assign({}, block);
        tmpBlock.sort = tmpBlock.sort - 2;
        onChange(tmpBlock, true);
    }
    const handleMoveDown = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        let tmpBlock = Object.assign({}, block);
        tmpBlock.sort = tmpBlock.sort + 2;
        onChange(tmpBlock, true);
    }
    const copyBlock = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        let tmpBlock = Object.assign({}, block);
        tmpBlock._id = uuidv4();
        onChange(tmpBlock, true);
    }

    //   <IconButton style={styleButton}><Settings color="gray" /></IconButton>
    if (selected === false) { return <div style={{ height: '44px' }}>&nbsp;</div> }
    return (
        <>
            <div className="block-options fade-in">

                {block.type === 'image' &&
                    <div className="block-option-left">
                        <Select style={{ ...styleButton, width: '115px', paddingLeft: '4px' }} value={block.imageStyle} onChange={(ev) => { handleChangeAttribute('imageStyle', ev.target.value) }}>
                            <option value={'full'}>Volle breedte</option>
                            <option value={'textleft'}>Tekst links naast de afbeelding</option>
                            <option value={'textonimage'}>Tekst op de afbeelding</option>
                        </Select>
                    </div>}


                {block.type === 'text' &&
                    <div className="block-option-left">
                        <Select style={{ ...styleButton, width: '115px', paddingLeft: '4px' }} value={block.textStyle} onChange={(ev) => { handleChangeAttribute('textStyle', ev.target.value) }}>
                            <option value={'par'}>Paragraaf</option>
                            <option value={'partit'}>Paragraaf met titel</option>
                            <option value={'parsub'}>Paragraaf met subtitel</option>
                            <option value={'tit'}>Titel</option>
                            <option value={'sub'}>Subtitel</option>
                            <option value={'twocol'}>Twee kolommen</option>
                            <option value={'say'}>Uitspaak</option>
                            <option value={'info'}>Info</option>
                        </Select>
                    </div>}

                {block.type === 'paragraphimage' || block.type === 'paragraphyoutube' &&
                    <div className="block-option-left">
                        <Select style={{ ...styleButton, width: '135px', paddingLeft: '4px' }} value={block.alignImage} onChange={(ev) => { handleChangeAttribute('alignImage', ev.target.value) }}>
                            <option value={'right'}>Afbeelding rechts</option>
                            <option value={'left'}>Afbeelding links</option>
                        </Select>
                    </div>}

                <div className="block-option-right">
                    {block.sort !== 0 && <IconButton style={styleButton} onClick={handleMoveUp}><KeyBoardArrowUp color="gray" /></IconButton>}
                    <IconButton style={styleButton} onClick={handleMoveDown}><KeyBoardArrowDown color="gray" /></IconButton>
                    <IconButton style={styleButton} onClick={handleDelete}><Delete color="gray" /></IconButton>
                    <IconButton style={styleButton} onClick={copyBlock}><FileCopy color="gray" /></IconButton>
                </div>
                <br style={{ clear: 'both' }} />
            </div>
        </>
    )
}
