import React from 'react'
import {CirculairProgress, TextArea} from '../ui/core';
import {AssignementTurnedIn,ChromeReaderMode} from '../ui/coreIcons'
export default function ItemSteps({steps,personalSteps,onClick}) {
    const styleStep = {padding:'12px',cursor:'pointer',display:'flex',color:'#333333'} 

    const getProgress = (stepID) =>{
      let found =  personalSteps.find(step=>{return step.elearningStep===stepID});
      if(found){
          return found.progress || 0
      }else{
          return 0;
      }
    }

    return (
        <div>
            {steps.map(step=>{
                return <div style={styleStep} onClick={()=>{onClick(step)}} key={step._id} >
                    <div style={{paddingRight:'10px'}}>
                    {step.type===0?<ChromeReaderMode color="#333333"/>:<AssignementTurnedIn color="#333333"/> }
                    </div>
                    <div style={{width:'90%'}}>{step.title} </div>
                  
                    <div><CirculairProgress progress={getProgress(step._id)}/></div>
                    </div>
            })}
        </div>
    )
}
