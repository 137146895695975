import React,{useContext} from 'react'

import {Context} from '../../AppContext';
import {ContextUI} from '../../uiContext';

import  Logo from '../../graphics/menu-logo.png'
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Badge } from '../../components/ui/core';
import { SwapHoriz,KeyBoard, Message, Search as SearchIcon, Settings as SettingsIcon, ViewList, Email, Info as InfoIcon, ExitToApp, DoneAll,Business,People,CheckCircle,School ,Settings, Home} from '../../components/ui/coreIcons';


export default function Menu() {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    return (
        <>
        <img src={Logo}/>
        <List style={{ width: '270px' }}>
           {(context.hasRights(context.user, 'fullcontrol-organisations', 'w')||context.hasRights(context.user, 'fullcontrol-users', 'w')||context.hasRights(context.user, 'fullcontrol-surveys', 'w')||context.hasRights(context.user, 'fullcontrol-elearnings', 'w')||context.hasRights(context.user, 'fullcontrol-checklists', 'w')) &&  <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer") }}><ListItemIcon><ViewList /></ListItemIcon> <ListItemText primary="Dashboard"></ListItemText></ListItem>}

           {context.hasRights(context.user, 'fullcontrol-organisations', 'w') &&  <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/organisaties") }}><ListItemIcon><Business /></ListItemIcon> <ListItemText primary="Organisaties"></ListItemText></ListItem>}
           {context.hasRights(context.user, 'fullcontrol-users', 'w') &&   <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/gebruikers") }}><ListItemIcon><People /></ListItemIcon> <ListItemText primary="Gebruikers"></ListItemText></ListItem>}
            {context.hasRights(context.user, 'fullcontrol-surveys', 'w') &&  <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/vragenlijsten") }}><ListItemIcon><CheckCircle /></ListItemIcon> <ListItemText primary="Vragenlijsten"></ListItemText></ListItem>}
            {context.hasRights(context.user, 'fullcontrol-elearnings', 'w') &&   <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/e-learning") }}><ListItemIcon><School /></ListItemIcon> <ListItemText primary="E-learning trajecten"></ListItemText></ListItem>}
            {context.hasRights(context.user, 'fullcontrol-checklists', 'w') &&   <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/checklijsten") }}><ListItemIcon><DoneAll /></ListItemIcon> <ListItemText primary="Bevoegdheden applicatie"></ListItemText></ListItem>}
            {context.hasRights(context.user, 'fullcontrol-settings', 'w') &&   <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/instellingen") }}><ListItemIcon><Settings /></ListItemIcon> <ListItemText primary="Instellingen"></ListItemText></ListItem>}
                 
            <ListItem onClick={() => {  contextUI.setShowSideBar(false); if (context.history) context.history.push("/") }}><ListItemIcon><Home /></ListItemIcon> <ListItemText primary="Homepage"></ListItemText></ListItem>

            <ListItem onClick={() => { contextUI.setShowSideBar(false); context.logout(context.history);  }}><ListItemIcon><ExitToApp /></ListItemIcon> <ListItemText primary="Uitloggen"></ListItemText></ListItem>
                 
        </List>
        </>
    )
}
