import API from "./api";

const ATTRIBUTE = "settings";

const Service = {
    get: () => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;