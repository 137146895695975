import API from "./api";
import StorageService from './storage';

const Service = {
    get: (elearningID,elearningStepID) => {
        return new Promise((resolve, reject) => {
            API.get(`/elearnings/${elearningID}/elearningsteps/${elearningStepID || 'all'}/personal`).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (elearningID,elearningStepID,personalStep) => {
        return new Promise((resolve, reject) => {
            API.post(`/elearnings/${elearningID}/elearningsteps/${elearningStepID}/personal`, [], { item:personalStep}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    } ,
    calculateComplete : (steps,personalSteps)=>{
        let retVal = 0;
        
        if(steps && steps.length > 0 && personalSteps && personalSteps.length > 0){
            let countComplete = 0;
            for(let i=0;i<personalSteps.length;i++){
                if(personalSteps[i].progress){
                    countComplete += personalSteps[i].progress
                }
            }
            retVal = Math.round(100/(steps.length*100) * countComplete);
        } 

        return retVal;
    }
}
export default Service;