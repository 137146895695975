import React,{useState,useEffect,useContext} from 'react'
import Service  from '../../services/elearning';
import List from '../../components/module-elearning-personal/list';
import {Context} from '../../AppContext';
import {ContextUI} from '../../uiContext';
import { Help } from '../../components/ui/coreIcons'
import { SearchBar,IconButton } from '../../components/ui/core';
import SelectOrganisation from '../../components/organisations/selector';
export default function Index(props) {
    const [items, setItems] = useState([]);
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [itemsFiltered, setItemsFiltered] = useState([]); 
    const [search,setSearch] = useState('');
    useEffect(()=>{ 
        contextUI.setShowHeader(true);
        contextUI.setSubTitle('Dashboard');
        contextUI.setShowBackButton(false);
       // contextUI.setHeaderActions();//<IconButton onClick={addItem}><Add color="white" /></IconButton>)
        contextUI.setHeaderActions(<IconButton onClick={()=>context.setShowFAQ(1)}><Help color="white" /></IconButton>)
        Service.get()
        .then(items=>{
            if(context.organisation && context.organisation.elearnings){
                setItems(items.filter(item=>{return context.organisation.elearnings.indexOf(item._id)>=0 }))
            }else{
                setItems([])
            }
        })
        .catch(ex=>{console.log(ex)})
    },[context.organisation])

    useEffect(() => {
        setItemsFiltered(items.filter(i=>{return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase())>=0}));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])

//props.history.push('/e-learning/'+item._id)
    return (
        <div>
            <SelectOrganisation />
            <SearchBar value={search} onKeyUp={setSearch} onReset={()=>{setSearch('')}}></SearchBar>
          
            <List items={itemsFiltered}  onClick={(item)=>{window.open(item.url,'_blank')}}/>
        </div>
    )
}
