import React, { useState, useContext, useEffect } from 'react'
import ServiceChecklist from '../../services/checklist';
import Service from '../../services/checklistcase';
import ServiceItems from '../../services/checklistcaseitems';
import Item from '../../components/module-checklist-case/item.js'
import { ContextUI } from '../../uiContext';
import { Context } from '../../AppContext';
import {  Menu,  IconButton, MenuItem } from '../../components/ui/core';
import {  MoreVert,Help  } from '../../components/ui/coreIcons'

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [checklist,setCheckList] = useState()
    const [checklistCaseItems,setChecklistCaseItems] = useState([]); 
    const [canWrite, setCanWrite] = useState(false);

    const handleDelete = () => {
        if (window.confirm("Weet u zeker dat u deze case wilt verwijderen?")) {
            Service.remove({ _id: props.match.params.id })
                .then(result => {
                    props.history.push('/checklist');
                })
                .catch(ex => { console.log(ex) })
        }
    }

    useEffect(() => {
        setCanWrite(Service.hasRight(item, context.user, 100));
    }, [item, context.user])

    useEffect(() => {
        getItems();
      
        contextUI.setSubTitle('Case');
        contextUI.setShowBackButton(true); 

        if (canWrite) {
            let menu = <Menu id="additemmenu" noscroll={true} control={<IconButton><MoreVert color="white" /></IconButton>}>
                <MenuItem onClick={() => { handleDelete() }}>Verwijder case</MenuItem>
            </Menu> 
            contextUI.setHeaderActions(menu);
            contextUI.setHeaderActions(<><IconButton onClick={()=>context.setShowFAQ(0)}><Help color="white" /></IconButton>{menu}</>)
        } else {
            contextUI.setHeaderActions(null);
            contextUI.setHeaderActions(<><IconButton onClick={()=>context.setShowFAQ(0)}><Help color="white" /></IconButton></>)
        }

        return () => { }
    }, [canWrite])

    useEffect(() => {
       if(checklist && checklist.title){
        contextUI.setSubTitle(checklist.title);
       }
    }, [checklist])

    const getItems = () => {
        Service.get(props.match.params.id)
            .then(item => {
                setItem(item); 

                ServiceChecklist.get(item.checklist)
                .then(result=>{ 
                    setCheckList(result)
                })
                .catch(err=>{console.log(err)})

               
                ServiceItems.getForCase(item._id)
                .then(result=>{ 
                    setChecklistCaseItems(result)
                })
                .catch(err=>{console.log(err)})
            })
            .catch(err => { console.log(err) })
    }
  
    return (
        <div >
            {item && <>
                <Item key={item._id} item={item} items={checklistCaseItems} onRefresh={getItems}/>
            </>}
        </div>
    )
}
