import React, { useEffect, useState,useContext } from 'react'

import { TextField, TextArea, CheckBox, Tabs,ImageUpload } from '../ui/core';
import Service from '../../services/checklistcase';
import ServiceFile from '../../services/file';
import Rights from './rights';
import { Context } from '../../AppContext';
import {ContextUI} from '../../uiContext';

export default function Item({ item, onSave }) {
    const context = useContext(Context);
    const contextUI =  useContext(ContextUI)
    const [selectedTab, setSelectedTab] = useState(0)
    const [editItem, setEditItem] = useState(item);
    const [canWrite, setCanWrite] = useState(false);

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    const handleChangeAndSave= (attribute,value)=>{
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }
 
    useEffect(() => {
        setCanWrite(Service.hasRight(editItem, context.user, 100));
    }, [editItem, context.user])

    useEffect(() => {
        setEditItem(item);
    }, [item])

    let uploadFile = (evt) => {
        if(document.getElementById('uploadimage').files[0]){
            let nameSplit = document.getElementById('uploadimage').files[0].name.split('.');
            let ext = nameSplit.pop();
            if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
               ServiceFile.fileToBase64( document.getElementById('uploadimage').files[0],350)
               .then(base64=>{
                    handleChangeAndSave('image',base64);
               })
               .catch(ex=>{
                 contextUI.setMessage('Uploaden van de afbeelding is mislukt.');
               })
               
            }else{
                contextUI.setMessage('Dit bestand ('+document.getElementById('uploadimage').files[0].name+') kan niet worden toegestaan, geen geldige afbeelding')
            }
            console.log(document.getElementById('uploadimage').files)
        }
      
    }

    return (
        <>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
            <div className="ui-tab"  >ALGEMEEN</div>
            <div className="ui-tab">BETROKKENEN</div>
        </Tabs>
          {selectedTab===0 &&   <div className="padding">
                {editItem && <>
                    <TextField readOnly={!canWrite} id="title" label="Titel" value={editItem.title} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextArea readonly={!canWrite} id="description" label="Omschrijving" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea>
                    {canWrite &&  <ImageUpload id="imageupload" label="Afbeelding" value={editItem.image} onChange={(ev)=>{ handleChangeAndSave('image',ev.target.value);}} resize={350}/>}
                    <br/><br/>
                    <div className="label">Archiveren</div>
                    <CheckBox id="archive" label="In archief plaatsen" checked={item.archived || false} onChange={(ev)=>{handleChangeAndSave('archived',ev.target.checked)}}/>
                </>}
            </div>}
            {selectedTab===1 && <div className="padding">
                <Rights checklistcase={item} onSave={onSave}/>
                </div>}
        </>
    )
}
