import React, { useContext } from 'react'
import { ContextUI } from '../../uiContext';
import './fab.css';
//variant:  contained outlined text
const Fab = (props) => {
    const contextUI = useContext(ContextUI);
    const style = {};
    style.backgroundColor = contextUI.primary.color;
    style.color = contextUI.primary.contrast;
   

    //variant
    switch (props.variant) {
        case 'contained':

            break;
        case 'outlined':
            style.backgroundColor = contextUI.primary.contrast;
            style.color = contextUI.primary.color;
            style.border = '1px solid ' + contextUI.primary.color;
            style.boxShadow = 'none'
            break;
        case 'text':
            style.backgroundColor = 'white';
            style.color = contextUI.primary.color;
            style.boxShadow = 'none'
            style.border = 'none'
            break;
        default:
            break;

    }

    if(props.float){
        style.position='fixed';
        style.bottom = '16px';
        style.right = '16px'
    }

    return (
        <div style={{ ...style, ...props.style }}
            onClick={props.onClick}
            className='ui-fab'
        >
            <span className='ui-fab-label'>{props.children}</span>
            
        </div>
    )
}
export { Fab };
