import React  from 'react' 

export default function List({ items, onClick }) {
   
 
    return (

<div >
 
{items.map(item => {
    return <div key={item._id} onClick={() => { onClick(item) }} className="card">
        <div className="card-image" style={{backgroundImage:'url('+item.image+')'}}></div>
        <div className="card-content">
            <h2>{item.title}</h2>
            <i>{item.description}</i>
        </div>
    </div>
})}
</div>
    )
}
