import React, { useState, useEffect } from 'react'

export default function Radar({ centerX = 350, centerY = 420, radius = 300, data, dataKey,stroke,fill,fillOpacity }) {
 //   const [lines, setLines] = useState([]); 

    const printPolygon = (r) => {
        let tmpObjects = [];
        let tmpLines = [];
        let tmpPoints = [];
        for (let i = 0; i < data.length; i++) {
            const angle = (i) * (Math.PI * 2) / data.length;
            
            const dist = r / data[i].max * data[i][dataKey];
            var x1 = centerX + Math.cos(angle) * (dist);
            var y1 = centerY + Math.sin(angle) * (dist);
            var x2 = centerX + Math.cos(angle)
            var y2 = centerY + Math.sin(angle)

            tmpPoints.push({ x: x1, y: y1 })
            //data[i].color
           // <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
  
          
            tmpLines.push(<circle onMouseOver={()=>{document.getElementById(dpLabelID).style.display='block'}} onMouseOut={()=>{document.getElementById(dpLabelID).style.display='none'}}  key={i+x1+x2+y1+y2} cx={x1} cy={y1} r={5}  strokeWidth={2} stroke= {data[i].color} fill={ data[i].color}/>)

            //hover label
            let dpLabelID = "dpl"+i+x1+x2+y1+y2;
          //  tmpLines.push(<circle onMouseOver={()=>{document.getElementById(dpLabelID).style.display='block'}} onMouseOut={()=>{document.getElementById(dpLabelID).style.display='none'}}  key={'hoverplane'+i+x1+x2+y1+y2} cx={x1} cy={y1} r={10} style={{ cursor:'pointer'}}  strokeWidth={2} stroke= {data[i].color} fill={ 'transparent'} />)
            tmpLines.push(<text id={dpLabelID} style={{display:'none'}} className="charting-datapointlabel" key={"dpl"+i+x1+x2+y1+y2} x={centerX} y={centerY -10} fill={"black"}>{data[i][dataKey]} %</text>)
        }
        let points = tmpPoints.map(point => { return point.x + ',' + point.y }).join(',')
       // <animate attributeName="points" dur="5-0ms" to={'0,0'}/>
       tmpObjects.push(<polygon key={'radar'} points={points} style={{ fill: fill, stroke:stroke, strokeWidth: 1, opacity: fillOpacity }} ></polygon>)
       tmpObjects.push(...tmpLines);
       
        return tmpObjects
    }

   // useEffect(() => {
   //     let tmpLines = [] 
   //     tmpLines.push(printPolygon(radius));
   //     setLines(tmpLines)
   // }, [data]) 
    return (<>
        {printPolygon(radius)}
    </>)
}
