import React, { useContext } from 'react'
import { ContextUI } from '../../uiContext';

const Select = function Select(props) {
    const contextUI = useContext(ContextUI);
    return (
        <>
        {props.label && <div className='selectFieldLabel'>{props.label}</div>}
        <div  className="selectField">
            <select id={props.id || ''} onChange={props.onChange} onBlur={props.onBlur && props.onBlur} value={props.value||''}>
                {props.children}
            </select>
        </div>
        {props.error && <div className='selectFieldError'>{props.error}</div>}
        {props.helper && <div className='selectFieldHelper'>{props.helper}</div>}
        </>
    )
}

export { Select };
