import React, { useEffect, useState } from 'react'
import './index.css';
import { v4 as uuidv4 } from 'uuid';
import BlockAdd from './block-add';
import BlockEdit from './block-edit.js';
export default function Index({ blocks, onChange,UploadComponent }) {
    const [selectedBlock, setSelectedBlock] = useState('');

    const handleAddBlock = (options) => {
        let tmpBlocks = Object.assign([], blocks);
        let maxSort = 0;
        for (let i = 0; i < tmpBlocks.length; i++) {
            tmpBlocks.selected = false;
            if (tmpBlocks[i].sort > maxSort) maxSort = tmpBlocks[i].sort;
        }
        const newBlock = options;
        newBlock['_id'] = uuidv4();
        newBlock.sort = maxSort + 1;
        newBlock.active = true; 
        tmpBlocks.push(newBlock);
        onChange(tmpBlocks, true);
    }

    const handleChangeBlock = (block, save) => {


        if (block) {
            let tmpBlocks = Object.assign([], blocks);
            let found = false;
            for (let i = 0; i < tmpBlocks.length; i++) {
                if (tmpBlocks[i]._id === block._id) {
                    tmpBlocks[i] = block;
                    found = true;
                }
            }
            if (!found) tmpBlocks.push(block)
       
            onChange(tmpBlocks, save);
        } else {
            onChange(null, save);
        }


    }



    if (!blocks) return null;
    return (
        <div className="fade-in">
            {blocks.map(block => {
                return <div key={block._id} onClick={() => { setSelectedBlock(block._id) }}><BlockEdit block={block} onChange={handleChangeBlock} selected={selectedBlock === block._id} UploadComponent={UploadComponent}/></div>
            })}
            <br />  <br />
            <BlockAdd onSelect={handleAddBlock} />
        </div>
    )
}
