import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const Service = {
    simpleHash: (text) => {
        var bytes = [];
        for (var i = 0; i < text.length; i++) {
            bytes.push(text.charCodeAt(i));
        }
        return bytes.join('');
    },
    encryptWithAES: (text, passphrase) => {
        try{
            return AES.encrypt(text, passphrase).toString();
        }catch(ex){
            return "";
        }
        
    },
    decryptWithAES: (ciphertext, passphrase) => {
        try{
            const bytes = AES.decrypt(ciphertext, passphrase);
            const originalText = bytes.toString(Utf8);
            return originalText;
        }catch(ex){
            return "";
        }
    
    },
    roundNumber: (num) => {
        return Math.round(num * 100 + Number.EPSILON) / 100
    },
    sortJSON: (data, key, way) => {
        return data.sort((a, b) => {
            var x = a[key]; var y = b[key];
            if (way === '123') { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }
            if (way === '321') { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }
            return 0;
        });
    },
    toJSONLocal: (date) => {
        var local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    },
    formatDate: function (d, showTime) {
        var givendate = new Date(d);
        var today = new Date();
        var compairedate = new Date(d);
        if (compairedate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
            if (showTime === undefined || showTime === true) {
                return "vandaag " + ("0" + givendate.getHours()).slice(-2) + ":" + ("0" + givendate.getMinutes()).slice(-2);
            } else {
                return "vandaag";
            }
        } else {
            var month = '';
            switch (givendate.getMonth()) {
                case 0:
                    month = 'jan.';
                    break;
                case 1:
                    month = 'febr.';
                    break;
                case 2:
                    month = 'mrt.';
                    break;
                case 3:
                    month = 'apr.';
                    break;
                case 4:
                    month = 'mei';
                    break;
                case 5:
                    month = 'jun.';
                    break;
                case 6:
                    month = 'jul.';
                    break;
                case 7:
                    month = 'aug.';
                    break;
                case 8:
                    month = 'sept.';
                    break;
                case 9:
                    month = 'okt.';
                    break;
                case 10:
                    month = 'nov.';
                    break;
                case 11:
                    month = 'dec.';
                    break;
                default:
                    month = '';
                    break;
            }
            if (isNaN(givendate.getDate())) {
                return '';
            } else {
                if (showTime === undefined || showTime === true) {
                    return ("0" + givendate.getDate()).slice(-2) + " " + month + " " + givendate.getFullYear() + " " + ("0" + givendate.getHours()).slice(-2) + ":" + ("0" + givendate.getMinutes()).slice(-2);
                } else {
                    return ("0" + givendate.getDate()).slice(-2) + " " + month + " " + givendate.getFullYear();
                }
            }
        }
    },
    postForm: (path, params, method) => {
        method = method || 'post';
        var form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    },


}
export default Service;

