import React,{useState} from 'react'
import ViewerBlock from '../block-builder/viewer';
import ViewerQuiz from '../quiz-builder/viewer';
export default function ItemContent({step,personalStep,onChange}) {

    const handleChangeQuiz = (quiz)=>{
        let tmpStep = {};
        if(personalStep){
            tmpStep = Object.assign({},personalStep);
        }
        tmpStep.elearning=step.elearning;
        tmpStep.elearningStep = step._id;
        tmpStep.active=true;
        tmpStep.progress = quiz.progress;
        tmpStep.failed = quiz.failed || false;
        tmpStep.quiz = quiz;
        onChange(tmpStep)
    }

    const handleProgress = (progress)=>{ 
        let tmpProgress = -1;
        let tmpStep = {};
        if(personalStep){ 
            tmpStep = Object.assign({},personalStep);
            tmpProgress = tmpStep.progress;
            if(tmpStep.progress<progress){
                tmpStep.progress = progress;
            }

        }else{
            tmpStep.progress = progress; 
        }
        tmpStep.elearning=step.elearning;
        tmpStep.elearningStep = step._id;
        tmpStep.active=true;
      
        if(tmpProgress !== progress){ 
            onChange(tmpStep)
        }
       
    }

    if(!step){
        return null
    }

    return (
        <div>
            <h1 className="padding">{step.title}</h1>
            {step.type===0 && <ViewerBlock blocks={step.blocks}   onProgress={handleProgress}/>}
            {step.type===1 && <ViewerQuiz quiz={step.quiz} answers={personalStep? personalStep.quiz :[]} onChange={handleChangeQuiz} />}
        </div>
    )
}
