import React, { useContext, useState, useEffect } from 'react'
import { ContextUI } from '../../uiContext';

import { v4 as uuidv4 } from 'uuid';
const TextArea = function TextArea(props) {
    const ref = React.useRef();
    const contextUI = useContext(ContextUI);
    const [style, setStyle] = useState(props.style || {})

    const auto_grow = () => {

        if (ref.current) {   
         
           //// if(ref.current.scrollHeight < 46 ) {
           //     ref.current.style.height = "29px";
           // }   else{
               ref.current.style.height = ""+(ref.current.scrollHeight || 29) + "px";
           // }  
            
        }
    }

    useEffect(() => {
        if(ref.current){
            if(ref.current.style.height===''){
                ref.current.style.height = "29px"
            }
        }
        auto_grow() 
    }, [ref.current])

    if (!props.id) {
        console.warn('No ID set for textfield' + props.label)
    }

    return (
        < >
            {props.label && <div className='textFielLabel'>{props.label}</div>}
            <textarea
                id={props.id}
                placeholder = {props.placeholder || ''}
                ref={ref}
                style={style}
                value={props.value || ''}
                onChange={(ev)=>{auto_grow();props.onChange(ev)}}
                type={props.type || 'text'}
                className='textField'
                readOnly={props.readOnly || false}
                onFocus={(ev) => {auto_grow() ;setStyle({ ...props.style, borderBottom: '1px solid ' + contextUI.primary.color });if(props.onFocus){props.onFocus(ev)}}}
                onBlur={(ev) => {setStyle(props.style);if(props.onBlur){props.onBlur(ev)}}}
            ></textarea>
            {props.error && <div className='textFieldError'>{props.error}</div>}
            {props.helper && <div className='textFieldHelper'>{props.helper}</div>}
        </>
    )
}

export { TextArea };
