import React, { useEffect, useState , useRef} from 'react'
import { FontDownload } from '../../ui/coreIcons';
import { IconButton } from '../../ui/iconButton';
export default function RadarIndex({id, width, height, data, style, settings, children,hideDownload }) {
  const [childs, setChilds] = useState();
  const ref = useRef();
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = height / 3;

  const copyStylesInline = (destinationNode, sourceNode) =>{
    var containerElements = ["svg", "g"];
    for (var cd = 0; cd < destinationNode.childNodes.length; cd++) {
      var child = destinationNode.childNodes[cd];
      if (containerElements.indexOf(child.tagName) != -1) {
        copyStylesInline(child, sourceNode.childNodes[cd]);
        continue;
      }
      var style = sourceNode.childNodes[cd].currentStyle || window.getComputedStyle(sourceNode.childNodes[cd]);
      if (style == "undefined" || style == null) continue;
      for (var st = 0; st < style.length; st++) {
        child.style.setProperty(style[st], style.getPropertyValue(style[st]));
      }
    }
  }

  const triggerDownload = (imgURI, fileName)=> {
    var evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true
    });
    var a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", imgURI);
    a.setAttribute("target", '_blank');
    a.dispatchEvent(evt);
  }

  const downloadSvg = (svg, fileName)=> {
   
    var copy = svg.cloneNode(true);
    copyStylesInline(copy, svg);
    var canvas = document.createElement("canvas");
    var bbox = svg.getBBox();
    
    canvas.width = width;//bbox.width;
    canvas.height =height;// bbox.height;
    var ctx = canvas.getContext("2d");
   
    ctx.clearRect(0, 0, width,height);//bbox.width, bbox.height);
    ctx.fillStyle="rgb(0,0,255)"
    var data = (new XMLSerializer()).serializeToString(copy);
    var DOMURL = window.URL || window.webkitURL || window;
    var img = new Image();
    var svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
    var url = DOMURL.createObjectURL(svgBlob);
    img.onload = function () {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);
      if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
        var blob = canvas.msToBlob();
        navigator.msSaveOrOpenBlob(blob, fileName);
      }
      else {
        var imgURI = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        triggerDownload(imgURI, fileName);
      }
      try{
        document.removeChild(canvas);
      }catch(ex){}
     
    };
    img.src = url;
  }


  useEffect(() => {
    var newChildren = React.Children.map(children, function (child) {
      return React.cloneElement(child, { data: data, centerX: centerX, centerY: centerY, radius: radius })
    });
    setChilds(newChildren);
  }, [data])

  return (
    <>
   {hideDownload!==true && <IconButton onClick={()=>{downloadSvg(ref.current,'grafiek.png')}}><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" height="24" width="24"><path d="M24 32.35 14.35 22.7 16.5 20.55 22.5 26.55V8H25.5V26.55L31.5 20.55L33.65 22.7ZM11 40Q9.8 40 8.9 39.1Q8 38.2 8 37V29.85H11V37Q11 37 11 37Q11 37 11 37H37Q37 37 37 37Q37 37 37 37V29.85H40V37Q40 38.2 39.1 39.1Q38.2 40 37 40Z" fill='gray'/></svg></IconButton>}
    <svg id={id} ref={ref} width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{...style,backgroudColor:'white'}}>
    <g id="group_1">
      <text x="10" y="20" className="charting-title">{settings.title}</text>
      {childs}
      </g>
    </svg>
    </>
  )
}
