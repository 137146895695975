import React, { useState, useEffect } from 'react'
import { Button, TextArea, TextField } from '../../ui/core';
import VeditorSimple from "../../veditor-simple/veditor";
import useWindowSize from '../hooks/windowsize';
export default function Paragraph({ block, onChange, onSave, UploadComponent }) {
    const windowsize = useWindowSize();
    const [contentRefPar] = useState(React.createRef());
    const [contentRefTitle] = useState(React.createRef());
    const [contentRefSubTitle] = useState(React.createRef());
    const [showUpload, setShowUpload] = useState(false);

    /* block.imageStyle
       <option value={'full'}>Volle breedte</option>
       <option value={'textleft'}>Tekst links naast de afbeelding</option>
       <option value={'textonimage'}>Tekst op de afbeelding</option>
    */

    const handleBlur = () => {
        let tmpBlock = Object.assign({}, block);
        onChange(tmpBlock, true);
    };

    const handleChange = (attribute, value) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock[attribute] = value;
        onChange(tmpBlock);
    };

    const handleSaveImage = (url) => {
        let tmpBlock = Object.assign({}, block);
        tmpBlock.video = url;
        onChange(tmpBlock);
        setShowUpload(false);
    }

    let styleVideo = {width: '100%'}
      
    if(windowsize.innerHeight < windowsize.innerWidth){
        styleVideo = {height: (windowsize.innerHeight - 64)+'px'};
    }

    if (!block) return null;
    return (
        <div className="w3-center">
            {showUpload && <UploadComponent onSave={handleSaveImage} onClose={() => { setShowUpload(false) }} />}
            {block.videoStyle && block.videoStyle.indexOf('par') >= 0 && <VeditorSimple id={block._id} value={block.paragraph !== undefined ? block.paragraph : 'Vul hier uw tekst in'} contentRef={contentRefPar} onChange={() => handleChange('paragraph', contentRefPar.current.innerHTML)} onBlur={handleBlur} />}
            {block.video &&  <video style={styleVideo} controls={true}>
                <source src={block.video} type="video/mp4"/> 
            </video>}
            {!block.video && <Button onClick={() => { setShowUpload(true) }}>Upload video</Button>}
        </div>
    )

}
