import React,{useState,useEffect} from 'react'
import { Paper, CheckBox } from '../ui/core';
import ServiceElearning from '../../services/elearning'
export default function Tools({ item, onSave }) {
    const [elearnings,setElearnings] = useState([]);

useEffect(() => {
ServiceElearning.get()
.then(result=>{setElearnings(result)})
.catch(ex=>{console.log(ex)})
}, [])

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.tools) tmpItem.tools = [];
        if (ev.target.checked) {
            tmpItem.tools.push(ev.target.id)
        } else {
            tmpItem.tools.splice(tmpItem.tools.indexOf(ev.target.id), 1);
        }
        onSave(tmpItem);
    }

    const handleChangeElearning = (ev) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.elearnings) tmpItem.elearnings = [];
        if (ev.target.checked) {
            tmpItem.elearnings.push(ev.target.id)
        } else {
            tmpItem.elearnings.splice(tmpItem.elearnings.indexOf(ev.target.id), 1);
        }
        console.log(tmpItem)
        onSave(tmpItem);
    }

    if (!item) return null;
    return (
        <div className="padding">
            <Paper className="padding">
                Beschikbare tools binnen de organisatie<br /><br />
                <CheckBox id="checklist" label="Bevoegdheden applicatie" checked={item.tools && item.tools.indexOf("checklist") >= 0} onChange={handleChange}></CheckBox>
                <CheckBox id="evaluation" label="Evaluatie app" checked={item.tools && item.tools.indexOf("evaluation") >= 0} onChange={handleChange}></CheckBox>
                <CheckBox id="elearning" label="E-learning" checked={item.tools && item.tools.indexOf("elearning") >= 0} onChange={handleChange}></CheckBox>
                <div style={{paddingLeft:'28px'}}>
                    {elearnings.map(elearning=>{
                        return <CheckBox key={elearning._id} id={elearning._id} label={elearning.title} checked={item.elearnings && item.elearnings.indexOf(elearning._id) >= 0} onChange={handleChangeElearning}></CheckBox>
                    })}
                </div>
            </Paper>
        </div>
    )
}
