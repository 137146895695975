import axios from 'axios';
import UserService from "./user";
import Settings from "../AppSettings";
import useWindowSize from '../components/ui/hooks/windowSize';
const locationAPI = Settings.getLocationAPI();
const locationWordDocumentAPI = Settings.getLocationWordDocumentAPI();

const checkLogout = (statusCode) => {
    if (statusCode === 401) {
        if (window.location.href.indexOf("/#/vragenlijst/") == -1) {
            window.localStorage.clear();
            window.location.href = "#";
        }
    }
}

const get = (attribute, headers) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};
        prepHeaders['platform'] = Settings.getPlatform();

        if (UserService.getToken() !== '0') {
            prepHeaders['authorization'] = 'Bearer ' + UserService.getToken();
        }

        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }
        axios.get(locationAPI + attribute, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                try {
                    checkLogout(err.response.status);
                } catch (ex) { }

                reject(err);
            })
    })
}

const post = (attribute, headers, data) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};


        data.uuid = Settings.getUUID();
        data.device = JSON.stringify(Settings.getDevice());
        if (localStorage.getItem('fcm')) {
            data.fcmtoken = localStorage.getItem('fcm');
        }
        prepHeaders['platform'] = Settings.getPlatform();

        if (UserService.getToken() !== '0') {
            prepHeaders['authorization'] = 'Bearer ' + UserService.getToken();
        }
        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/json; charset=utf-8';

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }
        axios.defaults.withCredentials = false;

        axios.post(locationAPI + attribute, data, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                try {
                    checkLogout(err.response.status);
                } catch (ex) { }
                reject(err);
            })

    })
}

const put = (attribute, headers, data) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        data.uuid = Settings.getUUID();
        data.device = JSON.stringify(Settings.getDevice());
        if (localStorage.getItem('fcm')) {
            data.fcmtoken = localStorage.getItem('fcm');
        }
        prepHeaders['platform'] = Settings.getPlatform();
        if (UserService.getToken() !== '0') {
            prepHeaders['authorization'] = 'Bearer ' + UserService.getToken();
        }
        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/json; charset=utf-8';

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }

        axios.put(locationAPI + attribute, data, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                try {
                    checkLogout(err.response.status);
                } catch (ex) { }
                reject(err);
            })
    })
}

const deleteItem = (attribute, headers) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        prepHeaders['platform'] = Settings.getPlatform();
        if (UserService.getToken() !== '0') {
            prepHeaders['authorization'] = 'Bearer ' + UserService.getToken();
        }
        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/json; charset=utf-8';

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }

        axios.delete(locationAPI + attribute, { headers: prepHeaders })
            .then(res => {
                resolve(res.data);
            }, err => {
                try {
                    checkLogout(err.response.status);
                } catch (ex) { }
                reject(err);
            })
    })
}

const postWordDocument = (attribute, headers, data) => {
    return new Promise((resolve, reject) => {
        let prepHeaders = {};

        data.uuid = Settings.getUUID();
        data.device = JSON.stringify(Settings.getDevice());
        if (localStorage.getItem('fcm')) {
            data.fcmtoken = localStorage.getItem('fcm');
        }
        prepHeaders['platform'] = Settings.getPlatform();

        if (UserService.getToken() !== '0') {
            prepHeaders['authorization'] = 'Bearer ' + UserService.getToken();
        }
        prepHeaders['Content-Type'] = 'application/json; charset=utf-8';
        prepHeaders['Accept'] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        if (headers) {
            Object.keys(headers).forEach((key) => {
                prepHeaders[key] = headers[key];
            })
        }
        axios.defaults.withCredentials = false;
        axios.post(locationWordDocumentAPI + attribute, data, { responseType: 'blob', headers: prepHeaders })
            .then(res => {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.filename);
                document.body.appendChild(link);
                link.click();

                resolve();
            }, err => {
                checkLogout(err.response.status);
                reject(err);
            })
    })
}

export default { get, post, put, deleteItem, postWordDocument };
