import React, { useState, useContext, useEffect } from 'react'
import './login.css';
import LoginLogo from './login-logo.png';
import { Paper, Typography, TextField, Button, TextArea } from '../../ui/core';
import { Context } from '../../../AppContext';
import { ContextUI } from '../../../uiContext';
import UserService from '../../../services/user';
import NewPassword from './newpassword';
export default function Login() {
    const context = useContext(Context)
    const contextUI = useContext(ContextUI);

    const [selectedScreen, setSelectedScreen] = useState('login');

    const [email, setEmail] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const [signUpSend, setSignUpSend] = useState(false);

    //for signup
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [funct, setFunct] = useState('');
    const [phone, setPhone] = useState('');
    const [remark, setRemark] = useState('');


    const [password, setPassword] = useState('');

    useEffect(() => {
        UserService.currentuser()
            .then(user => {
                context.setUser(user)
            })
            .catch(err => { })
    }, [])

    const login = () => {
        UserService.authenticate(email, password)
            .then(result => {
                UserService.setToken(result);
                UserService.currentuser()
                    .then(user => {
                        context.setUser(user);

                    })
                    .catch(err => {

                    })
            })
            .catch(err => {
                contextUI.setMessage('E-mailadres en/of wachtwoord onjuist')
            })
        //context.setUser({email:email})
    }

    const sendSignUp = () => {
        UserService.signup({
            email: email,
            lastname: lastName,
            firstname: firstName,
            organisation: organisation,
            funct: funct,
            phone: phone,
            remark: remark
        })
            .then(result => {
                contextUI.setMessage('Bedankt voor uw aanmelding, we zullen deze z.s.m. behandelen');
                setSignUpSend(true);
                setShowSignUp(false)
            })
            .catch(err => {
                contextUI.setMessage('Uw aanmelding kon niet worden verstuurd, probeer het later nogmaals. Mocht u reeds in het systeem staan dan kunt u een nieuw wachtwoord aanmaken.')
            })
    }

    return (
        <div className="login-body">
            <Paper className="login-paper" >
                <img src={LoginLogo} />
                <div className="login-paper-body">
                    {selectedScreen === 'newpassword' && <NewPassword onClose={()=>setSelectedScreen('login')}/>}

                    {selectedScreen === 'login' && <>
                        {!showSignUp && <Typography component={'h2'} style={{ textAlign: 'center' }}>Inloggen online omgeving</Typography>}
                        {showSignUp && <Typography component={'h2'} style={{ textAlign: 'center' }}>Aanmelden nieuwe gebruiker</Typography>}
                        {showSignUp && <div style={{ textAlign: 'center', fontStyle: 'italic' }}>Vul hieronder uw gegevens in, na het versturen zullen wij z.s.m. uw aanmelding behandelen.</div>}
                        <TextField id="email" required={true} label={'E-mailadres'} value={email} onChange={(ev) => { setEmail(ev.target.value) }} ></TextField>
                        {!showSignUp && <TextField id="password" required={true} label={'Wachtwoord'} value={password} onChange={(ev) => { setPassword(ev.target.value) }} type="password"></TextField>}
                        {showSignUp && <>
                            <TextField id="text" label={'Voornaam'} value={firstName} onChange={(ev) => { setFirstName(ev.target.value) }} ></TextField>
                            <TextField id="text" required={true} label={'Achternaam'} value={lastName} onChange={(ev) => { setLastName(ev.target.value) }} ></TextField>
                            <br /><br />
                            <TextField id="text" required={true} label={'Organisatie'} value={organisation} onChange={(ev) => { setOrganisation(ev.target.value) }} ></TextField>
                            <TextField id="text" label={'Functie'} value={funct} onChange={(ev) => { setFunct(ev.target.value) }} ></TextField>
                            <br /><br />
                            <TextField id="text" label={'Telefoon'} value={phone} onChange={(ev) => { setPhone(ev.target.value) }} ></TextField>

                            <TextArea id="text" label={'Opmerkingen'} value={remark} onChange={(ev) => { setRemark(ev.target.value) }} ></TextArea>

                        </>}
                        <br />
                        <div className="label" style={{ float: 'right' }}>* verplicht veld</div>
                        <br />
                        {!showSignUp && <Button style={{ width: '100%' }} onClick={login}>Inloggen</Button>}
                        {!showSignUp && <Button variant={'outlined'} style={{ width: '100%' }} onClick={() => { setSignUpSend(false); setShowSignUp(true) }}>Aanmelden</Button>}
                        {!showSignUp && <Button variant={'text'} style={{ width: '100%' }} onClick={() => { setSelectedScreen('newpassword') }}>(Nieuw) wachtwoord aanmaken</Button>}

                        {showSignUp && <Button variant={'contained'} style={{ width: '100%' }} onClick={() => { sendSignUp() }}>Aanmelding versturen</Button>}
                        {showSignUp && <Button variant={'outlined'} style={{ width: '100%' }} onClick={() => { setShowSignUp(false) }}>Annuleren</Button>}

                    </>}

                    <div style={{ display: 'flex', borderTop: '2px solid #ff8036', marginTop: '20px' }}>
                        <Button variant={'text'} style={{ width: '100%', fontSize: 'smaller' }} onClick={() => { window.open('./Privacy_statement.pdf', '_blank') }}>Privacyverklaring</Button>
                        <Button variant={'text'} style={{ width: '100%', fontSize: 'smaller' }} onClick={() => { window.open('./Klachtenregeling.pdf', '_blank') }}>Klachtenregeling</Button>
                    </div>
                    <div className="label" style={{float:'right'}}>v.1.31.0</div>
                </div>
            </Paper>

        </div>
    )
}
