import React, { useState, useEffect, useContext } from 'react';
import { Button, IconButton } from '../ui/core';
import { Delete } from '../ui/coreIcons';
import { CheckBox } from '../ui/checkBox'
import { DatePicker } from '../ui/datepicker'
import { Dialog } from '../ui/dialog'
import { DialogActions } from '../ui/dialogActions'
import { DialogContent } from '../ui/dialogContent'
import { DialogTitle } from '../ui/dialogTitle'
import { Select } from '../ui/select'
import { Tabs } from '../ui/tabs';
import { TextArea } from '../ui/textArea';
import { TextField } from '../ui/textField';
import { Typography } from '../ui/typography';
import { v4 as uuidv4 } from 'uuid';
import ServiceGeneral from '../../services/general';
import ServiceSurveys from '../../services/survey';
import { ContextUI } from '../../uiContext';

export default function MonitorEdit({ evaluationMonitor, processes, evaluations, onClose, onSave }) {
    const [monitor, setMonitor] = useState();
    const [selectedTab, setSelectedTab] = useState(0);
    const [surveys, setSurveys] = useState([]);
    const contextUI = useContext(ContextUI);
    const handleSave = () => {
        if (monitor.evaluations.length > 0) {
            onSave(monitor);
        } else {
            contextUI.setMessage('Selecteer minimaal 1 evaluatie');
            setSelectedTab(2);
        }
    }

    const handleChange = (attribute, value) => {
        let tmpItem = Object.assign({}, monitor);
        tmpItem[attribute] = value;
        if (tmpItem.useTimeSlot !== true) {
            tmpItem.from = null;
            tmpItem.until = null;
        }
        setMonitor(tmpItem);
    }

    useEffect(() => {
        //ophalen surveys
        if (monitor && monitor.evaluations) {
 
            let promises = [];
            for (let e = 0; e < monitor.evaluations.length; e++) {
                promises.push(ServiceSurveys.getForEvaluation(monitor.evaluations[e]._id));
            }
            Promise.all(promises)
                .then(result => {
                    let tmpSurveys = []; 
                    result.forEach(item => {
                        if (item.length > 0) {
                            tmpSurveys = tmpSurveys.concat(item);
                        }

                    }) 
                    setSurveys(tmpSurveys);
                })
                .catch(err => {
                    console.log('error', err)
                })

        }
    }, [monitor])

    useEffect(() => {
        let tmpMonitor = Object.assign({}, evaluationMonitor);
        if (!tmpMonitor.mainProcesses) { tmpMonitor.mainProcesses = [] }
        if (!tmpMonitor.subProcesses) { tmpMonitor.subProcesses = [] }
        if (!tmpMonitor.chartType) { tmpMonitor.chartType = "2" }
        if (!tmpMonitor.evaluations) { tmpMonitor.evaluations = [] }
        if (!tmpMonitor.surveys) { tmpMonitor.surveys = [] }
        if (!tmpMonitor.colorRange) {
            tmpMonitor.colorRange = [
                {
                    "_id": "f4f37747-0a4e-4f96-a111-bfeedee5cc55",
                    "from": "0",
                    "to": "25",
                    "color": "#C62828",
                    "active": true,
                    "text": "Onvoldoende, verbeterpunten urgent"
                },
                {
                    "_id": "ce566820-d614-43d3-b702-3526f9f1bb7c",
                    "from": "25",
                    "to": "50",
                    "color": "#ff9900",
                    "active": true,
                    "text": "Voor verbetering vatbaar, verbeterpunten nodig"
                },
                {
                    "_id": "d14b4276-982d-48a5-8f88-8d4dc31558df",
                    "from": "50",
                    "to": "75",
                    "color": "#fdd835",
                    "active": true,
                    "text": "Basis op orde, verbeterpunten mogelijk"
                },
                {
                    "_id": "209cf2fb-352e-40e9-ba2b-2cae415d9ef6",
                    "from": "75",
                    "to": "100",
                    "color": "#4caf50",
                    "active": true,
                    "text": "Op niveau, ging goed"
                }
            ]
        }
        setMonitor(tmpMonitor);
    }, [evaluationMonitor])

    const handleChangeProcess = (attribute, process) => {
        let tmpItem = Object.assign({}, monitor);
        let found = tmpItem[attribute].find(pro => { return pro.code === process.code })
        if (!found) {
            tmpItem[attribute].push(process)
        } else {
            tmpItem[attribute] = tmpItem[attribute].filter(pro => { return pro.code !== process.code })
        }
        setMonitor(tmpItem);
    }

    const handleChangeEvaluation = (attribute, evaluation) => {
        let tmpItem = Object.assign({}, monitor);
        let found = tmpItem[attribute].find(ev => { return evaluation._id === ev._id })
        if (!found) {
            tmpItem[attribute].push({_id:evaluation._id,title:evaluation.title,date:evaluation.date})
        } else {
            tmpItem[attribute] = tmpItem[attribute].filter(ev => { return evaluation._id !== ev._id })
        }
        setMonitor(tmpItem);
    }

    const handleChangeSurvey = (attribute, survey) => {
        let tmpItem = Object.assign({}, monitor);
        let found = tmpItem[attribute].find(ev => { return survey._id === ev._id })
        if (!found) {
            tmpItem[attribute].push({_id:survey._id,title:survey.title,evaluation:survey.evaluation,date:survey.date})
        } else {
            tmpItem[attribute] = tmpItem[attribute].filter(ev => { return survey._id !== ev._id })
        }
        setMonitor(tmpItem);
    }

    const addColorRange = (process) => {
        let tmpItem = Object.assign({}, monitor);

        tmpItem.colorRange.push({ _id: uuidv4(), from: 0, to: 100, color: '#00698f', active: true });
        setMonitor(tmpItem);
    }

    const handleChangeColorRange = (range, attribute, value) => {
        let tmpItem = Object.assign({}, monitor);
        let found = tmpItem.colorRange.find(r => { return r._id === range._id });
        if (found) {
            found[attribute] = value;
            setMonitor(tmpItem);
        }
    }

    const handleDeleteColorRange = (range) => {
        if (window.confirm('Weet u zeker dat u dit wilt verwijderen?')) {
            let tmpItem = Object.assign({}, monitor);
            tmpItem.colorRange = tmpItem.colorRange.filter(r => { return r._id !== range._id })
            setMonitor(tmpItem);
        }
    }


    if (!monitor) return null;
    return (
        <Dialog fullScreen>
            <DialogTitle><Typography component="h2">{monitor._id ? "Bewerken monitor" : "Nieuwe monitor"}</Typography>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <div className="ui-tab">ALGEMEEN</div>
                    <div className="ui-tab">WEERGAVE</div>
                    <div className="ui-tab">EVALUATIES/VRAGENLIJSTEN</div>
                   { /*<div className="ui-tab">PROCESSEN</div>*/}

                </Tabs>
            </DialogTitle>
            <DialogContent>

                {
                    selectedTab === 0 && <>

                        <TextField label="titel" value={monitor.title} id="title" onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }}></TextField><br /><br />
                        <TextArea label="omschrijving" value={monitor.descr} id="descr" onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }}></TextArea>
                        <br /><br />
                        {'do not'==='use' && <>
                        <div className="label">periode</div>
                        <CheckBox label="gebruik tijdvak" id="useTimeSlot" checked={monitor.useTimeSlot} onChange={(ev) => { handleChange(ev.target.id, ev.target.checked) }}></CheckBox>


{monitor.useTimeSlot === true &&
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ width: '50%' }}> <DatePicker label="van" value={monitor.from || new Date()} onChange={(dt) => { handleChange("from", dt) }}></DatePicker></div>
                                <div style={{ width: '50%' }}> <DatePicker label="tot" value={monitor.until || new Date()} onChange={(dt) => { handleChange("until", dt) }}></DatePicker></div>
                            </div>
                        }
</>}
                      <br />
                    </>
                }
                {
                    selectedTab === 1 && <>
                        <Select label="type weergave" value={monitor.chartType} id="chartType" onChange={(ev) => { handleChange(ev.target.id, ev.target.value) }}>
                            <option value={1}>Spinnediagram</option>
                            <option value={2}>Staafdiagram</option>
                        </Select>
                        <br />

                        <div className="label">kleurweergave scores</div>
                        <table style={{ width: '100%' }}><tbody>
                            <tr><td className="label">van (%)</td><td className="label">tot (%)</td><td className="label">verwoording</td><td className="label">kleurcode</td><td></td></tr>
                            {monitor.colorRange.map(range => {
                                return <tr key={range._id}>
                                    <td><TextField type="number" id={range._id + 'from'} value={range.from || 0} onChange={(ev) => { handleChangeColorRange(range, 'from', ev.target.value) }} /></td>
                                    <td><TextField type="number" id={range._id + 'to'} value={range.to} onChange={(ev) => { handleChangeColorRange(range, 'to', ev.target.value) }} /></td>
                                    <td><TextField id={range._id + 'text'} value={range.text} onChange={(ev) => { handleChangeColorRange(range, 'text', ev.target.value) }} /></td>
                                    <td style={{ backgroundColor: range.color }}><TextField id={range._id + 'color'} value={range.color} onChange={(ev) => { handleChangeColorRange(range, 'color', ev.target.value) }} /></td>
                                    <td valign="top"  ><IconButton onClick={() => { handleDeleteColorRange(range) }}><Delete /></IconButton></td>
                                </tr>
                            })}
                        </tbody></table>
                        <Button fullWidth onClick={addColorRange}>Toevoegen kleur</Button>

                    </>
                }
                {
                    selectedTab === 2 && <>
                        <div className='label'>Selecteer de evaluatie en de gewenste onderliggende vragenlijsten</div>
                        <table style={{ width: '100%' }}><tbody>
                            <tr><th>Evaluatie</th><th>Vragenlijst</th><th>Aanmaak datum</th></tr>
                            {evaluations.map(evaluation => {

                                let result = [];
                                result.push(<tr key={evaluation._id}><td><CheckBox key={'main' + evaluation._id} id={'main' + evaluation._id} label={evaluation.title} checked={monitor.evaluations.find(ev => { return ev._id === evaluation._id }) !== undefined} onClick={(ev) => { handleChangeEvaluation('evaluations', evaluation) }}></CheckBox></td><td></td><td>{ServiceGeneral.formatDate(evaluation.created, false)}</td></tr>);

                                let tmpSurveys = surveys.filter(s => { return s.evaluation === evaluation._id })
                                tmpSurveys.forEach(survey => {
                                    result.push(<tr key={survey._id}><td></td><td><CheckBox key={'main' + survey._id} id={'main' + survey._id} label={survey.title} checked={monitor.surveys.find(ev => { return ev._id === survey._id }) !== undefined} onClick={(ev) => { handleChangeSurvey('surveys', survey) }}></CheckBox></td><td>{ServiceGeneral.formatDate(survey.date, false)}</td></tr>)
                                })
                                return result;
                            })}
                        </tbody></table>

                    </>
                }
                {
                    selectedTab === 3 && <>
                        <table>
                            <tbody>
                                <tr>
                                    <td valign={'top'}>
                                        <div className="label">toon de hoofdprocessen</div><br />
                                        {ServiceGeneral.sortJSON(processes.mainProcesses, 'code', 123).map(process => {
                                            return <CheckBox key={'main' + process.code} id={'main' + process.code} label={process.code + ' : ' + process.title} checked={monitor.mainProcesses.find(pro => { return pro.code === process.code }) !== undefined} onClick={(ev) => { handleChangeProcess('mainProcesses', process) }}></CheckBox>
                                        })}
                                    </td>
                                    <td valign={'top'}>
                                        <div className="label">subprocessen</div><br />
                                        {ServiceGeneral.sortJSON(processes.subProcesses, 'code', 123).map(process => {
                                            return <CheckBox key={'sub' + process.code} id={'sub' + process.code} label={process.code + ' : ' + process.title} checked={monitor.subProcesses.find(pro => { return pro.code === process.code }) !== undefined} onClick={(ev) => { handleChangeProcess('subProcesses', process) }}></CheckBox>
                                        })}

                                    </td>
                                </tr>
                            </tbody>
                        </table>


                    </>
                }





            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Annuleren</Button>
                <Button onClick={handleSave}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
