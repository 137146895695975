import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "questions";


const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getForSurvey: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get('surveys/' + surveyID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getTextLineType: (type) => {
        switch (type) {
            case 0:
                return 'Tekstblok'
            case 1:
                return 'Open vraag, enkele regel'
            case 2:
                return 'Open vraag, meerdere regel'
            case 3:
                return 'Gesloten vraag, 1 antwoord mogelijk'
            case 4:
                return 'Gesloten vraag, meerdere antwoorden mogelijk'
        }
    }
}
export default Service;