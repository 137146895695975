window.veditorUpdateCheckbox = (elem) => {
  if (elem.src.indexOf('outline.svg') > 0) {
    elem.src = 'img/icons/red/check box.svg';
  } else {
    elem.src = 'img/icons/gray/check box outline.svg';
  }
  elem.setAttribute('upd', new Date().getTime());
};

window.getSelectionRange = (oElm) => {
  var $r = { text: "", start: 0, end: 0, length: 0 };
  var $oR;
  if (oElm.setSelectionRange) {	// W3C/Gecko
    $r.start = oElm.selectionStart;
    $r.end = oElm.selectionEnd;
    $r.text = ($r.start != $r.end) ? oElm.value.substring($r.start, $r.end) : "";
  }
  else if (document.selection) {	// IE
    if (oElm.tagName && oElm.tagName === "TEXTAREA") {
      var $oS = document.selection.createRange().duplicate();
      $oR = oElm.createTextRange();
      var $sB = $oS.getBookmark();
      $oR.moveToBookmark($sB);
    }
    else
      $oR = document.selection.createRange().duplicate();
    $r.text = $oR.text;
    for (; $oR.moveStart("character", -1) !== 0; $r.start++);
    $r.end = $r.text.length + $r.start;
  }
  $r.length = $r.text.length;
  return $r;
}


window.veditorUpdateTextbox = (elem) => {
  elem.setAttribute('value', elem.value);
};

window.veditorUpdateTextarea = (elem) => {
  var range = window.getSelectionRange(elem);

  elem.innerText = elem.value;
  elem.innerHTML = elem.value;

  elem.style.height = "5px";
  elem.style.height = elem.scrollHeight + 'px';

  elem.setSelectionRange(range.start, range.end);
};

window.updateTextareaHeights = () => {
  var textareas = document.getElementsByClassName('veditortextarea');
  for (var i = 0; i < textareas.length; i++) {
    textareas[i].style.height = textareas[i].scrollHeight - 4 + 'px';
  }
};

window.veditorUpdateSelect = (elem) => {
  for (var i = 0; i < elem.options.length; i++) {
    elem.options[i].removeAttribute('selected');
    if (elem.selectedIndex === i) {
      elem.options[i].setAttribute('selected', 'selected');
    }
  }
}




