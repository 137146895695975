import React from 'react'

export default function ItemCaseHeader({item,complete}) {
    const style = {backgroundColor:'#ff8036',color:(item.image?'black':'white'),minHeight:'120px',backgroundImage:'url('+item.image+')',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}
   
    return (
        <div style={style}>
            <div style={{padding:'12px',minHeight:'120px',backgroundColor:(item.image?'rgba(255,128,54,0.80)':'none'),color:(item.image?'white':'white')}}>
                 <h1 style={{color:(item.image?'white':'white')}}>{item.title}</h1>
                  {complete === 100 ? `Afgerond`: `${complete}% compleet`}
            </div>
        </div>
    )
}
