import React, { useEffect, useState ,useContext} from 'react'
import ServiceOrganisations from '../../services/organisation';
import ServiceDepartments from '../../services/department';
import ServiceUser from '../../services/user';
import { TextField, TextArea, Button, Paper, Tabs, Select, IconButton, CheckBox } from '../ui/core';
import { Delete } from '../ui/coreIcons';
import { Context } from '../../AppContext';
export default function Access({ item, onChange, onSave ,organisation}) {
    const context = useContext(Context);
    const [organisations, setOrganisations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [useAdminModules,setUseAdminModules] = useState(false)

    useEffect(() => {
        ServiceOrganisations.get()
            .then(organisations => {
                let tmpOrganisation = organisations;
                if(organisation){
                    tmpOrganisation = tmpOrganisation.filter(org=>{return org._id == organisation._id})
                    console.log(tmpOrganisation)
                }
                setOrganisations(tmpOrganisation);
                let departmentPromise = [];
                for (let i = 0; i < tmpOrganisation.length; i++) {
                    departmentPromise.push(ServiceDepartments.getForOrganisation(tmpOrganisation[i]._id))
                }
                Promise.all(departmentPromise)
                    .then(results => {
                        let tmpDepartments = [];
                        for (let r = 0; r < results.length; r++) {
                            tmpDepartments = tmpDepartments.concat(results[r]);
                        }
                        setDepartments(tmpDepartments);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
           
                let fullAccess = ServiceUser.canWrite(context.user.rights,'fullcontrol-users');
                setUseAdminModules(fullAccess);
          
    }, [context.user]);

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        onChange(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = item._id;
        onSave(tmpItem);
    }

    const handleChangeRight = (module, option, checked) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.rights) { tmpItem.rights = [] }

        let foundModule = tmpItem.rights.find(right => { return right.module === module });
        if (foundModule) {
            if (!foundModule.options) { foundModule.options = [] }
            foundModule.options = foundModule.options.filter(opt => { return opt !== option })
            if (checked) {
                foundModule.options.push(option);
            }
        } else {
            tmpItem.rights.push({ module: module, options: [option] })
        }

        onChange(tmpItem);

        let tmpSave = {};
        tmpSave.rights = tmpItem.rights;
        tmpSave._id = item._id;
        onSave(tmpSave);
    }

    const hasRights = (rights, module, option) => {
        if (!rights) return false;
        let foundModule = rights.find(right => { return right.module === module });
        if (!foundModule) return false;
        return foundModule.options.indexOf(option) >= 0;
    }

    const getOrganisationName = (id) => {
        let found = organisations.find(org => { return org._id === id });
        if (found) {
            return found.name;
        } else {
            return '--';
        }
    }

    const headerStyle = { backgroundColor: "rgb(0, 105, 143)", color: "rgb(255, 255, 255)" }
    return (
        <div >
            {item && < div className="padding">
                <Paper className="padding">
                   {!organisation && <>
                    <TextField id="newPassword" type="text" label="Nieuw wachtwoord" value={item.newPassword} onChange={handleChange} onBlur={handleSave}></TextField>
                    <br /><br />
                   </>} 
                    <div className="label">Rechten</div>
                    <table className="ui-table"><tbody>
                        <tr><th style={headerStyle}>Organisatie</th><th style={headerStyle}>Locatie</th><th style={headerStyle}>Module</th><th style={headerStyle}>Beheer</th></tr>
                        {
                            item.organisations && item.organisations.map(org => {
                                let retValue = [];
                                let found = organisations.find(findOrg => { return findOrg._id === org });
                                if(found){
                                    retValue.push(<tr key={org}><td >{getOrganisationName(org)}</td><td ></td><td >Organisatiebeheerder</td><td style={{ width: '20%' }}><CheckBox id={org} checked={hasRights(item.rights, 'organisations', 'w-'+org)} onChange={(ev) => { handleChangeRight('organisations', 'w-'+org, ev.target.checked) }}></CheckBox></td></tr>)
                                    retValue.push(<tr key={org + '_moduleevaluation'}><td > </td><td ></td><td >Aanmaken evaluatie</td><td style={{ width: '20%' }}><CheckBox id={org + '_moduleevaluation'} checked={hasRights(item.rights, 'organisations-evaluation', 'w-'+org)} onChange={(ev) => { handleChangeRight('organisations-evaluation', 'w-'+org, ev.target.checked) }}></CheckBox></td></tr>)
                                 //   retValue.push(<tr key={org + '_modulesurvey'}><td > </td><td ></td><td >Aanmaken vragenlijst</td><td style={{ width: '20%' }}><CheckBox id={org + '_modulesurvey'} checked={hasRights(item.rights, 'organisations-survey', 'w-'+org)} onChange={(ev) => { handleChangeRight('organisations-survey', 'w-'+org, ev.target.checked) }}></CheckBox></td></tr>)
                                  
                                    let tmpDepartments = departments.filter(d => { return d.organisation === org });
                                  
                                    for (let d = 0; d < tmpDepartments.length; d++) {
                                        
                                       // retValue.push(<tr key={tmpDepartments[d]._id}><td ></td><td >{tmpDepartments[d].name}</td><td >Locatiebeheerder</td><td style={{ width: '20%' }}><CheckBox id={tmpDepartments[d]._id} checked={hasRights(item.rights, 'departments', 'w-'+tmpDepartments[d]._id)} onChange={(ev) => { handleChangeRight('departments', 'w-'+tmpDepartments[d]._id, ev.target.checked) }}></CheckBox></td></tr>)
                                        retValue.push(<tr key={tmpDepartments[d]._id+'_moduleevaluation'}><td ></td><td >{tmpDepartments[d].name}</td><td >Aanmaken evaluatie</td><td style={{ width: '20%' }}><CheckBox id={tmpDepartments[d]._id+'_moduleevaluation'} checked={hasRights(item.rights, 'departments-evaluation', 'w-'+tmpDepartments[d]._id)} onChange={(ev) => { handleChangeRight('departments-evaluation', 'w-'+tmpDepartments[d]._id, ev.target.checked) }}></CheckBox></td></tr>)
                                  //      retValue.push(<tr key={tmpDepartments[d]._id+'_modulesurvey'}><td ></td><td ></td><td >Aanmaken vragenlijst</td><td style={{ width: '20%' }}><CheckBox id={tmpDepartments[d]._id+'_modulesurvey'} checked={hasRights(item.rights, 'departments-survey', 'w-'+tmpDepartments[d]._id)} onChange={(ev) => { handleChangeRight('departments-survey', 'w-'+tmpDepartments[d]._id, ev.target.checked) }}></CheckBox></td></tr>)
    
                                    }
                                }
                               
                                return retValue;
                            })
                        }
                    </tbody></table>
                    <br />
                    {useAdminModules && !organisation && <>
                        <div><b>Beheermodules voor Scherp medewerker</b></div>
                    <table className="ui-table"><tbody>
                        <tr><th style={headerStyle}>Module</th><th style={headerStyle}></th><th style={headerStyle}>Beheer</th></tr>
                        <tr><td style={{ width: '250px' }}>Organisaties</td><td></td><td><CheckBox id="fullcontrol-organisations" checked={hasRights(item.rights, 'fullcontrol-organisations', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-organisations', 'w', ev.target.checked) }}></CheckBox></td></tr>
                        <tr><td>Gebruikers</td><td></td><td><CheckBox id="fullcontrol-users" label="" checked={hasRights(item.rights, 'fullcontrol-users', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-users', 'w', ev.target.checked) }}></CheckBox></td></tr>
                        <tr><td>Vragenlijsten</td><td></td><td><CheckBox id="fullcontrol-surveys" label="" checked={hasRights(item.rights, 'fullcontrol-surveys', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-surveys', 'w', ev.target.checked) }}></CheckBox></td></tr>
                        <tr><td>Elearning trajecten</td><td></td><td><CheckBox id="fullcontrol-elearnings" label="" checked={hasRights(item.rights, 'fullcontrol-elearnings', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-elearnings', 'w', ev.target.checked) }}></CheckBox></td></tr>
                        <tr><td>Bevoegdheden applicatie</td><td></td><td><CheckBox id="fullcontrol-checklists" label="" checked={hasRights(item.rights, 'fullcontrol-checklists', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-checklists', 'w', ev.target.checked) }}></CheckBox></td></tr>
                        <tr><td>Instellingen algemeen</td><td></td><td><CheckBox id="fullcontrol-settings" label="" checked={hasRights(item.rights, 'fullcontrol-settings', 'w')} onChange={(ev) => { handleChangeRight('fullcontrol-settings', 'w', ev.target.checked) }}></CheckBox></td></tr>
                    </tbody></table>
                    </>}
                 
                </Paper>
            </div>}
        </div>
    )
}
