import React,{useState} from 'react'
import { Tabs, Table } from '../ui/core';

export default function List({items,onClick}) {
    const headers = [
        { label: 'Titel', attribute: 'title', default: true },
        { label: 'Beschrijving', attribute: 'description', default: true }
    ] 
    // <SearchBar />
    return (
        <>
            <div className="padding"><Table items={items} headers={headers} onClick={onClick}/></div>
        </>
    )
}
