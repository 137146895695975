import React,{useState,useEffect} from 'react'
import { XYPlot, LineSeries, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LabelSeries,RadarChart } from 'react-vis';
import ServiceSurvey from '../../services/survey';
import './monitor-dash-bar.css'
export default function MonitorDashBar({data,monitor}) {
    const [width,setWidth] = useState(300);
    const [chartData,setChartData] = useState([]);

    const trimWord = (word,amount)=>{
        if(word.length>amount){
            return word.substr(0,amount) + '...';
        }else{
            return word;
        }
    }

    useEffect(() => {
        let tmpData = [];
        if(monitor && monitor.mainProcesses && data.mainProcessScore){
            for(let m=0;m<monitor.mainProcesses.length;m++){
                let score = 0;
                let foundScore = data.mainProcessScore.find(score=>{return score._id.mainProcessCode === monitor.mainProcesses[m].code});
                if(foundScore) score = foundScore.perc;
                let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);
               
                tmpData.push({
                    x:trimWord(monitor.mainProcesses[m].title,16),
                    y:score,
                    color:color
                })
            }
        }
        if(monitor && monitor.subProcesses &&  data.subProcessScore){
            for(let m=0;m<monitor.subProcesses.length;m++){
                let score = 0;
                let foundScore = data.subProcessScore.find(score=>{return score._id.subProcessCode === monitor.subProcesses[m].code});
                if(foundScore) score = foundScore.perc;
                let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);
               
                tmpData.push({
                    x:trimWord(monitor.subProcesses[m].title,16),
                    y:score,
                    color:color
                })
            }
        }
       // console.log(monitor)
       // console.log(data);
        setChartData(tmpData);
        setWidth((monitor.mainProcesses.length + monitor.subProcesses.length)*90);
        
    }, [data])

   

    return (
         <XYPlot height={300} width={width} xType="ordinal" 
         margin={{
             left:60,
            top: 30,
            bottom: 80,
            right: 30
        }}
        >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis  tickLabelAngle={-25}/>
                <YAxis tickValues={[0, 10,20, 30, 40, 50,60,70,80,90,100]}   title="Procent"/>

                <LineSeries  colorType="literal" data={chartData} />
               
            </XYPlot>
    )
}
