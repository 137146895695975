import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "checklistcases";


const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getForOrganisation: (organisationID) => {
        return new Promise((resolve, reject) => {
            API.get('organisations/' + organisationID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    rights: () => {
        return [
            { val: 10, text: 'Inzage' },
            { val: 100, text: 'Beheer' }]
    },
    hasRight: (checklistcase, user, val) => {
        if (!checklistcase || !user) return false;
        if (checklistcase.rights && checklistcase.rights.length > 0) {
            let foundRights = checklistcase.rights.find(r => { return r.user === user._id });
            if (foundRights) {
                if (foundRights.right >= val) {
                    return true;
                }
            }
        }
        if (checklistcase.createdBy === user._id) {
            return true;
        }
        if(user._id === '5f6a5dd6abcc49a38d51b1c2'){
            return true;
        }
        return false;
    }
}
export default Service;