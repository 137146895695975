import React, { useEffect, useContext } from 'react'

import { Context } from '../../AppContext'; 
import { Typography } from '../../components/ui/typography';
import { ContextUI } from '../../uiContext';
export default function Index() {

    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    useEffect(() => {
        contextUI.setShowHeader(true);
        contextUI.setHeaderActions();
    }, [])

    return (
        <div className="padding">        

            {context.hasRights(context.user, 'fullcontrol-organisations', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/organisaties") }}>   <div className="card-content"><Typography component="h3">Organisaties</Typography></div></div>}
            {context.hasRights(context.user, 'fullcontrol-users', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/gebruikers") }}>   <div className="card-content"><Typography component="h3">Gebruikers</Typography></div></div>}
            {context.hasRights(context.user, 'fullcontrol-surveys', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/vragenlijsten") }}>   <div className="card-content"><Typography component="h3">Vragenlijsten</Typography></div></div>}
            {context.hasRights(context.user, 'fullcontrol-elearnings', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/e-learning") }}>   <div className="card-content"><Typography component="h3">E-learning</Typography></div></div>}
            {context.hasRights(context.user, 'fullcontrol-checklists', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/checklijsten") }}>   <div className="card-content"><Typography component="h3">Bevoegdheden applicatie</Typography></div></div>}
            {context.hasRights(context.user, 'fullcontrol-settings', 'w') && <div className="card"  onClick={() => { contextUI.setShowSideBar(false); if (context.history) context.history.push("/beheer/instellingen") }}>   <div className="card-content"><Typography component="h3">Instellingen</Typography></div></div>}



        </div>
    )
}
