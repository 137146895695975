import React, { useState, useEffect } from 'react'

import { Button, CheckBox,RadioButton } from '../../ui/core'
export default function Question({ question, answer, onChange }) {
    const [currentAnswer, setCurrentAnswer] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [passed,setPassed] = useState(false);
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);

    useEffect(() => {  
        if (answer) {
            if(answer.answer && answer.answer.length>0){
                setShowResult(true);
                let tmpCorrect = 0
                for(let i=0;i<answer.answer.length;i++){
                    let found = question.options.filter(opt=>{return opt._id ===  answer.answer[i] && opt.correct === true})
                    if(found.length>0){
                        tmpCorrect++;
                    }
                }
                if(tmpCorrect === question.options.filter(opt=>{return  opt.correct === true}).length && tmpCorrect === answer.answer.length ){
                    setPassed(true)
                }
            }
            setCurrentAnswer(answer);
        }else{
            setShowResult(false);
            setPassed(false);
            setCurrentAnswer({})
        }
    }, [answer])

    const handleChangeOption = (option, checked) => {
        let tmpAns = Object.assign({}, currentAnswer);
        tmpAns.question = question._id;
        if (!tmpAns.answer) tmpAns.answer = [];

        if(question.type === 0 ){
            //radiobutton
            tmpAns.answer = []
        }else{
            //checkbox
            tmpAns.answer = tmpAns.answer.filter(ans => { return ans !== option });
        }
      
        if (checked) {
            tmpAns.answer.push(option);
        }
        setCurrentAnswer(tmpAns);
    }

    const handleComplete = () => {
        let tmpAns = Object.assign({}, currentAnswer);
        tmpAns.complete = true;
        tmpAns.passed = passed;
        onChange(tmpAns);
    }

    const getBorderColor = (option)=>{
        if(showResult && option.correct){
            return '2px solid #4caf50' ;
        }
        return 'none'
    }

    const checkedOption = (option)=>{
        return currentAnswer && currentAnswer.answer && currentAnswer.answer.indexOf(option._id) >= 0
    }

    return (
        <div >
            <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }} ></h1>

            <div style={{width:'100%'}} >
                {
                    question.options && question.options.map(option => {
                        if (!option.text) return null
                        return <div key={option._id} style={{textAlign:'left',padding:'20px',margin:'10px',border:getBorderColor(option)}}>
                               {question.type === 0 && <RadioButton id={'rb' + option._id} disabled={showResult} checked={checkedOption(option)}  label={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.text) }}></div>}  onChange={(ev) => { handleChangeOption(option._id,ev.target.checked) }}></RadioButton>}
                             
                               {question.type === 1 &&  <CheckBox id={'cb' + option._id} disabled={showResult} checked={checkedOption(option)} label={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.text) }}></div>} onChange={(ev) => { handleChangeOption(option._id, ev.target.checked) }} ></CheckBox>}
                        </div>
                    })
                }
          </div>
            {showResult ?
                <>
                    <div style={{width:'100%',textAlign:'center'}}>
                        {passed && question.feedbackOnCorrect}
                        {!passed && question.feedbackOnIncorrect}
                        <br/>  <br/>
                    </div>
                    <Button fullWidth onClick={() => { handleComplete(currentAnswer) }} >Volgende</Button>
                </> : <>
                    <Button fullWidth onClick={() => { onChange(currentAnswer) }} >Check</Button>
                </>}
        </div>
    )
}
