import React from 'react'
import IconParagraph from './icons/paragraph.svg'
import IconParagraphImageLeft from './icons/paragraphimageleft.svg'
import IconParagraphVideo from './icons/paragraphvideo.svg'
import IconGallery from './icons/gallery.svg'
import IconHeading from './icons/heading.svg'
export default function BlockAdd({onSelect}) {
    return (
        <div className="block-menu">
            <div onClick={()=>{onSelect({type:'text',textStyle:'par'})}} className="block-add-box">  <img src={IconHeading}  /><br/>  Tekst </div>
            <div onClick={()=>{onSelect({type:'image'})}} className="block-add-box">  <img src={IconParagraphImageLeft}  /><br/>  Afbeelding </div>
            <div onClick={()=>{onSelect({type:'video'})}} className="block-add-box">  <img src={IconParagraphVideo}  /><br/>  Video </div>
            <br style={{clear:'both'}}/>
        </div>
    )
}
