import React, { useEffect, useState, useContext } from 'react'
import { Context } from '../../AppContext';
import ServiceGeneral from '../../services/general';
import { Button } from '../ui/button';
import { Typography } from '../ui/core';
import { Dialog } from '../ui/dialog';
import { DialogActions } from '../ui/dialogActions';
import { DialogContent } from '../ui/dialogContent';
import { DialogTitle } from '../ui/dialogTitle';

export default function MonitorEvaluationinfo({ history, survey, evaluation, chartData, onClose }) {
    const context = useContext(Context);

    const showEvaluation = () => {
        history.push('/evaluatie/' + evaluation._id)
    }
    return (
        <Dialog fullScreen={true}>
            <DialogContent>
                <table cellSpacing={8}>
                    <tbody>
                        <tr>
                            <td className="label" valign="top">Evaluatie</td>
                            <td> {evaluation.title}</td>
                        </tr>
                        <tr>
                            <td className="label" valign="top"></td>
                            <td> <i>{evaluation.description}</i></td>
                        </tr>
                        <tr>
                            <td className="label" valign="top">Betrokkenen</td>
                            <td> {evaluation.rights && evaluation.rights.map(r => {
                                return <div key={r._id}>{r.name} {r.email && <>{'(' + r.email + ')'}</>}</div>
                            })}</td>
                        </tr>
                        <tr>
                            <td className="label" valign="top">Vragenlijst</td>
                            <td> {survey.title}</td>
                        </tr>
                        <tr>
                            <td className="label" valign="top"></td>
                            <td> <i>{survey.description}</i></td>
                        </tr>
                        <tr>
                            <td className="label" valign="top">Datum</td>
                            <td> {ServiceGeneral.formatDate(survey.date, false)} </td>
                        </tr>
                     
                        <tr>
                            <td className="label" valign="top">Beoordelingen</td>
                            <td>
                                <table><tbody>
                                    {chartData && chartData.map(c => {
                                        return <tr key={c._id}><td>{c.fullTitle}</td><td style={{ textAlign: 'right' }}> {c.score}%</td></tr>
                                    })}
                                </tbody></table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                {evaluation.rights.filter(r => { return r.user === context.user._id }).length > 0 && <Button onClick={showEvaluation}>Bekijk evaluatie</Button>}
                <Button onClick={onClose}>Sluiten</Button></DialogActions>
        </Dialog>
    )
}
