import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "checklistcaseitems";


const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getForCase: (checklistcaseID) => {
        return new Promise((resolve, reject) => {
            API.get('checklistcases/' + checklistcaseID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getTypeName: (type) => {
        switch (type) {
            case 0:
                return 'Checklijst'
                break;
            case 1:
                return 'Naslag'
                break;
            case 2:
                return 'Notitie'
                break;
            case 3:
                return 'Acties';
                break;
            default:
                return '??';
                break;
        }
    }
}
export default Service;