import React, { useContext} from 'react'
import { ContextUI } from '../../uiContext';
import { Menu as MenuIcon, MoreVert ,ArrowBack} from './coreIcons';
import { IconButton, Menu } from './core';
import useWindowSize from './hooks/windowSize';

const Footer = function Footer() {
    const contextUI = useContext(ContextUI);

    const windowSize = useWindowSize();
    //contextUI.showFooter
    //contextUI.footerImage
    //contextUI.footerLine1
    //contextUI.footerLine2
    //contextUI.footerMenuItems

    const StyleIcon = {
        float: 'left',
        paddingLeft: '12px',
        paddingRight: '8px',
        display: windowSize.innerWidth > 370 ? 'block' : 'none'
      }

      const StyleText = {
        float: 'left',
        fontSize: '12px',
        paddingTop: '14px',
        paddingLeft: '6px',
        paddingRight: '8px',
        textAlign: 'center',
        display: windowSize.innerWidth > 500 ? 'block' : 'none'
      }

    return (
        <div className='footer' style={{ backgroundColor: contextUI.primary.color, color: contextUI.primary.contrast }}>
            <div style={{ ...StyleIcon }}><img style={{ height: '60px', width: '60px' }} src={contextUI.footerImage} alt='' /></div>
             <div style={{ ...StyleText }}>{contextUI.footerLine1}<br />{contextUI.footerLine2}</div>
             <div>{contextUI.footerMenuItems}</div>
        </div>
    )
}
export { Footer };
