import React, { useEffect, useState } from 'react'

import { TextField, TextArea, Paper,ImageUpload } from '../ui/core';
export default function Item({ item, onSave }) {
    const [editItem, setEditItem] = useState(item);

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }
    
    const handleChangeAndSave= (attribute,value)=>{
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }

    useEffect(() => {
        setEditItem(item);
    }, [item])

    return (
        <Paper className="padding" style={{marginBottom:'8px'}}>
            {editItem && <>                
                <TextField id="title" label="Titel" value={editItem.title} onChange={handleChange} onBlur={handleSave}></TextField>
                <TextArea id="description" label="Omschrijving" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea> 
                <TextField id="url" label="Website, url naar de cursus" value={editItem.url} onChange={handleChange} onBlur={handleSave}></TextField>
                <ImageUpload label="Afbeelding" value={editItem.image} onChange={(ev)=>{ handleChangeAndSave('image',ev.target.value);}} resize={350}/>
                 
              </>}
        </Paper>
    )
}
