import React from 'react'

import useWindowSize from '../hooks/windowsize';
export default function Text({ block }) {
    const windowsize = useWindowSize();
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    return (
        <div className="w3-animate-bottom">
            {block.image && <div style={{ backgroundImage: `url(${block.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: windowsize.innerHeight - 64 }}>
                {block.imageStyle && block.imageStyle.indexOf('textonimage') >= 0 && <div className="block-text-on-image"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.paragraph) }} ></div>}
            </div>}
            {block.imageStyle && block.imageStyle.indexOf('par') >= 0 && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.paragraph) }} ></div>}
        </div>
    )
}
