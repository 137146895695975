import React,{useRef,useState,useEffect} from 'react'
import './dialog.css';

const DialogTitle = function DialogTitle(props) {
    const ref = useRef(null);
    const [title,setTitle] = useState();
    const [height,setHeight] = useState('0px');

    let className = props.fullScreen ? 'ui-dialog-header-fullscreen':'ui-dialog-header';
    if(props.className){className = className +' ' + props.className }
    
    useEffect(() => {
        ref.current && setHeight(ref.current.offsetHeight + 'px')
    },[title])

    useEffect(() => {
        setTitle(<div ref={ref} className={className} style={props.style}>{props.children}</div>)
    }, [props.children])
    
    return (
        <>
        {title}
        <div style={{height:height}}>&nbsp;</div>
        </>
    )
}
export { DialogTitle };
