import React from 'react'

import Report from '../report/index';

import Processes from './processes';

export default function Overview({ survey }) {
    return (
        <div>
            <Processes survey={survey}/>
            <Report survey={survey} />
           </div>
    )
}
