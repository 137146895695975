import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/checklist';
import ServiceDocument from '../../../services/document';
import Item from '../../../components/module-checklist/item';
import Builder from '../../../components/module-checklist/builder/index';
import BuilderReference from '../../../components/module-checklist/builder-reference/index';

import { Tabs, Paper, Button } from '../../../components/ui/core';
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';


import Uploaddocument from '../../../components/general/uploaddocument';
import Documents from '../../../components/general/documents';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [selected, setSelected] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [uploadNewDocument, setUploadNewDocument] = useState('');
const [selectedTab,setSelectedTab] = useState(0)
    useEffect(() => {
        getItem();
        getDocuments();
        contextUI.setSubTitle('Bevoegdheden applicatie');
        contextUI.setShowBackButton(true);
        contextUI.setHeaderActions();
        return () => { }
    }, [])

    const getItem = () => { Service.get(props.match.params.id).then(item => setItem(item)).catch(err => { console.log(err) }) }

    const handleSave = (item) => { Service.update(item).then(result => {getItem(); }).catch(ex => { console.log(ex) }) }

    const getDocuments = () => {
        ServiceDocument.getForModule('checklist_report', props.match.params.id)
            .then(result => {
                setDocuments(result);
            })
    }

    const handleNewDocument = (document) => {
        document.module = uploadNewDocument;
        document.moduleid = item._id;
        ServiceDocument.insert(document)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { })
        setUploadNewDocument('');
    }

    const handleDeleteDocument = (doc) => {
        ServiceDocument.remove(doc)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { console.log(ex) })
    }

    if (!context.hasRights(context.user, 'fullcontrol-checklists', 'w')) return null;


    return (
        <div >
            <Paper>
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab">INSTELLINGEN</div>
                <div className="ui-tab">STAPPEN</div>
                </Tabs>
            </Paper>
            {item && <div className="padding">
                {selectedTab===0 && <Paper>
                    <Item item={item} onSave={handleSave} />
                </Paper>}
                <br />
                {selectedTab===1 && <Paper>

                    <Builder checklist={item} />

                </Paper>}
            </div>}
        </div>
    )
}
