import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '../ui/core'

export default function WikiPopup({item,onClose}) {
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    return (
        <Dialog fullScreen>
            {item.title && <DialogTitle><Typography component="h1">{item.title}</Typography></DialogTitle>}
            <DialogContent>
                <div style={{ width: '100%' }}  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.wiki) }} ></div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
