import React,{useState,useEffect,useContext} from 'react'
import { Paper, TextArea, Typography ,Button,CheckBox} from '../ui/core'
import {LocalLibrary} from '../ui/coreIcons';
import WikiPopup from './item-caseitem-wiki-popup';

import ServiceChecklistCaseItem from '../../services/checklistcaseitems';
import {Context} from '../../AppContext';
export default function ItemCaseitemWiki({item,onRefresh}) {
    const context = useContext(Context);
    const [readWiki,setReadWiki] = useState(); 
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);

    const handleCheck = (checked) =>{ 
        let saveItem = {};
        saveItem._id = item._id;
        saveItem.complete = checked;
        ServiceChecklistCaseItem.update(saveItem)
        .then(result=>{
            onRefresh();
        })
        .catch(ex=>{
            console.log(ex)
          //  context.setMessage('Het opslaan is mislukt');
        })
        
    }

    
    if (readWiki) return <WikiPopup item={item} onClose={()=>{setReadWiki(false)}}/>
    return (
        <div className="padding">
        <Typography component="h1" style={{borderBottom:'1px solid rgb(0, 105, 143)',paddingBottom:'6px'}}><LocalLibrary color={'rgb(0, 105, 143)'}/> {item.step}.{item.sort} {item.title}</Typography>
        {item.showWiki===true && <>
            <div style={{ whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.wiki) }}></div>
        </>}
        {item.showWiki!==true && <>
            <div style={{ whiteSpace: 'pre-wrap'}}>{item.introwiki}</div>
                {item.wiki && item.wiki.length > 0 && <Button style={{float:'right'}} onClick={()=>{setReadWiki(true)}}>Lees verder</Button>}
        </>}
      
        <br style={{clear:'both'}}/> <br style={{clear:'both'}}/>
        <CheckBox id={'complete_'+item._id} label="bekeken" checked={item.complete===true} style={{float:'right'}} onClick={(ev)=>{handleCheck(ev.target.checked)}}/><br style={{clear:'both'}}/>
    </div>
    )
}
