import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }) => {
    const [el] = useState(document.createElement('div'));
    const [mount] = useState(document.getElementById("portal"));

    useEffect(() => {
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el]);

    return createPortal(children, el)
};

export default Portal;