export let Capacitor;
export let Plugins;
export let FilesystemDirectory
export let AppState;
export let Filesystem;
export let FilesystemEncoding
try {
    if (window.Proxy) {
       const capacitor = require('@capacitor/core');
       Capacitor = capacitor.Capacitor;
       Plugins = capacitor.Plugins;
       FilesystemDirectory = capacitor.FilesystemDirectory;
       AppState = capacitor.AppState;
       Filesystem = capacitor.Filesystem;
       FilesystemEncoding = capacitor.FilesystemEncoding
    } else {
        Capacitor = undefined;
        Plugins = undefined;
        FilesystemDirectory = undefined;
        AppState = undefined;
        Filesystem = undefined;
        FilesystemEncoding = undefined;
    }
}
catch (e) {
    // Catch the error IE11 throws about Proxy being undefied;
}