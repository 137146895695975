import React from 'react'
import { SearchBar, Table } from '../ui/core';

export default function list({ items, onClick }) {
    const headers = [
        { label: 'Titel', attribute: 'title', default: true },
        { label: 'Beschrijving', attribute: 'description', default: true }
    ]
    return (
        <div className="padding">
            {items.map(item => {
                return <div key={item._id} onClick={() => { onClick(item) }} className="card" style={{cursor:'pointer'}}>
                    <div className="card-image"  style={{backgroundImage:'url('+item.image+')'}}></div>
                    <div className="card-content">
                        <h2>{item.title}</h2>
                        <i>{item.description}</i>
                    </div>
                </div>
            })}
        </div>
    )
}
