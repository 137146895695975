import API from "./api";
import StorageService from './storage';

const ATTRIBUTE = "answers";


const Service = {
    getForSurveyRespondent: (surveyID,respondentID) => {
        return new Promise((resolve, reject) => {
            API.get(`surveys/${surveyID}/respondents/${respondentID}/${ATTRIBUTE}`).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForQuestion:(surveyID,questionID)=>{
        return new Promise((resolve, reject) => {
            API.get(`surveys/${surveyID}/questions/${questionID}/${ATTRIBUTE}`).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE , [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
export default Service;