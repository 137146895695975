import React from 'react'

export default function ItemHeader({elearning,steps,complete}) {
    const style = {backgroundColor:'#ff8036',color:'white',padding:'12px',minHeight:'120px'}
    if(!elearning) return null;
    return (
        <div style={style}>
            <h1 style={{color:'white'}}>{elearning.title}</h1>


            {complete === 100 ? `Afgerond`: `${complete}% compleet`}
        </div>
    )
}
