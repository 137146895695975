import React, { useState, useEffect,useContext } from 'react'
import { Button, Paper, CheckBox, Typography, Table } from '../ui/core'
import { AlarmOn } from '../ui/coreIcons';

import ServiceChecklistCaseItem from '../../services/checklistcaseitems';
import ServiceDocument from '../../services/document';
import Documents from '../general/documents';

import { Context } from '../../AppContext';
export default function ItemCaseitemActions({ item, onRefresh,readOnly }) {
    const context = useContext(Context);
    const [actions, setActions] = useState([]);
    const [documents, setDocuments] = useState([]);
    const headers = [
        { label: 'Actie', attribute: 'description', default: true },
        { label: 'Uitvoeren na', attribute: 'interval', default: true },
        { label: 'Periode', attribute: 'periode', default: true }
    ]
    useEffect(() => {
        if (item.actions) {
            setActions(item.actions.map(action => {
                switch (action.timeperiod) {
                    case 'hours':
                        action.periode = 'uren';
                        break;
                    case 'days':
                        action.periode = 'dag(en)';
                        break;
                    case 'months':
                        action.periode = 'maand';
                        break;
                    case 'years':
                        action.periode = 'jaar';
                        break;
                }
                return action;
            }).filter(action => { return action.active === true }))
        }
        
        ServiceDocument.getForModule('checklist', item.copyFromId)
        .then(result => {
            setDocuments(result);
        })
    }, [item])

    const update = (saveItem) => {
        ServiceChecklistCaseItem.update(saveItem)
          .then(result => {
            onRefresh();
          })
          .catch(ex => {
            context.setMessage('Het opslaan is mislukt');
          })
      }
    const handleCheck = (checked) => {
        let saveItem = {};
        saveItem._id = item._id;
        saveItem.complete = checked;
        update(saveItem)
      }
    
    return (
        <div className="padding">
            <Typography component="h1" style={{ borderBottom: '1px solid rgb(0, 105, 143)', paddingBottom: '6px' }}><AlarmOn color={'rgb(0, 105, 143)'} /> {item.step}.{item.sort}  {item.title}</Typography>
            <Table items={actions} headers={headers} />
            <Documents documents={documents}  />
            <CheckBox disabled={readOnly} id={'complete_' + item._id} label="ingepland" checked={item.complete === true} style={{ float: 'right' }} onClick={(ev) => { handleCheck(ev.target.checked) }} /><br style={{ clear: 'both' }} />

        </div>
    )
}
