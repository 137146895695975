import React, { useEffect, useState } from 'react'
import { TextField, TextArea, IconButton, Menu, MenuItem, Divider, Select, Button, Paper, CheckBox } from '../../ui/core';
import { Delete, MoreVert } from '../../ui/coreIcons';

import ServiceDocument from '../../../services/document';
import ServiceQuestions from '../../../services/question';
import Service from '../../../services/checklistitems';
import Wiki from './wiki';
import Actions from './actions';
import { v4 as uuidv4 } from 'uuid';
import Question from '../../module-survey/builder/question';

import Uploaddocument from '../../general/uploaddocument';
import Documents from '../../general/documents';

export default function Checklist({ checklist, onSave, onDelete, surveys }) {
    const [item, setItem] = useState();
    const [editWiki, setEditWiki] = useState(false);
    const [uploadNewDocument, setUploadNewDocument] = useState('');
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        let tmpItem = Object.assign({}, checklist);
        setItem(tmpItem);
        getDocuments();
    }, [checklist])

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        let val = ev.target.value || ev.target.checked;
        if (ev.target.id === 'step' || ev.target.id === 'sort') {
            val = parseInt(val)
        }
        tmpItem[ev.target.id] = val;
        tmpItem._id = item._id;
        onSave(tmpItem);
    }


    const handleSaveWiki = (wiki) => {
        let tmpItem = {};
        tmpItem.wiki = wiki
        tmpItem._id = item._id;
        onSave(tmpItem);
        setEditWiki(false);
    }

    const handleSaveShowWiki = (val) => {
        let tmpItem = {};
        tmpItem.showWiki = val
        tmpItem._id = item._id;
        onSave(tmpItem); 
    }

    const handleSaveActions = (actions) => {
        let tmpItem = Object.assign({}, item);
        tmpItem.actions = actions;
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const newAction = (type) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.actions || !tmpItem.actions.push) { tmpItem.actions = [] }
        let newAction = {};
        newAction._id = uuidv4();
        newAction.active = true;
        tmpItem.actions.push(newAction);
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const addQuestion = (type) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.questions) { tmpItem.questions = [] }
        let maxNumber = 1;
        for (let i = 0; i < tmpItem.questions.length; i++) {
            if (tmpItem.questions[i].sort && tmpItem.questions[i].sort >= maxNumber) {
                maxNumber = parseInt(tmpItem.questions[i].sort) + 1
            }
        }
        let newQuestion = { _id: uuidv4(), question: '', type: type, sort: parseInt(maxNumber) }
        tmpItem.questions.push(newQuestion);
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const updateQuestion = (saveObject) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.questions) { tmpItem.questions = [] }
        let found = tmpItem.questions.find(q => { return q._id === saveObject._id });
        if (found) {
            let keys = Object.keys(saveObject);
            for (let k = 0; k < keys.length; k++) {
                found[keys[k]] = saveObject[keys[k]]
            }
        }
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const deleteQuestion = (deleteObject) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.questions) { tmpItem.questions = [] }
        for (let i = tmpItem.questions.length - 1; i >= 0; i--) {
            if (tmpItem.questions[i]._id === deleteObject._id) {
                tmpItem.questions.splice(i, 1);
            }
        }
        setItem(tmpItem);
        onSave(tmpItem);
    }

    const getDocuments = () => {
      
        ServiceDocument.getForModule('checklist', checklist._id)
            .then(result => {
                setDocuments(result);
            })
    }

    const handleNewDocument = (document) => {
        document.module = uploadNewDocument;
        document.moduleid = checklist._id;
        ServiceDocument.insert(document)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { })
        setUploadNewDocument('');
    }

    const handleDeleteDocument = (document)=>{
        if(window.confirm('Weet u zeker dat u dit document wilt verwijderen?')){
            ServiceDocument.remove(document) 
            .then(result => {
                getDocuments();
            })
            .catch(ex => { })
        }
    }

    const convertQuestions = (survey) => {
        ServiceQuestions.getForSurvey(survey)
            .then(items => {
                let newQuestions = items.filter(item => { return item.active === true }).map(item => { return { _id: item._id, text: item.text, question: item.question, options: item.options || [], sort: parseInt(item.sort), type: item.type, } })

                let tmpItem = Object.assign({}, item);
                tmpItem.questions = newQuestions;
                tmpItem.survey = null;
                setItem(tmpItem);
                onSave(tmpItem);

            })
    }

    return (<>
        {item && <>
            {editWiki && <Wiki item={item} onClose={() => { setEditWiki(false) }} onSave={handleSaveWiki} />}
            {uploadNewDocument.length > 0 && <Uploaddocument module={uploadNewDocument} onSave={handleNewDocument} onClose={() => { setUploadNewDocument('') }} />}
          
            <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', width: '100%' }}>
                <div style={{ width: '80%' }}>
                    <TextField id="title" value={item.title || ''} label={'Titel'} onChange={handleChange} onBlur={handleSave} />
                </div>
                <div style={{ width: '10%' }}><TextField id="step" type="number" label="Stap" value={item.step || 1} onChange={handleChange} onBlur={handleSave}></TextField></div>
                <div style={{ width: '10%' }}><TextField id="sort" type="number" label="Volgorde" value={item.sort || 0} onChange={handleChange} onBlur={handleSave}></TextField></div>
                <div style={{ width: '10%' }}>
                    <Menu id={'item_edit_' + item._id} control={<IconButton style={{ float: 'right' }}><MoreVert /></IconButton>} >
                     
                        {item.type === 1 && <MenuItem onClick={() => { setEditWiki(true) }}>Naslag opmaken</MenuItem>}
                        {item.type === 3 && <MenuItem onClick={() => { newAction() }}>Actie toevoegen</MenuItem>}
                        <MenuItem onClick={() => { setUploadNewDocument('checklist')}}>Document toevoegen</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { onDelete(item) }}>Verwijderen</MenuItem>
                    </Menu>
                </div>
            </div>

            {item.type === 0 && <>
                <br />
                {item.survey && <Select id="survey" label="Vragenlijst" value={item.survey || ''} onChange={handleSave}>
                    <option value=''></option>
                    {surveys.map(survey => { return <option value={survey._id} key={survey._id}>{survey.title}</option> })}
                </Select>}
                {item.survey && <Button onClick={() => { convertQuestions(item.survey) }}>Omzetten</Button>}
                <Paper className="padding" >
                    <div className="label">Checks</div><br />
                    {item.questions && item.questions.sort((a,b)=>{return a.sort-b.sort}).map(question => {
                        return <Paper key={'panel' + question._id} className="padding" style={{ marginBottom: '8px' }}><Question question={question} onSave={updateQuestion} onDelete={deleteQuestion} hideCode={true}/></Paper>
                    })}<br />
                    <Menu id="questionselectmenu" control={<Button fullWidth variant="outlined" >Controle item toevoegen</Button>}>
                        <MenuItem onClick={() => addQuestion(0)}>Tekstblok</MenuItem>
                        <MenuItem onClick={() => addQuestion(1)}>Open vraag, enkele regel</MenuItem>
                        <MenuItem onClick={() => addQuestion(2)}>Open vraag, meerdere regels</MenuItem>
                        <MenuItem onClick={() => addQuestion(3)}>Gesloten vraag,<br /> 1 antwoord mogelijkheid</MenuItem>
                        <MenuItem onClick={() => addQuestion(4)}>Gesloten vraag,<br /> meerdere antwoord mogelijkheden</MenuItem>
                    </Menu>
                </Paper>
            </>}

            {item.type === 1 && item.showWiki!==true &&  <>
                <TextArea fullWidth id="introwiki" value={item.introwiki || ''} label="Introductietekst naslag" onChange={handleSave} />
            </>}
            {item.type === 1 && <><br/>
                <CheckBox id="showWiki" checked = {item.showWiki||false} label="Toon naslag direct in het scherm (introductietekst wordt niet getoond)" onChange={handleSave}></CheckBox>
            </>}
            {item.type === 3 && <Actions actions={item.actions} onSave={handleSaveActions} />}
            <Documents documents={documents} onDelete={handleDeleteDocument}/>
            <div className="label" style={{ paddingTop: '6px', textAlign: 'right' }}>{Service.getTypeName(item.type)}</div>
        </>}
    </>
    )
}
