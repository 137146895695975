import React from 'react'
import EditText from './edit-block/text';
import EditImage from './edit-block/image';
import EditVideo from './edit-block/video';
import Options from './block-edit-options';
export default function BlockEdit({block,onChange,onSave,selected,UploadComponent}) {
    return (
        < >
           <Options block={block} onChange={onChange} selected={selected} onSave={onSave}/>
           {block.type==='text' && <EditText block={block} onChange={onChange} onSave={onSave}/>}
           {block.type==='image' && <EditImage block={block} onChange={onChange} onSave={onSave} UploadComponent={UploadComponent}/>}
           {block.type==='video' && <EditVideo block={block} onChange={onChange} onSave={onSave} UploadComponent={UploadComponent}/>}
        </>
    )
}
