import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/elearning';

import GeneralService from '../../../services/general';

import List from '../../../components/module-elearning/list';

import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { IconButton, SearchBar } from '../../../components/ui/core';
import { Add } from '../../../components/ui/coreIcons';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [items, setItems] = useState([]);

    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [search, setSearch] = useState('');

    const headers = [
        { label: 'Titel', attribute: 'title', default: true },
        { label: 'Beschrijving', attribute: 'descr', default: true }
    ]

    //INITIAL FUNCTIONS
    useEffect(() => {
        getItems();
        contextUI.setSubTitle('E-learning trajecten');
        contextUI.setShowBackButton(false);
        contextUI.setHeaderActions(<IconButton onClick={addItem}><Add color="white" /></IconButton>)
        return () => { }
    }, [])

    useEffect(() => {
        setItemsFiltered(items.filter(i => { return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase()) >= 0 }));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])


    //OPEN ITEM
    const handleClickItem = (item) => {
        props.history.push('/beheer/e-learning/' + item._id)
    }

    const getItems = () => { Service.get().then(items => setItems(GeneralService.sortJSON(items, 'title', '123'))).catch(err => { console.log(err) }) }

    const addItem = () => { Service.insert({}).then(item => { viewItem(item._id); }).catch(ex => { console.log(ex) }) }

    const viewItem = (id) => { props.history.push('/beheer/e-learning/' + id) }

    if (!context.hasRights(context.user, 'fullcontrol-elearnings', 'w')) return null;


    return (
        <div>
            <SearchBar value={search} onKeyUp={setSearch} onReset={() => { setSearch('') }}></SearchBar>

            <List items={itemsFiltered} onClick={(item) => viewItem(item._id)} />
        </div>
    )
}
