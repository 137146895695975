import React, { useState, useContext, useEffect } from 'react'
import ServiceDocument from '../../../services/document';
import ServiceSurvey from '../../../services/survey';
import ServiceEvaluation from '../../../services/evaluations';
import ServiceAPI from '../../../services/api';
import Uploaddocument from '../../../components/general/uploaddocument';
import Documents from '../../../components/general/documents';
import { Button, IconButton, Typography } from '../../ui/core'
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { Info } from '../../ui/coreIcons';
import InfoScreen from './info';
import DetailScreen from './detail';
import GenerateScreen from './generate';
export default function Index({ survey }) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [documents, setDocuments] = useState([]);
    const [uploadNewDocument, setUploadNewDocument] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [showReportGenerator, setShowReportGenerator] = useState(false);
    const [hasDetailRights, setHasDetailRights] = useState();
    useEffect(() => {
        ServiceEvaluation.get(survey.evaluation)
            .then(evaluation => {
                if (evaluation) {
                    let hasRight = ServiceEvaluation.hasRight(evaluation, context.user, 50);
                    setHasDetailRights(hasRight);
                } else {
                    setHasDetailRights(false);
                    console.log('No evaluation found')
                }
            })

        //  getDocuments();
        return () => { }
    }, []);

    useEffect(() => {
        getDocuments();
    }, [hasDetailRights])

    const getDocuments = () => {

        ServiceDocument.getForModule('surveys_report', survey._id)
            .then(result => {

                if (hasDetailRights == true) {
                    let detailView = {
                        _id: 'detailview',
                        active: true,
                        lastmodified: new Date(),
                        name: 'Gedetailleerd rapport',
                        title: 'Gedetailleerd rapport',
                        size: 0,
                        type: 'html',
                        canDelete: false
                    }
                    result.push(detailView);
                }

                setDocuments(result);
            })
    }

    const handleNewDocument = (document) => {
        document.module = uploadNewDocument;
        document.moduleid = survey._id;
        ServiceDocument.insert(document)
            .then(result => {
                getDocuments();
            })
            .catch(ex => { })
        setUploadNewDocument('');
    }

    const handleDeleteDocument = (doc) => {
        if (window.confirm('Weet u zeker dat u dit document wilt verwijderen?')) {
            ServiceDocument.remove(doc)
                .then(result => {
                    getDocuments();
                })
                .catch(ex => { console.log(ex) })
        }
    }

    const handleGenerateDocument = (doc) => {
        if (doc._id === 'detailview') {
            setShowDetail(true)
        } else {
            contextUI.setMessage('Rapportage "' + doc.title + '" wordt aangemaakt en gedownload.')
            ServiceDocument.requestDownloadKey(doc._id, doc.moduleid)
                .then(result => {
                    if (result && result.link) {
                        ServiceDocument.downLoadFile(result.link)
                            .then(base64 => {
                                ServiceSurvey.getReportValues(survey)
                                    .then(surveyValues => {
                                        let postData = {};
                                        postData.base64 = base64;
                                        postData.filename = doc.title + '.docx';
                                        postData.values = surveyValues;
                                        ServiceAPI.postWordDocument('', null, postData)
                                            .then(result => {

                                            })
                                            .catch(ex => {
                                                console.log(ex)
                                            })
                                    })

                            })
                            .catch(ex => {
                                console.log(ex)
                            })
                    } else {

                    }
                })
                .catch(ex => { console.log(ex) })
        }

    }


    return (
        <div >
            {showReportGenerator &&<GenerateScreen survey={survey} onClose={() => { setShowReportGenerator(false) }} />}
            {showDetail && <DetailScreen survey={survey} onClose={() => { setShowDetail(false) }} />}
            {showInfo && <InfoScreen survey={survey} onClose={() => { setShowInfo(false) }} />}
            <Typography component="h3">Rapportages <span style={{ cursor: 'pointer' }} onClick={() => { setShowInfo(true) }} ><Info /></span></Typography>
            {uploadNewDocument.length > 0 && <Uploaddocument module={uploadNewDocument} onSave={handleNewDocument} onClose={() => { setUploadNewDocument('') }} />}

            {<Documents documents={documents} onDelete={handleDeleteDocument} onClick={handleGenerateDocument} />}
            <Button fullWidth variant={'outlined'} onClick={() => { setUploadNewDocument('surveys_report') }}>Upload template</Button>
            {hasDetailRights && <Button fullWidth variant={'outlined'} onClick={() => { setShowReportGenerator(true) }}>Genereer rapport</Button>}
        </div>
    )
}
