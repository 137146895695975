import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/elearningsteps';
import Item from '../../../components/module-elearning/builder/lesson/index';
import { ContextUI } from '../../../uiContext';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const [item, setItem] = useState();

    //GETTING ITEMS FROM API
    const getItem = () => {Service.get(props.match.params.stepid).then(gotItem => setItem(gotItem)).catch(err => { console.log(err) }) }

    //INITIAL FUNCTIONS
    useEffect(() => {
        getItem();
        contextUI.setSubTitle('E-learning - les');
        contextUI.setShowBackButton(true);
        contextUI.setHeaderActions();
        return () => { }
    }, [])

   
    const handleChange = (changedItem,save) =>{
        setItem(changedItem);
        if(save){
            Service.update(changedItem).then(result => {console.log(result) }).catch(ex => { console.log(ex) }) 
        }
    
    }
    if(!item){return null}
    console.log('Item',item.blocks && item.blocks.map(b=>{return b.textStyle}))
    return (
        <div className="padding" >
            {item && <Item item={item}  onChange={handleChange}/>}            
        </div>
    )
}
