import React, { useState, useContext } from 'react'
import { TextField, Typography, Button } from '../../ui/core';

import UserService from '../../../services/user';
import { Context } from '../../../AppContext';
import { ContextUI } from '../../../uiContext';
export default function NewPassword({ onClose }) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(0);


    const handleSendPassword = () => {

        if (email.length > 0 && email.indexOf('@') > 0 && email.indexOf('.') > 0) {
            UserService.requestNewPasswordToken(email)
                .then(result => {
                    setStep(1);
                })
                .catch(err => {
                    contextUI.setMessage('Er is een fout opgetreden, probeer het later nogmaals.')
                })
        } else {
            contextUI.setMessage('U heeft een niet geldig E-mailadres opgegeven.');
        }
    }

    const handleSavePassword = () => {
        if (newPassword.length < 6) {
            contextUI.setMessage('Uw wachtwoord moet uit minimaal 6 tekens bestaan.');
        } else {
            UserService.changePassword(email,newPassword,code)
            .then(result => {
                contextUI.setMessage('Uw wachtwoord is opgeslagen.');
                onClose();
            })
            .catch(err => {
                contextUI.setMessage('Het wachtwoord kan niet worden ingesteld, controleer de ontvangen code.')
            })
        }
    }

    return (
        <>
            <Typography component={'h2'} style={{ textAlign: 'center' }}>Aanmaken (nieuw) wachtwoord</Typography>
            <div style={{ textAlign: 'center' }}>Om uw wachtwoord te kunnen wijzigen dienen wij uw account te verifiëren. Voer uw e-mailadres in en klik op "Aanvraag verzenden".</div>
            <TextField id="email" required={true} label={'E-mailadres'} value={email} onChange={(ev) => { setEmail(ev.target.value) }} ></TextField>
            {step === 0 && <Button style={{ width: '100%' }} onClick={() => { handleSendPassword() }}>Aanvraag verzenden</Button>}
            {step > 0 && <>
                <div style={{ textAlign: 'center' }}><br />Indien u in ons systeem bekend bent is er een bericht naar uw e-mail verstuurd met daarin een code om een nieuw wachtwoord aan te maken. Deze kunt u hieronder invullen.
Als u de e-mail niet binnen een paar minuten na het aanvragen ontvangt, dan kan het zijn dat u een foutief e-mailadres heeft ingevoerd, of dat de e-mail terecht is gekomen in uw spamfolder.<br /></div>
                <TextField id="code" required={true} label={'Ontvangen code'} value={code} onChange={(ev) => { setCode(ev.target.value) }} type="text"></TextField>
                <TextField id="newPassword" required={true} label={'Gewenst wachtwoord (min. 6 tekens)'} value={newPassword} onChange={(ev) => { setNewPassword(ev.target.value) }} type="password"></TextField>
                <Button style={{ width: '100%' }} onClick={() => { handleSavePassword() }}>Wachtwoord opslaan</Button>
            </>}

            <Button variant={'outlined'} style={{ width: '100%' }} onClick={() => { onClose() }}>Annuleren</Button>
        </>
    )
}
