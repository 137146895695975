import React, { useContext } from 'react'
import { ContextUI } from '../../uiContext';
import './button.css';

//variant:  contained outlined text
const Button = function Button(props) {
    const contextUI = useContext(ContextUI);
    const style = {};
    style.backgroundColor = contextUI.primary.color;
    style.color = contextUI.primary.contrast;
    style.width = props.fullWidth ? '100%' : 'auto';

    //variant
    switch (props.variant) {
        case 'contained':

            break;
        case 'outlined':
            style.backgroundColor = contextUI.primary.contrast;
            style.color = contextUI.primary.color;
            style.border = '1px solid ' + contextUI.primary.color;
            style.boxShadow = 'none'
            break;
        case 'text':
            style.backgroundColor = 'white';
            style.color = contextUI.primary.color;
            style.boxShadow = 'none'
            style.border = 'none'
            break;
        default:
            break;

    }

    if(props.disabled ===true){style.backgroundColor  = '#cccccc'}

    return (
        <button style={{ ...style, ...props.style }}
            onClick={props.onClick}
            className='button'
            disabled={props.disabled}
        >
            {props.startIcon && <span className='button-start-icon'>{props.startIcon}</span>}
            <span className='button-label'>{props.children}</span>
            {props.endIcon && <span className='button-end-icon'>{props.endIcon}</span>}
        </button>
    )
}
export { Button };
