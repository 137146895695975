import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/elearning';
import Item from '../../../components/module-elearning/item';
import Builder from '../../../components/module-elearning/builder/index'
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { Typography } from '../../../components/ui/core';
import { Tabs, Paper, Button, IconButton, Menu,MenuItem } from '../../../components/ui/core';

import {  MoreVert } from '../../../components/ui/coreIcons';
export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();

    //GETTING ITEMS FROM API
    const getItem = () => { Service.get(props.match.params.id).then(item => setItem(item)).catch(err => { console.log(err) }) }

    //INITIAL FUNCTIONS
    useEffect(() => {
        getItem();
        contextUI.setSubTitle('E-learning');
        contextUI.setShowBackButton(true); 
        let menu = <Menu id="additemmenu" noscroll={true} control={<IconButton><MoreVert color="white" /></IconButton>}> 
       <MenuItem onClick={() => { handleDelete() }}>Verwijder E-learning</MenuItem>
   </Menu> 
   contextUI.setHeaderActions(menu);
        return () => { }
    }, [])

    const handleSave = (item) => { Service.update(item).then(result => { console.log(result) }).catch(ex => { console.log(ex) }) }

    const handleDelete = ()=>{
        var check = window.confirm("Weet u zeker dat u deze e-learning wilt verwijderen?");
        if(check){
            Service.remove({_id:props.match.params.id})
            .then(result=>{
                props.history.push('/beheer/e-learning');
            })
            .catch(ex=>{console.log(ex)}) 
        }
    }

    
    if(!context.hasRights(context.user, 'fullcontrol-elearnings', 'w')) return null;

    /*
      <Typography component="h2">Onderdelen</Typography>
            {item && <Builder elearning={item}  {...props}/>}
             */
    return (
        <div className="padding" >
            {item && <Item item={item} onSave={handleSave}  {...props}/>}
          
        </div>
    )
}
