import React, { useEffect, useState } from 'react';
import ContentEditable from "react-contenteditable";
import Menu from './menu';
import useWindowSize from "./hooks/windowsize";
import { Button, Paper } from '../ui/core';
export default function Veditor(props) {
    const createDOMPurify = require('dompurify');
    const DOMPurify = createDOMPurify(window);
    const [updated, setUpdated] = useState(new Date());
    const windowSize = useWindowSize();
    const [topEditor, setTopEditor] = useState(0);
    const [cursorInTable, setCursorInTable] = useState(false);
    const [formModus, setFormModus] = useState(false);

    if (!window.tempValueVEditor) { window.tempValueVEditor = [] }

    //setting initial value for the first time
    useEffect(() => {
        if (props.value) {
            window.tempValueVEditor[props.id] = props.value
        } else {
            window.tempValueVEditor[props.id] = '';
        }
        setUpdated(new Date());

        if (props.contentRef.current) {
            props.contentRef.current.addEventListener('paste', pasteAction);
        }

        return () => {
            props.contentRef.current.removeEventListener('paste', pasteAction);
        }
    }, [props.value]);

    useEffect(() => {
        props.formModus && setFormModus(props.formModus);
    }, [props.formModus])

    useEffect(() => {
        if(document.getElementById(props.id)){
            let top = document.getElementById(props.id).getBoundingClientRect().top;
            setTopEditor(top + 50);
        }
    }, [props,windowSize])

    const pasteAction = (e) => {
        e.preventDefault();
        var clipdata = e.clipboardData || window.clipboardData;
        var text = clipdata.getData('text/html');   
        
        //replace own url if copied
        let ownURL = window.location.protocol + '//' + window.location.host + '/';
        var regex = new RegExp(ownURL, 'g');
        text = text.replace(regex,'')

        if (document.queryCommandSupported('insertHTML')) {
            document.execCommand('insertHTML', false, text);
        } else if (document.queryCommandSupported('paste')) {
            document.execCommand('paste', false, text);
        }
        
    }

    const handleChange = () => {
        if (props.contentRef && props.contentRef.current) {
            window.tempValueVEditor[props.id] = props.contentRef.current.innerHTML;
        }
    }


    const checkAction = (event) => {
        handleChange();

        let r;
        if (window.getSelection)//non IE Browsers
        {
            try {
                r = window.getSelection().getRangeAt(0);
                //    setRange(r)
            } catch (ex) { }

        }
        else if (document.selection)//IE
        {
            try {
                r = document.selection.createRange();
                window.tempValueRangeVEditor = r;
                //   setRange(document.selection.createRange());
            } catch (ex) { }
        }
        window.tempValueRangeVEditor = r;
        //test replacing select options
        //props.contentRef.current.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        //let formElements = props.contentRef.current.querySelectorAll('select:not([disabled])');
        // props.contentRef.current.innerHTML =  props.contentRef.current.innerHTML.replace(formElements[0].innerHTML,formElements[0].innerHTML.replace('eerste','1e'));


        try {

            let tbl = getParentTable(r.startContainer.parentElement);
            if (tbl) {
                setCursorInTable(true);
            } else {
                setCursorInTable(false);
            }

        } catch (ex) { }


    }

    const checkActionKeyDown = (event) => {
        if (event.keyCode === 9) { // tab key
            event.preventDefault();
            if (event.shiftKey) {
                document.execCommand('outdent', false, null);
            }
            else {
                document.execCommand('indent', false, null);

            }
        }
    }

    const getParentTable = (elem) => {
        if (elem.tagName.toLowerCase() === 'table') {
            return elem;
        } else {
            if (elem.parentElement) {
                return getParentTable(elem.parentElement);
            } else {
                return null;
            }
        }
    };

    const getCurrentRow = (elem) => {
        if (elem.tagName.toLowerCase() === 'tr') {
            return elem.rowIndex;

        } else {
            if (elem.parentElement) {
                return getCurrentRow(elem.parentElement);
            } else {
                return null;
            }
        }
    };

    const addRow = () => {
        let tbl = getParentTable(getSelection().getRangeAt(0).startContainer.parentElement);
        if (tbl.rows) {
            var cellCount = tbl.rows[tbl.rows.length - 1].cells.length;
            var row = tbl.insertRow(tbl.rows.length);
            for (var i = 0; i < cellCount; i++) {
                row.insertCell(i);
            }
        }
        handleChange();
        props.contentRef.current.focus();
    };

    const deleteRow = () => {
        var tbl = getParentTable(getSelection().getRangeAt(0).startContainer.parentElement);
        if (tbl) {
            let trCount = getCurrentRow(getSelection().getRangeAt(0).startContainer.parentElement);
            if (trCount !== null) {
                if (tbl.rows.length - 1 < trCount) {
                    trCount = tbl.rows.length - 1;
                }
                tbl.deleteRow(trCount);
            }
        }
        handleChange();
        props.contentRef.current.focus();
    }


    window.updateTextareaHeights();

    const getHeight = () =>{
        if(props.height){
            return props.height
        }else{
            return (windowSize.innerHeight - topEditor -150 )
        }
     
    }

    return (
        <div style={{ paddingTop: '6px', borderBottom: !props.disabled ? '1px solid gray' : '' }} >
            {props.label && <div className="label">{props.label}</div>}
            <Paper elevation={4}>
                {(!props.disabled) && <Menu contentEditable={props.contentRef} onChange={handleChange} formModus={formModus} onSetFormModus={(modus) => { setFormModus(modus) }} content={window.tempValueVEditor[props.id] || ''} files={props.files} pages={props.pages} />}
                {(cursorInTable && formModus === false) && <React.Fragment><Button onClick={() => addRow()}>Rij toevoegen</Button><Button onClick={() => deleteRow()}>Rij verwijderen</Button></React.Fragment>}

                <div id={props.id}>
                    {formModus === false && <ContentEditable
                        innerRef={props.contentRef}
                        style={{ fontFamily: 'inherit', fontSize: '14px', minHeight: '200px', height: getHeight(), overflowY: 'scroll' }}
                        className="editable padding veditor content"
                        tagName="div"
                        html={window.tempValueVEditor[props.id] || ''}// innerHTML of the editable div
                        disabled={props.disabled || false} // use true to disable edition
                        onChange={(ev) => handleChange(ev)}
                        onClick={(ev) => { checkAction(ev) }}
                        onKeyUp={(ev) => { checkAction(ev) }}
                        onKeyDown={checkActionKeyDown}
                    />}
                    {formModus === true && <div ref={props.contentRef} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(window.tempValueVEditor[props.id] || '') }} className="veditor padding" />}
                </div>
            </Paper>
        </div>
    )
}