import React, { useState, useEffect, useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, Typography } from '../ui/core'
import ServiceChecklist from '../../services/checklist';
import ServiceChecklistItems from '../../services/checklistitems';

import Service from '../../services/checklistcase';
import { Context } from '../../AppContext';
export default function PopupNewCase({ history, onClose }) {
    const context = useContext(Context);
    const [title, setTitle] = useState('');
    const [checkLists, setCheckLists] = useState([]);
    const [selectedChecklist, setSelectedChecklist] = useState('')
    useEffect(() => {
        ServiceChecklist.get()
            .then(result => { setCheckLists(result.filter(item=>{return item.published===true && !item.archived})) })
    }, [])

    const onSave = () => {
        Service.insert({ title: title, organisation: context.organisation._id, checklist: selectedChecklist }).then(item => {
            history.push('/checklist/' + item._id);
        }).catch(ex => { console.log(ex) })
    }
    return (
        <Dialog>
            <DialogTitle><Typography component="h2">Aanmaken nieuwe case</Typography></DialogTitle>
            <DialogContent>
                <TextField id="title" label="Titel voor de case" value={title} onChange={(ev) => { setTitle(ev.target.value) }} /><br /><br />
                <Select label="Selecteer de gewenste bevoegdheid" id="checklist" value={selectedChecklist} onChange={(ev) => { setSelectedChecklist(ev.target.value) }}>
                    <option></option>
                    {checkLists.map(item => { return <option key={item._id} value={item._id}>{item.title}</option> })}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Annuleren</Button>
                <Button variant="contained" onClick={onSave} disabled={!title || !selectedChecklist}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    )
}
