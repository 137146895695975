import React, { useEffect, useState } from 'react'
import ServiceOrganisations from '../../services/organisation';
import { TextField, TextArea, Button, Paper, Tabs, Select, IconButton } from '../ui/core';
import { Delete } from '../ui/coreIcons';
export default function Item({ item, onChange, onSave }) {
    const [organisations, setOrganisations] = useState([])


    useEffect(() => {
        ServiceOrganisations.get()
            .then((organisations) => { setOrganisations(organisations) })
            .catch((ex) => { console.log(ex) })
    }, [])


    const handleSelectOrganisation = (ev) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.organisations) { tmpItem.organisations = [] };
        tmpItem.organisations.push(ev.target.value);
        onChange(tmpItem);
        onSave(tmpItem);
    }
    const handleDeleteOrganisation = (orgID) => {
        if (window.confirm('Weet u zeker dat u deze organisatie wilt loskoppelen?')) {
            let tmpItem = Object.assign({}, item);
            tmpItem.organisations = tmpItem.organisations.filter(org => { return org !== orgID })
            onChange(tmpItem);
            onSave(tmpItem);
        }
    }

    const getOrganisationName = (id) => {
        let found = organisations.find(org => { return org._id === id });
        if (found) {
            return found.name;
        } else {
            return '??';
        }
    }
    const getOrganisationAddres = (id) => {
        let found = organisations.find(org => { return org._id === id });
        if (found) {
            return (found.address ||'') + ', ' + (found.place || '');
        } else {
            return '??';
        }
    }

    const headerStyle = { backgroundColor: "rgb(0, 105, 143)", color: "rgb(255, 255, 255)" }

    if (!item) return null;
    return (
        <div className="padding" >
            <Paper className="padding">

                <table className="ui-table"><tbody>
                    <tr><th style={headerStyle}>Naam</th><th style={headerStyle}>Adres</th><th style={headerStyle}></th></tr>
                    {item.organisations && item.organisations.map(org => {
                        return <tr key={org}><td >{getOrganisationName(org)}</td><td >{getOrganisationAddres(org)}</td><td style={{ width: '20%' }}><IconButton onClick={() => handleDeleteOrganisation(org)}><Delete /></IconButton></td></tr>
                    })}
                </tbody></table>

                <br /><br />
                <div className="label">Toevoegen organisatie</div>
                <Select value={null} onChange={handleSelectOrganisation}  >
                    <option value={0}>Kies een organisatie om aan deze gebruiker te koppelen</option>
                    {organisations.map(org => {
                        if (item.organisations && item.organisations.indexOf(org._id) >= 0) {
                            return null
                        } else {
                            return <option key={org._id} value={org._id}>{org.name}</option>;
                        }
                    })}
                </Select>



            </Paper>

        </div>
    )
}
