import React, { useContext, useState } from 'react'
import { ContextUI } from '../../uiContext';
import { v4 as uuidv4 } from 'uuid';
const TextField = function TextField(props) {
    const [prefixID] = useState(uuidv4() + "#") 
    const contextUI = useContext(ContextUI);
    const [style, setStyle] = useState(props.style || {})

    if (!props.id) {
        console.warn('No ID set for textfield' , props)
    } 

    const onChange = (ev)=>{
        let retVal = {};
        retVal.target = {}; 
        retVal.target.value = ev.target.value;
        retVal.target.id = ev.target.id.replace(prefixID,'');
        props.onChange && props.onChange(retVal);
    }
    const onBlur = (ev)=>{
        let retVal = {};
        retVal.target = {}; 
        retVal.target.value = ev.target.value;
        retVal.target.id = ev.target.id.replace(prefixID,'');
        props.onBlur && props.onBlur(retVal);
    }
    const onFocus = (ev)=>{
        let retVal = {};
        retVal.target = {}; 
        retVal.target.value = ev.target.value;
        retVal.target.id = ev.target.id.replace(prefixID,'');
        props.onFocus && props.onFocus(retVal);
    }
    return (
        < >
            {props.label && <div className='textFielLabel'>{props.label} {props.required===true && '*'}</div>}
            <input
                required={props.required || false}
                id={prefixID + props.id}
                style={style}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={onChange}
                onKeyUp={props.onKeyUp}
                type={props.type || 'text'}
                className='textField'
                readOnly={props.readOnly|| false}
                onFocus={(ev) => {setStyle({ ...props.style, borderBottom: '1px solid ' + contextUI.primary.color });onFocus(ev)}}
                onBlur={(ev) => {setStyle(props.style);onBlur(ev)}}
            ></input>
            {props.error && <div className='textFieldError'>{props.error}</div>}
            {props.helper && <div className='textFieldHelper'>{props.helper}</div>}
        </>
    )
}

export { TextField };
