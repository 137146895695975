import React, { useContext, useEffect, useState } from 'react';
import Service from '../../services/checklistcase';
import ServiceOrganisations from '../../services/organisation';
import GeneralService from '../../services/general';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import { IconButton, SearchBar, Paper, Tabs } from '../../components/ui/core';
import { Add ,Help} from '../../components/ui/coreIcons';
import List from '../../components/module-checklist-case/list';
import SelectOrganisation from '../../components/organisations/selector';
import PopupNewCase from '../../components/module-checklist-case/popupnewcase';

export default function Index(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [items, setItems] = useState([]);
    const [organisations, setOrganisations] = useState([])
    const [selectedTab, setSelectedTab] = useState(0)
    const [showNew, setShowNew] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [search, setSearch] = useState('');
    useEffect(() => {
        context.organisation && getItems();
        contextUI.setShowHeader(true);
        contextUI.setSubTitle('Dashboard');
        contextUI.setShowBackButton(false);
        contextUI.setHeaderActions(<><IconButton onClick={() => { setShowNew(true) }}><Add color="white" /></IconButton><IconButton onClick={()=>context.setShowFAQ(2)}><Help color="white" /></IconButton></>)

        ServiceOrganisations.get()
            .then(items => { setOrganisations(items) })
            .catch(ex => { console.log(ex) })
    }, [context.organisation])

    useEffect(() => {
        setItemsFiltered(items.filter(i => { return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase()) >= 0 }));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])

    //GETTING ITEMS FROM API
    const getItems = () => { Service.getForOrganisation(context.organisation._id).then(items => setItems(GeneralService.sortJSON(items, 'title', '123'))).catch(err => { console.log(err) }) }

    return (
        <div >
            <SelectOrganisation />
            <Paper>
                <Tabs value={selectedTab} onChange={setSelectedTab} >
                    <div className="ui-tab">ACTUEEL</div>
                    <div className="ui-tab">ARCHIEF</div>
                </Tabs>
            </Paper>
            <SearchBar value={search} onKeyUp={setSearch} onReset={() => { setSearch('') }}></SearchBar>
            {showNew && <PopupNewCase onClose={() => { setShowNew(false) }} history={props.history} />}
            <List items={itemsFiltered.filter(item => { if (selectedTab === 1 && item.archived === true) { return true }; if (selectedTab === 0 && !item.archived) { return true }; return false; })
            } onClick={(item) => { props.history.push('/checklist/' + item._id) }} />
        </div>
    )
}
