import React, { useContext, useState, useEffect } from 'react'
import { ContextUI } from '../../uiContext';

const Typography = function Typography(props) {
    const contextUI = useContext(ContextUI);
    const [style, setStyle] = useState(props.style || {})

    useEffect(() => {
        let newStyle = Object.assign({}, style);
        if (props.style && props.style.color) { } else { newStyle.color = contextUI.primary.color }
        setStyle(newStyle)
    }, [contextUI.primary])
    
    let typo = <>{props.children}</>
    switch (props.component) {
        case 'h1':
            typo = <h1 style={style} className={props.className}>{props.children}</h1>
            break;
        case 'h2':
            typo = <h2 style={style} className={props.className}>{props.children}</h2>
            break;
        case 'h3':
            typo = <h3 style={style} className={props.className}>{props.children}</h3>
            break;
        default:
            typo = <span style={style} className={props.className}>{props.children}</span>
            break;
    }
    return (typo)
}

export { Typography };
