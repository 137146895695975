import React from 'react'
import './dialog.css';
const DialogActions = function DialogActions(props) { 
    let className = props.fullScreen ? 'ui-dialog-actions-fullscreen':'ui-dialog-actions';
    if(props.className){className = className +' ' + props.className }
    return (
        <>
        <div style={{height:props.fullScreen?'50px':'0px'}}>&nbsp;</div>
        <div className={className} style={props.style}>{props.children}</div>
        </>
    )
}
export { DialogActions };
