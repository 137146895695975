import React, { useEffect, useState } from 'react'
import ServiceOrganisations from '../../services/organisation';
import ServiceUsers from '../../services/user';
import { TextField, TextArea, Button, Paper, CheckBox, IconButton } from '../ui/core';
import { Delete } from '../ui/coreIcons';
export default function Item({ item, onChange, onSave,canDelete }) {

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        if (tmpItem.email) {
            tmpItem.email = tmpItem.email.trim().toLowerCase();
        }
        onChange(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = item._id;
        onSave(tmpItem);
    }


    const handleChangeAndSave = (attribute, value) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[attribute] = value;
        onChange(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = item._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
    }



    const handleApprove = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem.concept = false;
        onChange(tmpItem);
        onSave(tmpItem);
    }

    const handleDelete = () =>{
        if(window.confirm("Weet u zeker dat u deze gebruiker wilt verwijderen?")){
            ServiceUsers.remove(item)
            .then((result=>{
                let tmpItem = Object.assign({}, item);
                tmpItem.active = false;
                onChange(tmpItem);
                onSave(tmpItem);
            }))
            .catch(ex=>{
                console.log(ex)
            })
           
        }
    }

    if(item && item.active===false){
        return  < div className="padding"><Paper className="padding">Deze gebruiker is verwijderd</Paper></div>
    }

    return (
        <div >

            {item && < div className="padding">
                {item.concept === true && <> <Paper className="padding">
                    Dit is een nieuwe aanmelding. <br /><br />
                    {item.organisation && "Opgegeven organisatie: " + item.organisation}<br />
                    {item.remark && "Opgegeven opmerking: " + item.remark}<br />
                    <Button onClick={handleApprove} fullWidth variant="outlined">Gebruiker goedkeuren</Button>
                </Paper><br /></>}
                <Paper className="padding">
                    <TextField id="lastname" label="Achternaam" value={item.lastname} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextField id="firstname" label="Voornaam" value={item.firstname} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextField id="email" label="Email" value={item.email} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextField id="phone" label="Telefoon" value={item.phone} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextField id="funct" label="Functie" value={item.funct} onChange={handleChange} onBlur={handleSave}></TextField>
                    <TextArea id="note" label="Notitie" value={item.note} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <div className="label">Archiveren</div>
                    <CheckBox label="In archief plaatsen" checked={item.archived || false} onChange={(ev) => { handleChangeAndSave('archived', ev.target.checked) }} />
                    {canDelete && <Button style={{ float: 'right', backgroundColor: '#dd4e4e' }} onClick={()=>{handleDelete()}}> verwijder gebruiker</Button>}
                    <br style={{ clear: 'both' }} />

                </Paper>
            </div>}
        </div>
    )
}
