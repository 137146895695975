import React, { useEffect, useState, useContext } from 'react'
import { CheckBox, Paper, Typography } from '../ui/core'
import ImputFormControl from '../module-survey/inputform/inputformcontrol';
import { DoneAll } from '../ui/coreIcons';
import ServiceChecklistCaseItem from '../../services/checklistcaseitems';
import { Context } from '../../AppContext';
export default function ItemCaseitemSurvey({ item, onRefresh,readOnly }) {
  const context = useContext(Context);
  const [caseItem, setCaseItem] = useState(item);

  useEffect(() => {
    setCaseItem(item);
  }, [item])

  const update = (saveItem) => {
    ServiceChecklistCaseItem.update(saveItem)
      .then(result => {
        onRefresh();
      })
      .catch(ex => {
        context.setMessage('Het opslaan is mislukt');
      })
  }

  const handleCheck = (checked) => {
    let saveItem = {};
    saveItem._id = item._id;
    saveItem.complete = checked;
    update(saveItem)
  }



  const getValue = (question, option) => {

    if (!item.answers) return null
    let found
    if (option) {
      found = item.answers.find(answer => { return answer.question === question._id && answer.option === option._id });
      return found ? found.checked : false;
    } else {
      found = item.answers.find(answer => { return answer.question === question._id });
      return found ? found.value : null;
    }
  }

  const handleChangeCheck = (question, option, checked) => {
    let tmpItem = Object.assign({}, caseItem);
    if (!tmpItem.answers) tmpItem.answers = [];
    if (question.type === 3) {
      for (let i = 0; i < tmpItem.answers.length; i++) {
        if (tmpItem.answers[i].question === question._id) {
          tmpItem.answers[i].checked = false;
          tmpItem.answers[i].active = false;
          tmpItem.answers[i].saved = false;
        }
      }
    }

    let found = tmpItem.answers.find(ans => { return ans.question === question._id && ans.option === option._id });
    if (found) {
      found.checked = checked;
      found.score = option.score;
      found.saved = false;
    } else {
      let tmpAnswer = {};
      tmpAnswer.question = question._id;
      tmpAnswer.option = option._id;
      tmpAnswer.score = option.score;
      tmpAnswer.checked = checked;
      tmpAnswer.saved = false;
      tmpItem.answers.push(tmpAnswer);
    }

    update(tmpItem)

  }
  const handleChange = (question, value, remark) => {
    let tmpItem = Object.assign({}, caseItem);
    if (!tmpItem.answers) tmpItem.answers = [];
    let found = tmpItem.answers.find(ans => { return ans.question === question._id });
    if (found) {
      if (value !== undefined) found.value = value;
      if (remark !== undefined) found.remark = remark;
    } else {
      let tmpAnswer = {};
      tmpAnswer.question = question._id;
      if (value !== undefined) tmpAnswer.value = value;
      if (remark !== undefined) tmpAnswer.remark = remark;
      tmpItem.answers.push(tmpAnswer)
    }
    setCaseItem(tmpItem)
    //setAnswers(tmpAnswers);
  }


  const markForSaving = (question) => {
    update(caseItem);
  } 
  
  return (
    <div className="padding">
      {item && <>
        <Typography component="h1" style={{ borderBottom: '1px solid rgb(0, 105, 143)', paddingBottom: '6px' }}>  <DoneAll color={'rgb(0, 105, 143)'} /> {item.step}.{item.sort}  {item.title}</Typography>

        {item.questions && item.questions.sort((a, b) => { return a.sort - b.sort }).map(question => {
          return <ImputFormControl key={question._id} question={question} getValue={getValue} onChangeCheck={handleChangeCheck} onChange={handleChange} markForSaving={markForSaving} readOnly={readOnly}/>
        })}
        <CheckBox disabled={readOnly} id={'complete_' + item._id} label="doorlopen" checked={item.complete === true} style={{ float: 'right' }} onClick={(ev) => { handleCheck(ev.target.checked) }} /><br style={{ clear: 'both' }} />

      </>}
    </div>
  )
}
