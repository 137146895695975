import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '../ui/core';
import { FileCopy, Delete, Image } from '../ui/coreIcons';
import ServiceDocuments from '../../services/document'
export default function Documents({ documents, title, onDelete, onClick,onPostClick }) {
    const download = (doc) => {
        if (onClick) {
            onClick(doc)
        } else {
            ServiceDocuments.requestDownloadKey(doc._id, doc.moduleid)
                .then(result => {
                    if (result && result.link) {
                        window.open(result.link, '_blank')
                    }
                    onPostClick && onPostClick(doc);
                })
                .catch(ex => { console.log(ex) })
        }

    }
    
    const getImage = (doc) => {
        let ext = '';
        try { ext = doc.name.split('.').pop().toLowerCase().trim() } catch (ex) { }
        switch (ext) {
            case 'png':
                return <Image />;
            case 'jpg':
                return <Image />;
            default:
                return <FileCopy />;
        }
    }

    return (
        <>
            <List>
                {documents.map(doc => {
                    return <ListItem key={doc._id} onClick={() => download(doc)}>
                        <ListItemIcon>{getImage(doc)}</ListItemIcon>
                        <ListItemText primary={doc.title}></ListItemText>

                        {onDelete && doc.canDelete!==false && <ListItemIcon onClick={() => onDelete(doc)}><Delete /></ListItemIcon>}
                    </ListItem>
                })}
            </List>
        </>
    )
}
