import React from 'react'

import ItemActions from './item-caseitem-actions';
import ItemNote from './item-caseitem-note';
import ItemSurvey from './item-caseitem-survey';
import ItemWiki from './item-caseitem-wiki';

export default function ItemCaseContent({items,onRefresh,readOnly}) {
    return (
        <div>
              {items.map(checklist => {
                        let retItem;
                        switch (checklist.type) {
                            case 0:
                                retItem = <ItemSurvey key={checklist._id} item={checklist} onRefresh={onRefresh} readOnly={readOnly}></ItemSurvey>
                                break;
                            case 1:
                                retItem = <ItemWiki key={checklist._id} item={checklist} onRefresh={onRefresh}  readOnly={readOnly}></ItemWiki>
                                break;
                            case 2:
                                retItem = <ItemNote key={checklist._id} item={checklist} onRefresh={onRefresh}  readOnly={readOnly}></ItemNote>
                                break;
                            case 3:
                                retItem = <ItemActions key={checklist._id} item={checklist} onRefresh={onRefresh} readOnly={readOnly}></ItemActions>
                                break;
                        }
                        return retItem;
                    })}
        </div>
    )
}
