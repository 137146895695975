import React, { useState, useEffect, useContext } from 'react'
import Service from '../../services/elearningsteps';
import ServiceElearning from '../../services/elearning';
import ServiceElearningPersonal from '../../services/elearningpersonal';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';

import ElearningItem from '../../components/module-elearning-personal/item';

//import Presentation from '../../components/module-elearning-personal/view/presentation';
//import Survey from '../../components/module-elearning-personal/view/survey';
//import Wiki from '../../components/module-elearning-personal/view/wiki';
export default function Item(props) {
    const [steps, setSteps] = useState([]);
    const [elearning, setElearning] = useState();
    const [personalSteps, setPersonalSteps] = useState([]);

    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    useEffect(() => {
        contextUI.setSubTitle('');
        contextUI.setShowBackButton(true);
        contextUI.setHeaderActions();

        Service.getForElearning(props.match.params.id)
            .then(items => {
                items = items.sort((a, b) => { return a.sort - b.sort })
                setSteps(items);
            })

        ServiceElearning.get(props.match.params.id)
            .then(item => {
                contextUI.setSubTitle(item.title);
                setElearning(item);
                getPersonalSteps(item._id);
            })

        return () => { }
    }, [])


    const getPersonalSteps = (id) => {
        ServiceElearningPersonal.get(id)
            .then(result => {
                setPersonalSteps(result);
            })
            .catch(ex => { console.log(ex) })
    }

    const handleChange = (personalStep) => {
        if (context.user) {
            ServiceElearningPersonal.update(personalStep.elearning, personalStep.elearningStep, personalStep)
                .then(result => {
                    getPersonalSteps(personalStep.elearning);
                })
        }
    }

    return (
        <div >
            <ElearningItem elearning={elearning} steps={steps} personalSteps={personalSteps} onChange={handleChange} />
        </div>
    )
}
