import React, { useState,useEffect} from 'react';
import ServiceElearningPersonal from '../../services/elearningpersonal';
import ItemSteps from './item-steps';
import ItemHeader from './item-header';
import ItemContent from './item-content';

import Container from '../sidemenu/container';
import Menu from '../sidemenu/menu';
import Content from '../sidemenu/content';

export default function Item({ elearning, steps, personalSteps, onChange }) { 
    const [currentStep, setCurrentStep] = useState(); 
    const [refresh,setRefresh] = useState(0)
    const [complete,setComplete] = useState(0);

    useEffect(() => {
        setComplete(  ServiceElearningPersonal.calculateComplete(steps,personalSteps));
    }, [personalSteps])


    return (
        <Container>
            <Menu> 
                <ItemHeader elearning={elearning} complete={complete} steps={steps} personalStep={personalSteps.find(step => { if (!currentStep) return false; return step.elearningStep === currentStep._id }) || {}} />
                <ItemSteps steps={steps} onClick={(step)=>{setCurrentStep(step);setRefresh(new Date().toString())}} personalSteps={personalSteps} />
            </Menu>
            <Content scrollTop={refresh} >
                 <ItemContent step={currentStep} personalStep={personalSteps.find(step => { if (!currentStep) return false; return step.elearningStep === currentStep._id })} onChange={onChange}/>         
            </Content>
        </Container>

    )
}
