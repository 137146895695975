import React from 'react'
import { SearchBar, Table } from '../ui/core';

export default function list({items,onClick}) {
    const headers = [
        { label: 'Naam', attribute: 'name', default: true },
        { label: 'Adres', attribute: 'address', default: true },
        { label: 'Postcode', attribute: 'pobox', default: true },
        { label: 'Woonplaats', attribute: 'place', default: true }
    ] 
    // <SearchBar />
    return (
        <>
           
            <div className="padding"><Table items={items} headers={headers} onClick={onClick}/></div>
        </>
    )
}
