import React, { useState, useEffect } from 'react'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, Select, MenuItem, Typography } from '../ui/core';

export default function Link(props) {
    const [title, setTitle] = useState(props.range ? props.range.toString() : '');
    const [link, setLink] = useState('');
    const [selectedSearch, setSelectedSearch] = useState('');
    const [selectedFile, setSelectedFile] = useState(); //title, value
    const [selectedPage, setSelectedPage] = useState();


    const insertItem = () => {
        if (selectedFile && selectedFile.length > 0) {
            props.onSave(`<a href="${selectedFile}" class="DPathLink">${title}</a>`);
        } else if (selectedPage && selectedPage.length > 0) {
            props.onSave(`<a href="${selectedPage}" class="internalPage">${title}</a>`);
        } else if (selectedSearch && selectedSearch.length > 0) {
            props.onSave(`<a href="${encodeURIComponent(selectedSearch)}" class="internalSearchPage">${title}</a>`);
        } else {
            props.onSave(`<a href="${link}" target="_blank" class="link">${title}</a>`);
        }
        props.onClose();
    }
    return (
        <Dialog onClose={() => props.onClose()} open={true} >
            <DialogTitle id="simple-dialog-title"><Typography component="h1">Invoegen link</Typography></DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Naam voor de link"
                    type="text"
                    value={title || ""}
                    onChange={(ev) => { setTitle(ev.target.value) }}
                    fullWidth
                    required
                />
                <TextField
                    margin="dense"
                    id="link"
                    label="Link, verwijzing naar website"
                    type="text"
                    value={link || ""}
                    onChange={(ev) => { setLink(ev.target.value) }}
                    fullWidth
                    placeholder="https://"
                    helperText="Begin een telefoonnummer met tel: ,email met email: en een website met https://"
                />
                <div style={{ width: '100px', textAlign: 'center' }}><br />--OF--<br /></div>
                <TextField
                    margin="dense"
                    id="link"
                    label="Zoeken binnen het handboek op een bepaald woord"
                    type="text"
                    value={selectedSearch || ""}
                    onChange={(ev) => { setSelectedSearch(ev.target.value) }}
                    fullWidth
                    placeholder="Zoekwoord"
                />


                {props.files && props.files.length > 0 && <React.Fragment>
                    <div style={{ width: '100px', textAlign: 'center' }}><br />--OF--<br /></div>
                    <Select label="Link, verwijzing naar document" fullWidth value={selectedFile || ''} labelId="width" onChange={(ev) => { setSelectedFile(ev.target.value) }}>
                        <option></option>
                        {props.files.filter(item => { let fileType = item.title ? item.title.split('.').pop() : ''; return ['form', 'html', 'json'].indexOf(fileType) === -1 }).map((file, i) => {
                            return <option key={file.value + i} value={file.value}>{file.title}</option>
                        })}
                    </Select>
                </React.Fragment>}
                {props.pages && props.pages.length > 0 && <React.Fragment>
                    <div style={{ width: '100px', textAlign: 'center' }}><br />--OF--<br /></div>

                    <Select label="Link, verwijzing naar pagina" fullWidth value={selectedPage || ''} labelId="width" onChange={(ev) => { setSelectedPage(ev.target.value) }}>
                        <option></option>
                        {props.pages.map((page) => {
                            return <option key={page} value={page}>{page}</option>
                        })}
                    </Select>

                </React.Fragment>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={() => { props.onClose() }}>Annuleren</Button><Button color="primary" variant="contained" onClick={() => { insertItem() }} disabled={title.length === 0}>Invoegen</Button>
            </DialogActions>
        </Dialog>
    )
}
