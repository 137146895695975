import React, { useEffect, useState } from 'react'
import ServiceSettings from '../../services/settings';
import FAQ from './faq';
import { TextField, TextArea, Button, Paper, Tabs  } from '../ui/core'; 
export default function Settings() { 
    const [item,setItem] = useState();
    const [selectedTab,setSelectedTab] = useState(0);

    useEffect(() => {
        console.log('get')
        ServiceSettings
        .get()
        .then(settings=>{
            console.log(settings)
            setItem(settings);
        })
        .catch(ex=>{
            console.log(ex);
        })
    }, [ ])

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        if (tmpItem.email) {
            tmpItem.email = tmpItem.email.trim().toLowerCase();
        } 
        setItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = item._id;
        ServiceSettings.update(tmpItem);
    }
 
    return (
        <div >
            <Paper>
                <Tabs value={selectedTab} onChange={setSelectedTab} >
                <div className="ui-tab">MAIL BERICHTEN</div>
                <div className="ui-tab">TEKSTEN</div>
                <div className="ui-tab">FAQ</div>
            </Tabs>
            </Paper>
            {item && < div className="padding">
                {selectedTab===0 &&   <Paper className="padding">
                    <TextField id="defaultsender" label="Standaard emailadres afzender" value={item.defaultsender || ''} onChange={handleChange} onBlur={handleSave}></TextField>
                    <br /><br />  
                    <TextField id="signuprecipient" label="Emailadres van ontvanger aanmeldingen" value={item.signuprecipient || ''} onChange={handleChange} onBlur={handleSave}></TextField>
                    <br /><br />
                    <TextArea id="mailforgotpassword" helper="Gebruik de tag [code] op de plaatst waar de benodigde code voor het aanmaken nieuw wachtwoord moet komen te staan." label="Mailbericht voor aanmaken (nieuw) wachtwoord" value={item.mailforgotpassword || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <TextArea id="mailsignup" label="Bevestigings mail voor aanmelden" value={item.mailsignup || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <TextArea id="mailsignupcomplete" label="Bevestiging mail aanmelding is compleet" value={item.mailsignupcomplete || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <TextArea id="mailsurveyrespondent" label="Template uitnodigingsmail waarnemer." value={item.mailsurveyrespondent || ''} onChange={handleChange} onBlur={handleSave} helper="Gebruik de tags [url] en [qrcode] in het mailbericht om automatisch de juiste waarde in te vullen."></TextArea>
                  
                    <br /><br />
                </Paper>
                }
                   {selectedTab===1 &&   <Paper className="padding">
                    <TextArea id="introelearning" label="Introductietekst voor E-learning bij selecteren tool" value={item.introelearning || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <TextArea id="introgea" label="Introductietekst voor evaluatie app bij selecteren tool" value={item.introgea || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                    <br /><br />
                    <TextArea id="introchecklist" label="Introductietekst voor controle lijst bij selecteren tool" value={item.introchecklist || ''} onChange={handleChange} onBlur={handleSave}></TextArea>
                  
                    <br /><br />
                </Paper>
                }

{selectedTab===2 &&   <Paper>
                  <FAQ/>
                    <br /><br />
                </Paper>
                }
              
            </div>}
        </div>
    )
}
