import React, { useState, useEffect, useRef } from 'react';
import TextBlock from './view-block/text';
import ImageBlock from './view-block/image';
import VideoBlock from './view-block/video';
export default function Viewer({ blocks, onProgress }) {
    const [timer, setTimer] = useState(0); 
    const contentRef = useRef();

    const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
        if(!el) return false;
        const { top, left, bottom, right } = el.getBoundingClientRect();
        const { innerHeight, innerWidth } = window;
        return partiallyVisible
            ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
            ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
            : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
    };

    const checkElement = () => {
       let count = 0
        for (let i = 0; i < blocks.length; i++) { 
            if(document.getElementById(`block-builder-blockid-${blocks[i]._id}`)){
                if (elementIsVisibleInViewport(document.getElementById(`block-builder-blockid-${blocks[i]._id}`), true)) {
                    document.getElementById(`block-builder-blockid-${blocks[i]._id}`).viewed = true;
                   
                }
                if(  document.getElementById(`block-builder-blockid-${blocks[i]._id}`).viewed){
                    count++
                }
            }
        }
      
        if(count>0){
            onProgress(100/blocks.length * count);
        }
       

    }

    useEffect(() => {
        if (contentRef.current) { 
            contentRef.current.parentElement.parentElement.onscroll = checkElement; 
        }
    }, [contentRef])



    useEffect(() => {
        setTimeout(() => setTimer(timer + 1), 500);
        //  checkElement();
    }, [timer])

    return (
        <>
            {blocks && blocks.map(block => {
                let retVal;
                switch (block.type) {
                    case 'text':
                        retVal = <div key={block._id}><TextBlock block={block} /></div>
                        break;
                    case 'image':
                        retVal = <div key={block._id}><ImageBlock block={block} /></div>
                        break;
                    case 'video':
                        retVal = <div key={block._id}><VideoBlock block={block} /></div>
                        break;
                    default:
                        retVal = null;
                }
                return <div key={block._id} id={`block-builder-blockid-${block._id}`} ref={contentRef}>{retVal}</div>
            })}
        </>
    )
}
