import React, { useEffect, useState } from 'react'
import ServiceEvaluationMonitor from '../../services/evaluationmonitor';

import { Typography } from '../ui/typography';
import { Button, IconButton, List, ListItem, Menu, MenuItem } from '../ui/core';
import { Info, MoreVert } from '../ui/coreIcons';
import DashBar from './monitor-dash-bar';
import DashRadar from './monitor-dash-radar';
import DashLine from './monitor-dash-line';
import Timeline from './monitor-timeline';
import TimelineV2 from './monitor-timelinev2';
import ServiceGeneral from '../../services/general';
import ServiceSurvey from '../../services/survey';
import ServiceEvaluation from '../../services/evaluations';
import EvaluationInfo from './monitor-evaluationinfo';
import moment from 'moment';
export default function MonitorDash({ history, monitor, onEdit, onDelete }) {
    const [data, setData] = useState();
    const [chartData, setChartData] = useState([]);
    const [chartDataSub, setChartDataSub] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState();
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [selectedSurveyObject, setSelectedSurveyObject] = useState();
    const [selectedEvaluationObject, setSelectedEvaluationObject] = useState();
    const [showEvaluationInfo, setShowEvaluationInfo] = useState(false);
    const [selectedMainProcess, setSelectedMainProcess] = useState();
    const trimWord = (word, amount) => {
        if (word.length > amount) {
            return word.substr(0, amount) + '...';
        } else {
            return word;
        }
    }
    /*
    const validDate = (from, until, date) => {
        if (date) {
            if (from && until) {

                return moment(date).isBetween(moment(from), moment(until))
            }
            return true
        }
        return false;
    }
    */
    useEffect(() => {

        ServiceEvaluationMonitor.getScore(monitor._id)
            .then(result => {
                /*
                for (let i = 0; i < result.mainProcessScore.length; i++) {
                    let found = result.surveys.find(s => { return s._id === result.mainProcessScore[i]._id.survey })
                    if (found && validDate(monitor.from, monitor.until, found.date)) { found.found = true; }
                }
                for (let i = 0; i < result.subProcessScore.length; i++) {
                    let found = result.surveys.find(s => { return s._id === result.subProcessScore[i]._id.survey })
                    if (found && validDate(monitor.from, monitor.until, found.date)) { found.found = true; }
                }
              
                result.surveys = result.surveys.filter(s => { return s.found === true && s.evaluation });
                  */
                result.surveys = ServiceGeneral.sortJSON(result.surveys, 'date', '123');

                if (result.surveys.length > 0) {
                    setSelectedSurvey(result.surveys[result.surveys.length - 1]._id);
                    setSelectedSurveys([result.surveys[result.surveys.length - 1]._id])
                } else {
                    setSelectedSurvey(null)
                }
                setData(result)
            })
    }, [monitor])



    useEffect(() => { 
      setSelectedMainProcess(null);
        let tmpData = [];
        if (data && data.surveys) {
        //    let surveyObject = data.surveys.find(s => { return s._id === selectedSurvey });
            
            let mainProcesses = [];
            for(let i=0;i<selectedSurveys.length;i++){
               
                let foundSurveyObject = data.surveys.find(s => { return s._id === selectedSurveys[i] });
                if(foundSurveyObject){
                   
                    for (let m = 0; m < foundSurveyObject.mainProcesses.length; m++) {
                        if (foundSurveyObject.mainProcesses[m].active == true) {
                           
                            let alreadyExcists = mainProcesses.find(p=>{return p.code ===foundSurveyObject.mainProcesses[m].code });
                            if(!alreadyExcists){
                                mainProcesses.push(foundSurveyObject.mainProcesses[m]);
                            }
                        }
                    }
                }
            }
           
            
            //mainProcesses
            //subProcesses
         
                if (monitor && data && data.mainProcessScore) {
                    for (let m = 0; m <mainProcesses.length; m++) {
                         
                            let score = 0;
                            let foundScores = data.mainProcessScore.filter(score => { return score._id.mainProcessCode === mainProcesses[m].code && selectedSurveys.indexOf(score._id.survey)>=0});
                       
                            if(foundScores.length>0){ 
                                score =  foundScores.reduce((a, b) => { return { perc: ((a.perc || 0) + (b.perc || 0)) } }).perc / foundScores.length;
                              
                                if(isNaN(score)==true){
                                    score = 0;
                                } 
                            }
                           
                          score = ServiceGeneral.roundNumber(score)
                            let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);

                            try {
                                tmpData.push({
                                    _id: mainProcesses[m].code ||  mainProcesses[m]._id,
                                    x: trimWord(mainProcesses[m].title || "", 25),
                                    title: trimWord(mainProcesses[m].title || "", 25), //16
                                    fullTitle: mainProcesses[m].title || "",
                                    y: score,
                                    score: score,
                                    color: color,
                                    max: 100
                                })
                            } catch (ex) {
                                console.log(ex);
                            }

                            // console.log(monitor.mainProcesses[m])
 
 
                    }
                }
                /*
                if (monitor && monitor.subProcesses && data && data.subProcessScore) {
                    for (let m = 0; m < monitor.subProcesses.length; m++) {
                        let score = 0;
                        let foundScore = data.subProcessScore.find(score => { return score._id.subProcessCode === monitor.subProcesses[m].code && score._id.survey === selectedSurvey });
                        if (foundScore) score = foundScore.perc;
                        let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);
                        try {
                          
                                tmpData.push(
                                    {
                                        _id: monitor.subProcesses[m].code,
                                        x: trimWord(monitor.subProcesses[m].title|| "", 16),
                                        title: trimWord(monitor.subProcesses[m].title|| "", 16),
                                        fullTitle: monitor.subProcesses[m].title|| "",
                                        y: score,
                                        score: score,
                                        color: color,
                                        perc: score,
                                        max: 100
                                    }
        
                                )
                          
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }*/
                setChartData(tmpData);
                if(mainProcesses.length==1){
                    setSelectedMainProcess(mainProcesses[0].code)
                }
        }

      
        
/*
        if (data && data.surveys) {
            let surveyObject = data.surveys.find(s => { return s._id === selectedSurvey });
            if (surveyObject) {
                setSelectedSurveyObject(surveyObject);
                //set evaluation
                ServiceEvaluation.get(surveyObject.evaluation)
                    .then(result => {
                        setSelectedEvaluationObject(result)
                    })
                    .catch(ex => { })
            }

        }
        */
    }, [selectedSurveys, monitor, data])

    useEffect(() => {
     
        let tmpData = [];
        if (data) {
            //
          //  let surveyObject = data.surveys.find(s => { return s._id === selectedSurvey });

           // let mainProcessObject = surveyObject.mainProcesses.find(p => { return p.code == selectedMainProcess });
           let subProcesses = [];
           for(let i=0;i<selectedSurveys.length;i++){
              
               let foundSurveyObject = data.surveys.find(s => { return s._id === selectedSurveys[i] });
               if(foundSurveyObject){
                  
                   for (let m = 0; m < foundSurveyObject.mainProcesses.length; m++) {
                       if (foundSurveyObject.mainProcesses[m].active == true && foundSurveyObject.mainProcesses[m].code===selectedMainProcess) {
                        
                           if(foundSurveyObject.mainProcesses[m].subProcesses){
                            for(let s=0;s<foundSurveyObject.mainProcesses[m].subProcesses.length;s++){
                                if(foundSurveyObject.mainProcesses[m].subProcesses[s].active==true){
                                    let alreadyExcists = subProcesses.find(p=>{return p.code ===foundSurveyObject.mainProcesses[m].subProcesses[s].code });
                                    if(!alreadyExcists){
                                        subProcesses.push(foundSurveyObject.mainProcesses[m].subProcesses[s]);
                                    }
                                }
                               
                              }
                           }
                        
                          
                       }
                   }
               }
           } 
            if (subProcesses.length>0) {

                //mainProcesses
                //subProcesses 
                    if (monitor && data && data.subProcessScore) {
                      //  for (let m = 0; m < data.subProcessScore.length; m++) {
                       for(let m=0;m<subProcesses.length;m++){    
                                let score = 0;
                                let foundScores = data.subProcessScore.filter(score => { return score._id.subProcessCode === subProcesses[m].code &&score._id.mainProcessCode=== selectedMainProcess && selectedSurveys.indexOf(score._id.survey)>=0});
 
                                if(foundScores && foundScores.length>0){ 
                                    score =  foundScores.reduce((a, b) => { return { perc: ((a.perc || 0) + (b.perc || 0)) } }).perc / foundScores.length;
                                  
                                    if(isNaN(score)==true){
                                        score = 0;
                                    } 
                                }
                                score = ServiceGeneral.roundNumber(score)
                             //   if (foundScore) score = foundScore.perc;
                                let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);

                                try {
                                    tmpData.push({
                                        _id: subProcesses[m].code,
                                        x: trimWord(subProcesses[m].title || "", 25),
                                        title: trimWord(subProcesses[m].title || "", 25),
                                        fullTitle: subProcesses[m].title || "",
                                        y: score,
                                        score: score,
                                        color: color,
                                        max: 100
                                    })
                                } catch (ex) {
                                    console.log(ex);
                                }

                                // console.log(monitor.mainProcesses[m])

                            

                        }
                    }
                    /*
                    if (monitor && monitor.subProcesses && data && data.subProcessScore) {
                        for (let m = 0; m < monitor.subProcesses.length; m++) {
                            let score = 0;
                            let foundScore = data.subProcessScore.find(score => { return score._id.subProcessCode === monitor.subProcesses[m].code && score._id.survey === selectedSurvey });
                            if (foundScore) score = foundScore.perc;
                            let color = ServiceSurvey.getColorFromRange(monitor.colorRange, score);
                            try {
                              
                                    tmpData.push(
                                        {
                                            _id: monitor.subProcesses[m].code,
                                            x: trimWord(monitor.subProcesses[m].title|| "", 16),
                                            title: trimWord(monitor.subProcesses[m].title|| "", 16),
                                            fullTitle: monitor.subProcesses[m].title|| "",
                                            y: score,
                                            score: score,
                                            color: color,
                                            perc: score,
                                            max: 100
                                        }
            
                                    )
                              
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    }*/
                    setChartDataSub(tmpData);
               

            }

        }


    }, [selectedMainProcess,selectedSurveys])

    const handleTimeLineClick = (survey) => {
        setChartDataSub(null);
        setSelectedSurvey(survey);

    } 
    return (
        <>
            {selectedSurveyObject && selectedEvaluationObject && showEvaluationInfo && <EvaluationInfo history={history} chartData={chartData} evaluation={selectedEvaluationObject} survey={selectedSurveyObject} onClose={() => setShowEvaluationInfo(false)} />}
            <div key={monitor._id} className="card" style={{ width: 'auto', height: 'auto' }}>
                <div style={{ display: 'flex', width: '100%' }}>

                    <div style={{ width: '100%' }}>
                        <Typography component="h3" className="padding">{monitor.title}</Typography>
                      
                    </div>
                    <div style={{ textAlign: 'right', paddingTop: '4px' }}>
                        <Menu id="monitorMenu" control={<IconButton><MoreVert /></IconButton>}>
                            <MenuItem onClick={onEdit}>Wijzigen</MenuItem>
                            <MenuItem onClick={onDelete}>Verwijderen</MenuItem>
                        </Menu>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    {data && <TimelineV2 scores={data} monitor={monitor} onClick={handleTimeLineClick} selectedSurveys={selectedSurveys}  onClickSurveys={(surveys)=>{setSelectedSurveys(surveys)}}/>}
                  
                  <div style={{display:'block'}}>
                    {data && monitor.chartType === '1' && chartData.length>1 && <DashRadar monitor={monitor} chartData={chartData} onClick={(proc) => { setSelectedMainProcess(proc._id) }} />}
                    {data && monitor.chartType === '1' && chartDataSub && <DashRadar monitor={monitor} chartData={chartDataSub} onClick={() => { }} />}


                    {data && monitor.chartType === '2' && chartData.length>1 && <DashBar monitor={monitor} chartData={chartData} width={(chartData.length) * 120} onClick={(proc) => { setSelectedMainProcess(proc._id) }}/>}
                    {data && monitor.chartType === '2' && chartDataSub && <><br style={{clear:'both'}}/><DashBar monitor={monitor} chartData={chartDataSub} width={(chartDataSub.length ) * 120} onClick={() => {  }}/></>}
                  
                    {data && monitor.chartType === '3' && <DashLine monitor={monitor} chartData={chartData} />}
                    </div>
                </div>
              
                {selectedSurveyObject && selectedSurveyObject.evaluation && <div className="label" style={{ marginRight: '16px',float:'right' }}>{ServiceGeneral.formatDate(selectedSurveyObject.date, false)} - {selectedSurveyObject.title} <IconButton onClick={() => { setShowEvaluationInfo(true) }} style={{ float: 'left', marginTop: '-8px' }}><Info /></IconButton></div>}

            </div>
        </>
    )
}
