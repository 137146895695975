import React, { useContext, useState, useEffect } from 'react'
import { ContextUI } from '../../uiContext';
import { Button } from './button';

const ImageUpload = function ImageUpload(props) {
    const ref = React.createRef();
    const [imagestyle, setImageStyle] = useState(props.imagestyle || {})

    if (!props.id) {
        console.warn('No ID set for imageUpload' + props.label)
    }



    const addImage = (files) => {
        if (files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                if (props.resize) {
                    var canvas = document.createElement('canvas');
                    var img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        try {
                            canvas.width = props.resize;
                            canvas.height = canvas.width * img.height / img.width;
                            var context = canvas.getContext('2d');
                            context.drawImage(img, 0, 0, canvas.width, canvas.height);

                            let ev = { target: {} };
                            ev.target.id = props.id;
                            ev.target.value = canvas.toDataURL('image/jpeg', 0.5);
                            props.onChange(ev);

                        } catch (ex) {
                            console.log('Error: ', ex);
                        }
                    }
                } else {
                    let ev = { target: {} };
                    ev.target.id = props.id;
                    ev.target.value = reader.result
                    props.onChange(ev);
                }
            }, false);
            reader.readAsDataURL(files[0]);
        }
    }

    const removeImage = () => {
        let ev = { target: {} };
        ev.target.id = props.id;
        ev.target.value = ""
        props.onChange(ev);
    }

    return (
        < >
            {props.label && <div className='textFielLabel'>{props.label}</div>}
            <input ref={ref} style={{ display: 'none' }} type='file' id={'upload_image_' + props.id} onChange={(e) => { addImage(e.target.files); }} accept="image/*" />
            <img id={props.id} src={props.value || ''} style={imagestyle}></img>
            {props.error && <div className='textFieldError'>{props.error}</div>}
            {props.helper && <div className='textFieldHelper'>{props.helper}</div>}
            <br />
            {!props.value && <Button variant="text" onClick={() => { ref.current && ref.current.click() }}>Upload afbeelding</Button>}
            {props.value && <Button variant="text" onClick={removeImage}>Verwijder afbeelding</Button>}
        </>
    )
}

export { ImageUpload };
