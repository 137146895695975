import React, { useContext, useState } from 'react'
import { ContextUI } from '../../uiContext';
import { v4 as uuidv4 } from 'uuid';
const CheckBox = function CheckBox(props) {
    const [prefixID] = useState(uuidv4() + "#") 
    const contextUI = useContext(ContextUI);
    const [style, setStyle] = useState(props.style)
    let checkStyle = props.checked? {backgroundColor:contextUI.primary.color,border:'2px solid '+contextUI.primary.color} :{backgroundColor:'white',border:'2px solid rgb(118, 118, 118)'}
    if(props.disabled){
        checkStyle.backgroundColor = 'gray';
        checkStyle.border='2px solid gray';
    }
    if (!props.id) { console.warn('ID missing for checkbox') }

    const handleChange = (ev)=>{ 
        let retVal = {};
        retVal.target = {}; 
        retVal.target.checked = ev.target.checked;
        retVal.target.id = ev.target.id.replace(prefixID,'');
        props.onChange && props.onChange(retVal);
        props.onClick && props.onClick(retVal);
    }

   return (
        < div style={props.style}>
         <label className='checkboxLabel' htmlFor={prefixID + props.id|| ''}>{props.label || <span>&nbsp;</span>}
            <input
                disabled={props.disabled}
                readOnly={props.readOnly}
                style={style}
                value={props.value || ''}
                checked={props.checked || false}
                id={prefixID + props.id}
                onChange={handleChange}
                type={'checkbox'}
                className='checkbox'
                onFocus={() => setStyle({ ...props.style, borderBottom: '1px solid ' + contextUI.primary.color })}
                onBlur={() => setStyle(props.style)}
            ></input>
             <span className="checkmark" style={checkStyle}></span>
           </label>
        </div>
    )
}

export { CheckBox };
