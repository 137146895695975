import React, { useState, useEffect } from 'react'

export default function Labels({ centerX = 350, centerY = 420, radius = 300, data, dataKey,onClick }) {
    const [lines, setLines] = useState([]); 

    const printPolygon = (r) => {
        let tmpLabels = [];
        for (let i = 0; i < data.length; i++) {
            const angle = (i) * (Math.PI * 2) / data.length;
            
            const dist = r +20;
            var x1 = centerX + Math.cos(angle) * (dist);
            var y1 = centerY + Math.sin(angle) * (dist);
            var x2 = centerX + Math.cos(angle)
            var y2 = centerY + Math.sin(angle)
            x1 = x1 - (4 * data[i][dataKey].length);
            if(x1 < 0) x1 = 0
            let text = <text className="charting-datalabel" key={i+x1+x2+y1+y2} onClick={()=>{onClick(data[i])}} x={x1} y={y1} fill={"black"}>{data[i][dataKey]}</text>

            tmpLabels.push(text)
        }
        
        return tmpLabels
    }

    useEffect(() => {
        let tmpLines = [] 
        tmpLines.push(printPolygon(radius));
        setLines(tmpLines)
    }, [data])

    return (<>
        {lines}
    </>)
}
